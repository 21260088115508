import { useState, useEffect } from 'react';
import axios from 'axios';

const MAINET_API_URL = 'https://mainet.walletpayin.com';

const useTokenBalances = (address) => {
  const [tokens, setTokens] = useState([]);
  const [allTokens, setAllTokens] = useState([]);
  const [balances, setBalances] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!address) {
      setTokens([]);
      setAllTokens([]);
      setBalances({});
      setLoading(false);
      return;
    }

    const fetchTokenBalances = async () => {
      setLoading(true);
      setError(null);

      try {
        const url = `${MAINET_API_URL}/api/v1/balances/${address}`;
        const response = await axios.get(url);

        if (!response.data.success) {
          throw new Error(response.data.message || 'Failed to fetch token balances');
        }

        const tokenBalances = response.data.tokens.map((token) => ({
          name: token.name,
          symbol: token.symbol,
          address: token.contractAddress,
          decimals: token.decimals,
          logo: token.logoUrl || '',
          rawBalance: token.rawBalance,
          formattedBalance: token.formattedBalance,
          balance: token.formattedBalance, // Display formattedBalance as balance
          usdValue: token.usdValue || 0, // Default USD value to $0 if unavailable
          hasBalance: token.hasBalance,
        }));

        setAllTokens(response.data.allTokens);

        const balancesObj = {};
        tokenBalances.forEach((token) => {
          balancesObj[token.address] = token.balance;
        });

        setTokens(tokenBalances);
        setBalances(balancesObj);
      } catch (err) {
        console.error('Error fetching token balances:', err);
        setError(err.message || 'Failed to fetch token balances');
      } finally {
        setLoading(false);
      }
    };

    const intervalId = setInterval(fetchTokenBalances, 30000);
    fetchTokenBalances();

    return () => clearInterval(intervalId);
  }, [address]);

  return {
    tokens,
    allTokens,
    balances,
    loading,
    error,
    getTokenBalance: (tokenAddress) => balances[tokenAddress] || 0,
    getTokenUSDValue: (tokenAddress) => {
      const token = tokens.find((t) => t.address === tokenAddress);
      return token ? token.usdValue : 0;
    },
    totalUSDValue: tokens.reduce((sum, token) => sum + (token.usdValue || 0), 0),
    ethBalance: tokens.find((t) => t.address === 'ETH')?.balance || 0,
    ethUSDValue: tokens.find((t) => t.address === 'ETH')?.usdValue || 0,
    activeTokenCount: tokens.length,
    totalTokenCount: allTokens.length,
  };
};

export default useTokenBalances;
