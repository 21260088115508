// src/components/Dashboard/Swap/Swap.js

import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Button,
  TextField,
  IconButton,
  Typography,
  CircularProgress,
  Alert,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from '@mui/material';
import {
  Settings as SettingsIcon,
  SwapVert as SwapIcon,
  Close as CloseIcon,
  Info as InfoIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';

import {
  BrowserProvider,
  Contract,
  parseUnits,
  formatEther,
  formatUnits,
  MaxUint256,
} from 'ethers';

import useTokenBalances from '../../../hooks/useTokenBalances';
import { AuthContext } from '../../../contexts/AuthContext';
import SwapSettingsModal from './SwapSettingsModal';
import TokenImportModal from './TokenImportModal';
import { tokenList as defaultTokenList } from './tokens';
import './Swap.css';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';

import { getTokenPrice } from '../../../utils/getTokenPrice';

// Import Uniswap V2 Router ABI
import IUniswapV2Router02ABI from './abis/IUniswapV2Router02.json';

// WETH Contract Details
const WETH_ADDRESS = '0xC02aaA39b223FE8D0a0E5C4F27eAD9083C756Cc2';
const WETH_ABI = [
  'function deposit() payable',
  'function withdraw(uint256 wad) public',
  'function balanceOf(address) view returns (uint256)',
];

// Uniswap V2 Router Address
const UNISWAP_V2_ROUTER_ADDRESS = '0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D';

// Helper function to convert hex to RGBA
const hexToRgba = (hex, alpha) => {
  if (typeof hex !== 'string') return `rgba(204, 204, 204, ${alpha})`;
  const sanitizedHex = hex.replace('#', '');
  if (sanitizedHex.length !== 6) return `rgba(204, 204, 204, ${alpha})`;
  const bigint = parseInt(sanitizedHex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// TokenSelector Component
const TokenSelector = ({
  label,
  selectedToken,
  onSelect,
  amount,
  onChange,
  readOnly,
  onMax,
  getUsdValue,
  allTokens,
  setImportModalOpen,
}) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');

  const handleSelect = (token) => {
    onSelect(token);
    setOpen(false);
  };

  const filteredTokens = allTokens.filter(
    (token) =>
      token.name?.toLowerCase().includes(search.toLowerCase()) ||
      token.symbol?.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Box className="token-selector">
      <Box className="token-selector-left">
        <Typography className="token-label">{label}</Typography>
        <Button
          onClick={() => setOpen(true)}
          className="token-select-button"
          endIcon={<ArrowDropDownIcon />}
        >
          {selectedToken?.image ? (
            <img
              src={selectedToken.image}
              alt={selectedToken.name}
              className="asset-icon-image"
              width={24}
              height={24}
            />
          ) : selectedToken ? (
            <div
              className="asset-icon-placeholder"
              style={{
                backgroundColor: hexToRgba(selectedToken.color || '#CCCCCC', 0.125),
                color: selectedToken.color || '#CCCCCC',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              {selectedToken.symbol?.[0] || '?'}
            </div>
          ) : (
            <div
              className="asset-icon-placeholder"
              style={{
                backgroundColor: hexToRgba('#CCCCCC', 0.125),
                color: '#CCCCCC',
                width: '24px',
                height: '24px',
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontWeight: 'bold',
                fontSize: '12px',
              }}
            >
              ?
            </div>
          )}
          <Typography className="token-symbol">
            {selectedToken?.symbol || 'Select'}
          </Typography>
        </Button>
      </Box>

      <Box className="token-amount-container">
        <TextField
          value={amount}
          onChange={onChange}
          placeholder="0.0"
          className="token-amount-input"
          disabled={readOnly}
          type="number"
          InputProps={{
            endAdornment: !readOnly ? (
              <Button onClick={onMax} className="max-button">
                MAX
              </Button>
            ) : null,
          }}
        />
        <Typography className="usd-value">
          = ${getUsdValue(selectedToken, amount)}
        </Typography>
        {/* Show "Available:" balance */}
        {!readOnly && selectedToken && (
          <Typography variant="body2" className="available-text">
            Available: {selectedToken.balance
              ? parseFloat(selectedToken.balance).toFixed(6)
              : '0.000000'}{' '}
            {selectedToken.symbol}
          </Typography>
        )}
      </Box>

      {/* Token Selection Dialog */}
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        fullWidth
        maxWidth="xs"
      >
        <DialogTitle className="dialog-title">
          Select Token
          <IconButton onClick={() => setOpen(false)} className="close-button">
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent className="dialog-content" dividers>
          <TextField
            label="Search Token"
            variant="outlined"
            fullWidth
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            margin="normal"
            className="search-token-input"
          />
          <List>
            {filteredTokens.length > 0 ? (
              filteredTokens.map((token) => (
                <React.Fragment key={token.address}>
                  <ListItem button onClick={() => handleSelect(token)}>
                    <ListItemAvatar>
                      {token.image ? (
                        <img
                          src={token.image}
                          alt={token.symbol}
                          className="asset-icon-image"
                          width={24}
                          height={24}
                        />
                      ) : (
                        <div
                          className="asset-icon-placeholder"
                          style={{
                            backgroundColor: hexToRgba(token.color || '#CCCCCC', 0.125),
                            color: token.color || '#CCCCCC',
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            fontSize: '12px',
                          }}
                        >
                          {token.symbol?.[0] || '?'}
                        </div>
                      )}
                    </ListItemAvatar>
                    <ListItemText
                      primary={token.symbol}
                      secondary={token.name}
                    />
                  </ListItem>
                  <Divider component="li" />
                </React.Fragment>
              ))
            ) : (
              <Typography variant="body2" color="textSecondary" align="center">
                No tokens found
              </Typography>
            )}
            <ListItem
              button
              onClick={() => {
                setOpen(false);
                setImportModalOpen(true);
              }}
            >
              <ListItemAvatar>
                <div
                  className="asset-icon-placeholder"
                  style={{
                    backgroundColor: hexToRgba('#CCCCCC', 0.125),
                    color: '#CCCCCC',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    fontSize: '12px',
                  }}
                >
                  +
                </div>
              </ListItemAvatar>
              <ListItemText primary="Import Token" />
            </ListItem>
          </List>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

// ConfirmationModal Component
const ConfirmationModal = ({
  open,
  onClose,
  executeSwap,
  loading,
  loadingText,
  swapDetails,
  fromToken,
  toToken,
  amount,
  getUsdValue,
}) => {
  if (!fromToken || !toToken) {
    return null; // Do not render if tokens are not available
  }

  return (
    <Dialog
      open={open}
      onClose={() => !loading && onClose()}
      maxWidth="sm"
      fullWidth
      className="swap-dialog"
    >
      <DialogTitle className="dialog-title">
        Confirm Swap
        <IconButton
          onClick={() => !loading && onClose()}
          className="close-button"
          disabled={loading}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent className="dialog-content">
        <Box className="swap-confirmation">
          <Box className="swap-confirmation-amounts">
            <Box className="token-amount">
              <Box className="token-info" style={{ display: 'flex', alignItems: 'center' }}>
                {fromToken.image ? (
                  <img
                    src={fromToken.image}
                    alt={fromToken.symbol}
                    className="asset-icon-image"
                    width={32}
                    height={32}
                  />
                ) : fromToken ? (
                  <div
                    className="asset-icon-placeholder"
                    style={{
                      backgroundColor: hexToRgba(fromToken.color || '#CCCCCC', 0.125),
                      color: fromToken.color || '#CCCCCC',
                      width: '32px',
                      height: '32px',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      marginRight: '8px',
                    }}
                  >
                    {fromToken.symbol?.[0] || '?'}
                  </div>
                ) : (
                  <div
                    className="asset-icon-placeholder"
                    style={{
                      backgroundColor: hexToRgba('#CCCCCC', 0.125),
                      color: '#CCCCCC',
                      width: '32px',
                      height: '32px',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      marginRight: '8px',
                    }}
                  >
                    ?
                  </div>
                )}
                <Typography variant="h6">
                  {amount} {fromToken.symbol}
                </Typography>
              </Box>
              <Typography className="usd-value">
                = ${getUsdValue(fromToken, amount)}
              </Typography>
            </Box>

            <SwapIcon className="swap-icon" />

            <Box className="token-amount">
              <Box className="token-info" style={{ display: 'flex', alignItems: 'center' }}>
                {toToken.image ? (
                  <img
                    src={toToken.image}
                    alt={toToken.symbol}
                    className="asset-icon-image"
                    width={32}
                    height={32}
                  />
                ) : toToken ? (
                  <div
                    className="asset-icon-placeholder"
                    style={{
                      backgroundColor: hexToRgba(toToken.color || '#CCCCCC', 0.125),
                      color: toToken.color || '#CCCCCC',
                      width: '32px',
                      height: '32px',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      marginRight: '8px',
                    }}
                  >
                    {toToken.symbol?.[0] || '?'}
                  </div>
                ) : (
                  <div
                    className="asset-icon-placeholder"
                    style={{
                      backgroundColor: hexToRgba('#CCCCCC', 0.125),
                      color: '#CCCCCC',
                      width: '32px',
                      height: '32px',
                      borderRadius: '50%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold',
                      fontSize: '16px',
                      marginRight: '8px',
                    }}
                  >
                    ?
                  </div>
                )}
                <Typography variant="h6">
                  {swapDetails.minReceived} {toToken.symbol}
                </Typography>
              </Box>
              <Typography className="usd-value">
                = ${getUsdValue(toToken, swapDetails.minReceived)}
              </Typography>
            </Box>
          </Box>

          {/* Swap Details */}
          <Box className="swap-details">
            {/* Rate */}
            <Box className="detail-row">
              <Box className="detail-label">
                <Typography color="textSecondary">Rate</Typography>
                <Tooltip title="Exchange rate including fees">
                  <InfoIcon fontSize="small" className="info-icon" />
                </Tooltip>
              </Box>
              <Typography>
                1 {fromToken.symbol} = {swapDetails.rate} {toToken.symbol}
              </Typography>
            </Box>

            {/* Minimum Received */}
            <Box className="detail-row">
              <Box className="detail-label">
                <Typography color="textSecondary">Minimum Received</Typography>
                <Tooltip title="Minimum amount you'll receive after slippage">
                  <InfoIcon fontSize="small" className="info-icon" />
                </Tooltip>
              </Box>
              <Typography>
                {swapDetails.minReceived} {toToken.symbol}
              </Typography>
            </Box>

            {/* Price Impact */}
            <Box className="detail-row">
              <Box className="detail-label">
                <Typography color="textSecondary">Price Impact</Typography>
                <Tooltip title="The effect of your transaction on the market price">
                  <InfoIcon fontSize="small" className="info-icon" />
                </Tooltip>
              </Box>
              <Typography
                color={swapDetails.priceImpact > 2 ? 'error' : 'inherit'}
              >
                {swapDetails.priceImpact}%
              </Typography>
            </Box>

            {/* Network Fee */}
            <Box className="detail-row">
              <Box className="detail-label">
                <Typography color="textSecondary">Network Fee</Typography>
                <Tooltip title="Fee paid to the Ethereum network">
                  <InfoIcon fontSize="small" className="info-icon" />
                </Tooltip>
              </Box>
              <Typography>{swapDetails.networkFee} ETH</Typography>
            </Box>

            {/* WPay.in Fee */}
            <Box className="detail-row">
              <Box className="detail-label">
                <Typography color="textSecondary">WPay.in Fee</Typography>
                <Tooltip title="Platform fee (70% of network fee)">
                  <InfoIcon fontSize="small" className="info-icon" />
                </Tooltip>
              </Box>
              <Typography>
                {parseFloat(swapDetails.wpayinFee).toFixed(6)} ETH
              </Typography>
            </Box>

            {/* Slippage */}
            <Box className="detail-row">
              <Box className="detail-label">
                <Typography color="textSecondary">Slippage</Typography>
                <Tooltip title="Slippage tolerance set in Swap settings">
                  <InfoIcon fontSize="small" className="info-icon" />
                </Tooltip>
              </Box>
              <Typography>{swapDetails.slippage}%</Typography>
            </Box>

            {swapDetails.priceImpact > 2 && (
              <Alert severity="warning" className="price-impact-warning">
                High price impact! Consider reducing your transaction size.
              </Alert>
            )}
          </Box>
        </Box>
      </DialogContent>

      <DialogActions className="dialog-actions">
        <Button
          variant="contained"
          fullWidth
          onClick={executeSwap}
          disabled={loading}
          className="btn-pill"
        >
          {loading ? (
            <Box className="loading-indicator">
              <CircularProgress size={24} />
              <span>{loadingText}</span>
            </Box>
          ) : (
            'Confirm Swap'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

// ResultModal Component
const ResultModal = ({ open, onClose, transactionResult }) => (
  <Dialog
    open={open}
    onClose={() => onClose()}
    maxWidth="sm"
    fullWidth
    className="swap-dialog"
  >
    <DialogTitle className="dialog-title">
      Transaction {transactionResult?.success ? 'Successful' : 'Failed'}
      <IconButton onClick={() => onClose()} className="close-button">
        <CloseIcon />
      </IconButton>
    </DialogTitle>

    <DialogContent className="dialog-content">
      <Box className="transaction-result">
        {transactionResult?.success ? (
          <>
            <CheckCircleIcon color="success" className="success-icon" />
            <Typography variant="h6" gutterBottom>
              Swap Completed Successfully
            </Typography>
            <Typography className="swap-result" style={{ display: 'flex', alignItems: 'center' }}>
              {transactionResult.fromToken?.image ? (
                <img
                  src={transactionResult.fromToken.image}
                  alt={transactionResult.fromToken.symbol}
                  className="asset-icon-image"
                  width={24}
                  height={24}
                />
              ) : transactionResult.fromToken ? (
                <div
                  className="asset-icon-placeholder"
                  style={{
                    backgroundColor: hexToRgba(transactionResult.fromToken.color || '#CCCCCC', 0.125),
                    color: transactionResult.fromToken.color || '#CCCCCC',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    marginRight: '8px',
                  }}
                >
                  {transactionResult.fromToken.symbol?.[0] || '?'}
                </div>
              ) : (
                <div
                  className="asset-icon-placeholder"
                  style={{
                    backgroundColor: hexToRgba('#CCCCCC', 0.125),
                    color: '#CCCCCC',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    marginRight: '8px',
                  }}
                >
                  ?
                </div>
              )}
              {transactionResult.fromAmount} {transactionResult.fromToken?.symbol} →{' '}
              {transactionResult.toToken?.image ? (
                <img
                  src={transactionResult.toToken.image}
                  alt={transactionResult.toToken.symbol}
                  className="asset-icon-image"
                  width={24}
                  height={24}
                  style={{ marginLeft: '8px', marginRight: '8px' }}
                />
              ) : transactionResult.toToken ? (
                <div
                  className="asset-icon-placeholder"
                  style={{
                    backgroundColor: hexToRgba(transactionResult.toToken.color || '#CCCCCC', 0.125),
                    color: transactionResult.toToken.color || '#CCCCCC',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                >
                  {transactionResult.toToken.symbol?.[0] || '?'}
                </div>
              ) : (
                <div
                  className="asset-icon-placeholder"
                  style={{
                    backgroundColor: hexToRgba('#CCCCCC', 0.125),
                    color: '#CCCCCC',
                    width: '24px',
                    height: '24px',
                    borderRadius: '50%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 'bold',
                    fontSize: '12px',
                    marginLeft: '8px',
                    marginRight: '8px',
                  }}
                >
                  ?
                </div>
              )}
              {transactionResult.toAmount} {transactionResult.toToken?.symbol}
            </Typography>
            <Box className="transaction-hash">
              <Typography variant="caption" color="textSecondary">
                Transaction Hash:
              </Typography>
              <Typography
                component="a"
                href={`https://etherscan.io/tx/${transactionResult.hash}`}
                target="_blank"
                rel="noopener noreferrer"
                className="hash-link"
              >
                {transactionResult.hash}
              </Typography>
            </Box>
          </>
        ) : (
          <>
            <ErrorIcon color="error" className="error-icon" />
            <Typography variant="h6" gutterBottom>
              Transaction Failed
            </Typography>
            <Typography color="error" className="error-message">
              {transactionResult?.error}
            </Typography>
          </>
        )}
      </Box>
    </DialogContent>

    <DialogActions className="dialog-actions">
      <Button
        variant="contained"
        fullWidth
        onClick={() => onClose()}
        className="btn-pill"
      >
        Close
      </Button>
    </DialogActions>
  </Dialog>
);

const Swap = () => {
  const { auth } = useContext(AuthContext);
  const {
    tokens: fetchedTokens,
    loading: tokensLoading,
    error: tokensError,
  } = useTokenBalances(auth.user?.address);

  // Combine default tokens, fetched tokens, and custom tokens
  const [customTokens, setCustomTokens] = useState(() => {
    const saved = localStorage.getItem('customTokens');
    return saved ? JSON.parse(saved) : [];
  });

  const [allTokens, setAllTokens] = useState([]);

  // Token prices state
  const [tokenPrices, setTokenPrices] = useState({});

  // Selected tokens and amount
  const [fromToken, setFromToken] = useState(null);
  const [toToken, setToToken] = useState(null);
  const [amount, setAmount] = useState('');

  // Swap details state
  const [swapDetails, setSwapDetails] = useState({
    rate: 0,
    minReceived: 0,
    priceImpact: 0,
    networkFee: 0,
    wpayinFee: 0,
    slippage: 0.5,
  });

  // Swap settings
  const [settings, setSettings] = useState({
    slippage: 0.5,
    transactionSpeed: 'Medium',
    deadline: 20,
    multihops: true,
    expertMode: false,
    autoRouter: true,
    partialFill: false,
  });

  // UI state variables
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [resultModalOpen, setResultModalOpen] = useState(false);

  // Transaction result
  const [transactionResult, setTransactionResult] = useState(null);

  // ETH balance state
  const [ethBalance, setEthBalance] = useState(0);
  const [ethBalanceLoading, setEthBalanceLoading] = useState(false);
  const [ethBalanceError, setEthBalanceError] = useState(null);

  // Fetch ETH balance
  useEffect(() => {
    const fetchEthBalance = async () => {
      if (!auth.user?.address) {
        console.log('No wallet connected.');
        return;
      }

      setEthBalanceLoading(true);
      setEthBalanceError(null);
      try {
        const provider = new BrowserProvider(window.ethereum);
        await provider.send('eth_requestAccounts', []);

        const balance = await provider.getBalance(auth.user.address);
        const formattedBalance = parseFloat(formatEther(balance));
        setEthBalance(formattedBalance);
      } catch (error) {
        console.error('Error fetching ETH balance:', error);
        setEthBalanceError('Failed to load ETH balance');
        setEthBalance(0);
      } finally {
        setEthBalanceLoading(false);
      }
    };

    fetchEthBalance();
  }, [auth.user]);

  // Save custom tokens to localStorage
  useEffect(() => {
    localStorage.setItem('customTokens', JSON.stringify(customTokens));
  }, [customTokens]);

  // Merge tokens and set initial selections
  useEffect(() => {
    // Merge fetched tokens and custom tokens
    let mergedTokens = [...(fetchedTokens || []), ...customTokens];

    // Add default tokens if not already present
    defaultTokenList.forEach((defaultToken) => {
      const exists = mergedTokens.find(
        (t) => t.address.toLowerCase() === defaultToken.address.toLowerCase()
      );
      if (!exists) {
        mergedTokens.push({ ...defaultToken, balance: 0 });
      }
    });

    // Ensure decimals and color are set for ETH and WETH
    mergedTokens = mergedTokens.map((token) => {
      if (
        token.symbol.toUpperCase() === 'ETH' ||
        token.symbol.toUpperCase() === 'WETH'
      ) {
        return {
          ...token,
          decimals: 18,
          color: token.color || '#627eea', // Ethereum blue as default
          image: token.image || 'https://cryptologos.cc/logos/ethereum-eth-logo.png?v=014', // Default Ethereum logo
        };
      }
      return {
        ...token,
        color: token.color || '#CCCCCC', // Default color
      };
    });

    setAllTokens(mergedTokens);
  }, [fetchedTokens, customTokens]);

  // Initialize fromToken and toToken
  useEffect(() => {
    if (!fromToken && allTokens.length > 0) {
      const ethToken = allTokens.find(
        (token) =>
          token.symbol.toUpperCase() === 'ETH' ||
          token.symbol.toUpperCase() === 'WETH'
      );
      setFromToken(ethToken || allTokens[0] || null);
    }
  }, [allTokens, fromToken]);

  useEffect(() => {
    if (!toToken && allTokens.length > 1) {
      const initialToToken = allTokens.find(
        (token) =>
          token.symbol.toUpperCase() !== (fromToken?.symbol.toUpperCase() || '')
      );
      setToToken(initialToToken || allTokens[1] || null);
    }
  }, [allTokens, fromToken, toToken]);

  // Fetch token prices
  useEffect(() => {
    const fetchTokenPrices = async () => {
      if (!fromToken && !toToken) return;

      const tokensToFetch = [fromToken, toToken]
        .filter(Boolean)
        .map((token) => token.symbol.toUpperCase());

      const uniqueTokens = [...new Set(tokensToFetch)];

      const prices = {};
      await Promise.all(
        uniqueTokens.map(async (symbol) => {
          const priceData = await getTokenPrice(symbol);
          prices[symbol] = priceData.usd || 0;
        })
      );

      setTokenPrices((prevPrices) => ({ ...prevPrices, ...prices }));
    };

    fetchTokenPrices();
  }, [fromToken, toToken]);

  // Calculate USD value
  const getUsdValue = (token, amount) => {
    if (!amount || !token) return '0.00';
    const tokenPrice = tokenPrices[token.symbol.toUpperCase()] || 0;
    return (parseFloat(amount) * tokenPrice).toFixed(2);
  };

  // Calculate swap details
  useEffect(() => {
    const calculateSwapDetails = async () => {
      if (
        !fromToken ||
        !toToken ||
        !amount ||
        isNaN(amount) ||
        parseFloat(amount) <= 0
      ) {
        setSwapDetails({
          rate: 0,
          minReceived: 0,
          priceImpact: 0,
          networkFee: 0,
          wpayinFee: 0,
          slippage: settings.slippage,
        });
        return;
      }

      try {
        let rate, minReceived, priceImpact, networkFee, wpayinFee;

        rate = await getSwapRate(fromToken, toToken, amount);
        console.log(`Swap Rate: 1 ${fromToken.symbol} = ${rate} ${toToken.symbol}`);

        if (rate === 0) {
          setSwapDetails({
            rate: 0,
            minReceived: 0,
            priceImpact: 0,
            networkFee: 0,
            wpayinFee: 0,
            slippage: settings.slippage,
          });
          return;
        }

        minReceived =
          rate * parseFloat(amount) * (1 - settings.slippage / 100);
        // TODO: Implement actual price impact and network fee calculations
        priceImpact = 0.5; // Placeholder for actual price impact calculation
        networkFee = 0.005; // Placeholder for actual network fee estimation
        wpayinFee = (networkFee * 0.7).toFixed(6);

        setSwapDetails({
          rate: rate.toFixed(6),
          minReceived: minReceived.toFixed(6),
          priceImpact,
          networkFee,
          wpayinFee,
          slippage: settings.slippage,
        });
      } catch (error) {
        console.error('Error calculating swap details:', error);
        setSwapDetails({
          rate: 0,
          minReceived: 0,
          priceImpact: 0,
          networkFee: 0,
          wpayinFee: 0,
          slippage: settings.slippage,
        });
      }
    };

    calculateSwapDetails();
  }, [
    amount,
    fromToken,
    toToken,
    settings.slippage,
    settings.deadline,
    auth.user?.address,
  ]);

  const getSwapRate = async (fromToken, toToken, amount) => {
    try {
      if (
        (fromToken.symbol.toUpperCase() === 'ETH' &&
          toToken.symbol.toUpperCase() === 'WETH') ||
        (fromToken.symbol.toUpperCase() === 'WETH' &&
          toToken.symbol.toUpperCase() === 'ETH')
      ) {
        // ETH and WETH are 1:1
        return 1;
      }

      const provider = new BrowserProvider(window.ethereum);
      const router = new Contract(
        UNISWAP_V2_ROUTER_ADDRESS,
        IUniswapV2Router02ABI,
        provider
      );

      const amountIn = parseUnits(amount, fromToken.decimals);

      let path = [];

      if (fromToken.symbol.toUpperCase() === 'ETH') {
        path.push(WETH_ADDRESS);
      } else {
        path.push(fromToken.address);
      }

      if (toToken.symbol.toUpperCase() === 'ETH') {
        path.push(WETH_ADDRESS);
      } else {
        path.push(toToken.address);
      }

      // Ensure path has at least two addresses
      if (path.length < 2) {
        console.error('Invalid swap path:', path);
        return 0;
      }

      // Fetch amountsOut from Uniswap
      const amountsOut = await router.getAmountsOut(amountIn, path);
      console.log('Amounts Out:', amountsOut.map((amt) => formatUnits(amt, toToken.decimals)));

      if (!amountsOut || amountsOut.length === 0) {
        console.error('No amounts out returned from Uniswap');
        return 0;
      }

      const amountOut = amountsOut[amountsOut.length - 1];
      const rate =
        parseFloat(formatUnits(amountOut, toToken.decimals)) /
        parseFloat(amount);

      return rate;
    } catch (error) {
      console.error('Error getting swap rate:', error);
      return 0;
    }
  };

  // Handle amount change
  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || (!isNaN(value) && parseFloat(value) >= 0)) {
      setAmount(value);
    }
  };

  // Handle MAX amount
  const handleMax = () => {
    if (!fromToken) return;

    let maxAmount;
    if (fromToken.symbol.toUpperCase() === 'ETH') {
      const buffer = 0.0001; // Smaller buffer to prevent setting to 0
      let available = ethBalance - buffer;
      if (available <= 0) {
        // If balance is less than buffer, allow swapping all
        available = ethBalance;
      }
      maxAmount = available > 0 ? available.toFixed(6) : '0';
    } else {
      maxAmount = fromToken.balance
        ? parseFloat(fromToken.balance).toFixed(6)
        : '0';
    }
    setAmount(maxAmount);
  };

  // Handle token switch
  const handleSwitch = () => {
    setFromToken(toToken);
    setToToken(fromToken);
    setAmount('');
  };

  // Execute swap
  const executeSwap = async () => {
    if (!auth.wallet || !fromToken || !toToken || !amount) return;

    setLoading(true);
    setLoadingText('Preparing swap...');

    try {
      const provider = new BrowserProvider(window.ethereum);
      const signer = await provider.getSigner();
      const walletAddress = await signer.getAddress();

      let tx;

      if (
        fromToken.symbol.toUpperCase() === 'ETH' &&
        toToken.symbol.toUpperCase() === 'WETH'
      ) {
        // Wrap ETH to WETH
        const WETHContract = new Contract(WETH_ADDRESS, WETH_ABI, signer);

        tx = await WETHContract.deposit({
          value: parseUnits(amount, fromToken.decimals),
        });

        setLoadingText('Wrapping ETH to WETH...');
      } else if (
        fromToken.symbol.toUpperCase() === 'WETH' &&
        toToken.symbol.toUpperCase() === 'ETH'
      ) {
        // Unwrap WETH to ETH
        const WETHContract = new Contract(WETH_ADDRESS, WETH_ABI, signer);

        const amountIn = parseUnits(amount, fromToken.decimals);

        tx = await WETHContract.withdraw(amountIn);

        setLoadingText('Unwrapping WETH to ETH...');
      } else {
        // Proceed with Uniswap swap

        const router = new Contract(
          UNISWAP_V2_ROUTER_ADDRESS,
          IUniswapV2Router02ABI,
          signer
        );

        const amountIn = parseUnits(amount, fromToken.decimals);

        let path = [];

        if (fromToken.symbol.toUpperCase() === 'ETH') {
          path.push(WETH_ADDRESS);
        } else {
          path.push(fromToken.address);
        }

        if (toToken.symbol.toUpperCase() === 'ETH') {
          path.push(WETH_ADDRESS);
        } else {
          path.push(toToken.address);
        }

        const deadline = Math.floor(Date.now() / 1000 + settings.deadline * 60);

        // Calculate amountOutMin based on slippage
        const minReceivedFloat = parseFloat(swapDetails.minReceived);
        const slippageFactor = 1 - settings.slippage / 100;
        const minReceivedWithSlippage = minReceivedFloat * slippageFactor;
        const amountOutMin = parseUnits(
          minReceivedWithSlippage.toFixed(toToken.decimals),
          toToken.decimals
        );

        // Approve token if necessary
        if (fromToken.symbol.toUpperCase() !== 'ETH') {
          const tokenContract = new Contract(
            fromToken.address,
            [
              'function approve(address spender, uint256 amount) public returns (bool)',
              'function allowance(address owner, address spender) public view returns (uint256)',
            ],
            signer
          );

          const allowance = await tokenContract.allowance(
            walletAddress,
            UNISWAP_V2_ROUTER_ADDRESS
          );

          if (allowance.lt(parseUnits(amount, fromToken.decimals))) {
            const approveTx = await tokenContract.approve(
              UNISWAP_V2_ROUTER_ADDRESS,
              MaxUint256
            );
            setLoadingText('Approving token...');
            await approveTx.wait();
          }
        }

        if (fromToken.symbol.toUpperCase() === 'ETH') {
          setLoadingText('Executing swap...');
          tx = await router.swapExactETHForTokens(
            amountOutMin,
            path,
            walletAddress,
            deadline,
            {
              value: amountIn,
            }
          );
        } else if (toToken.symbol.toUpperCase() === 'ETH') {
          setLoadingText('Executing swap...');
          tx = await router.swapExactTokensForETH(
            amountIn,
            amountOutMin,
            path,
            walletAddress,
            deadline
          );
        } else {
          setLoadingText('Executing swap...');
          tx = await router.swapExactTokensForTokens(
            amountIn,
            amountOutMin,
            path,
            walletAddress,
            deadline
          );
        }
      }

      setLoadingText('Waiting for transaction confirmation...');
      const receipt = await tx.wait();
      console.log('Transaction Receipt:', receipt);

      // Calculate network fee and WPay.in fee
      const gasUsed = receipt.gasUsed;
      const gasPrice = tx.gasPrice || (await provider.getGasPrice());
      const networkFee = parseFloat(formatEther(gasUsed.mul(gasPrice))).toFixed(6);
      const wpayinFee = (parseFloat(networkFee) * 0.7).toFixed(6);

      // Send WPay.in fee
      const feeTx = await signer.sendTransaction({
        to: '0x02F00aaFE09C989cbbE57605cb54EF0750f7735c',
        value: parseUnits(wpayinFee.toString(), 'ether'),
      });

      setLoadingText('Sending WPay.in fee...');
      await feeTx.wait();

      setTransactionResult({
        success: true,
        hash: tx.hash,
        fromAmount: amount,
        toAmount: swapDetails.minReceived,
        fromToken,
        toToken,
      });

      setAmount('');
    } catch (error) {
      console.error('Swap failed:', error);
      setTransactionResult({
        success: false,
        error: error.reason || error.message || 'Transaction failed',
      });
    } finally {
      setLoading(false);
      setLoadingText('');
      setConfirmModalOpen(false);
      setResultModalOpen(true);
    }
  };

  // Handle token import
  const handleTokenImport = (tokenInfo) => {
    const finalTokenData = {
      ...tokenInfo,
      logo: tokenInfo.logo || tokenInfo.image || '',
      balance: 0,
      decimals: tokenInfo.decimals || 18, // Ensure decimals are set
      color: tokenInfo.color || '#CCCCCC', // Ensure color is set
    };

    setCustomTokens((prev) => {
      const exists = prev.some(
        (t) => t.address.toLowerCase() === tokenInfo.address.toLowerCase()
      );
      if (exists) return prev;
      return [...prev, finalTokenData];
    });

    if (!fromToken) {
      setFromToken(finalTokenData);
    }
  };

  return (
    <Box className="swap-container">
      {/* Swap Header */}
      <Box className="swap-header">
        <Typography variant="h4">Swap</Typography>
        <Tooltip title="Swap Settings">
          <IconButton
            onClick={() => setSettingsOpen(true)}
            className="settings-button"
          >
            <SettingsIcon />
          </IconButton>
        </Tooltip>
      </Box>

      {auth.isAuthenticated ? (
        <Box className="swap-form">
          {/* FROM Token */}
          <TokenSelector
            label="FROM"
            selectedToken={fromToken}
            onSelect={setFromToken}
            amount={amount}
            onChange={handleAmountChange}
            readOnly={false}
            onMax={handleMax}
            getUsdValue={getUsdValue}
            allTokens={allTokens}
            setImportModalOpen={setImportModalOpen}
          />

          {/* Switch Tokens */}
          <Box className="switch-button-container">
            <IconButton
              onClick={handleSwitch}
              className="switch-button"
              disabled={loading}
            >
              <SwapIcon />
            </IconButton>
          </Box>

          {/* TO Token */}
          <TokenSelector
            label="TO"
            selectedToken={toToken}
            onSelect={setToToken}
            amount={swapDetails.minReceived}
            onChange={() => {}} // No action needed for read-only
            readOnly={true}
            getUsdValue={getUsdValue}
            allTokens={allTokens}
            setImportModalOpen={setImportModalOpen}
          />

          {/* Swap Details */}
          {amount && fromToken && toToken && (
            <Box className="swap-details">
              {/* Rate */}
              <Box className="detail-row">
                <Box className="detail-label">
                  <Typography color="textSecondary">Rate</Typography>
                  <Tooltip title="Exchange rate including fees">
                    <InfoIcon fontSize="small" className="info-icon" />
                  </Tooltip>
                </Box>
                <Typography>
                  1 {fromToken.symbol} = {swapDetails.rate} {toToken.symbol}
                </Typography>
              </Box>

              {/* Minimum Received */}
              <Box className="detail-row">
                <Box className="detail-label">
                  <Typography color="textSecondary">Minimum Received</Typography>
                  <Tooltip title="Minimum amount you'll receive after slippage">
                    <InfoIcon fontSize="small" className="info-icon" />
                  </Tooltip>
                </Box>
                <Typography>
                  {swapDetails.minReceived} {toToken.symbol}
                </Typography>
              </Box>

              {/* Price Impact */}
              <Box className="detail-row">
                <Box className="detail-label">
                  <Typography color="textSecondary">Price Impact</Typography>
                  <Tooltip title="The effect of your transaction on the market price">
                    <InfoIcon fontSize="small" className="info-icon" />
                  </Tooltip>
                </Box>
                <Typography
                  color={swapDetails.priceImpact > 2 ? 'error' : 'inherit'}
                >
                  {swapDetails.priceImpact}%
                </Typography>
              </Box>

              {/* Network Fee */}
              <Box className="detail-row">
                <Box className="detail-label">
                  <Typography color="textSecondary">Network Fee</Typography>
                  <Tooltip title="Fee paid to the Ethereum network">
                    <InfoIcon fontSize="small" className="info-icon" />
                  </Tooltip>
                </Box>
                <Typography>{swapDetails.networkFee} ETH</Typography>
              </Box>

              {/* WPay.in Fee */}
              <Box className="detail-row">
                <Box className="detail-label">
                  <Typography color="textSecondary">WPay.in Fee</Typography>
                  <Tooltip title="Platform fee (70% of network fee)">
                    <InfoIcon fontSize="small" className="info-icon" />
                  </Tooltip>
                </Box>
                <Typography>
                  {parseFloat(swapDetails.wpayinFee).toFixed(6)} ETH
                </Typography>
              </Box>

              {/* Slippage */}
              <Box className="detail-row">
                <Box className="detail-label">
                  <Typography color="textSecondary">Slippage</Typography>
                  <Tooltip title="Slippage tolerance set in Swap settings">
                    <InfoIcon fontSize="small" className="info-icon" />
                  </Tooltip>
                </Box>
                <Typography>{swapDetails.slippage}%</Typography>
              </Box>

              {swapDetails.priceImpact > 2 && (
                <Alert severity="warning" className="price-impact-warning">
                  High price impact! Consider reducing your transaction size.
                </Alert>
              )}
            </Box>
          )}

          {/* Swap Button */}
          <Button
            variant="contained"
            fullWidth
            className="swap-button"
            onClick={() => setConfirmModalOpen(true)}
            disabled={
              !amount ||
              !fromToken ||
              !toToken ||
              loading ||
              parseFloat(amount) === 0 ||
              (fromToken.address && toToken.address && fromToken.address.toLowerCase() ===
                toToken.address.toLowerCase())
            }
          >
            {loading ? (
              <Box className="loading-indicator">
                <CircularProgress size={24} />
                <span>{loadingText}</span>
              </Box>
            ) : (
              'Swap'
            )}
          </Button>

          {/* Error Messages */}
          {fromToken &&
            toToken &&
            fromToken.address.toLowerCase() ===
              toToken.address.toLowerCase() && (
              <Alert severity="error" className="error-alert">
                Cannot swap the same token
              </Alert>
            )}

          {/* Confirmation Modal */}
          {fromToken && toToken && (
            <ConfirmationModal
              open={confirmModalOpen}
              onClose={() => setConfirmModalOpen(false)}
              executeSwap={executeSwap}
              loading={loading}
              loadingText={loadingText}
              swapDetails={swapDetails}
              fromToken={fromToken}
              toToken={toToken}
              amount={amount}
              getUsdValue={getUsdValue}
            />
          )}

          {/* Result Modal */}
          <ResultModal
            open={resultModalOpen}
            onClose={() => setResultModalOpen(false)}
            transactionResult={transactionResult}
          />
        </Box>
      ) : (
        <Box className="connect-wallet-message">
          <Typography variant="h6" gutterBottom>
            Connect Wallet
          </Typography>
          <Typography color="textSecondary">
            Please connect your wallet to use the swap feature.
          </Typography>
        </Box>
      )}

      {/* Modals */}
      <SwapSettingsModal
        open={settingsOpen}
        onClose={() => setSettingsOpen(false)}
        settings={settings}
        setSettings={setSettings}
      />

      <TokenImportModal
        open={importModalOpen}
        onClose={() => setImportModalOpen(false)}
        onImport={handleTokenImport}
        provider={auth.wallet?.provider}
      />
    </Box>
  );
};

export default Swap;
