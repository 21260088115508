// src/components/Dashboard/Swap/tokens.js

export const tokenList = [
  {
    name: 'Dai',
    symbol: 'DAI',
    address: '0x6B175474E89094C44Da98b954EedeAC495271d0F',
    decimals: 18,
    logo: 'https://assets.coingecko.com/coins/images/9956/large/4943.png',
  },
  {
    name: 'Tether',
    symbol: 'USDT',
    address: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    decimals: 6,
    logo: 'https://assets.coingecko.com/coins/images/325/large/Tether-logo.png',
  },
  // Add more tokens as needed
  // *** Ujistěte se, že zde není WETH token ***
];
