// src/components/Dashboard/FeedBackSupport/FeedBackSupport.js

import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { NotificationsContext } from '../../../contexts/NotificationsContext';
import { Navigate } from 'react-router-dom';
import './FeedBackSupport.css'; // Ensure this CSS file exists for styling

const FeedBackSupport = () => {
  const { auth } = useContext(AuthContext);
  const { addNotification } = useContext(NotificationsContext);

  // **Move hooks above any conditional statements**
  const [formData, setFormData] = useState({
    subject: '',
    message: '',
    category: '',
    attachment: null,
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // **Now handle conditional rendering**
  if (!auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === 'attachment') {
      setFormData({ ...formData, [name]: files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!formData.subject || !formData.category || !formData.message) {
      addNotification({
        type: 'error',
        message: 'Please fill in all required fields.',
      });
      return;
    }

    setIsSubmitting(true);

    try {
      // Implement your feedback submission logic here
      // For example, send data to your backend or an API endpoint

      // Simulating submission delay
      await new Promise((resolve) => setTimeout(resolve, 2000));

      addNotification({
        type: 'success',
        message: 'Your feedback has been submitted successfully!',
      });

      // Reset form
      setFormData({
        subject: '',
        category: '',
        message: '',
        attachment: null,
      });
    } catch (error) {
      console.error('Feedback submission error:', error);
      addNotification({
        type: 'error',
        message: 'Failed to submit feedback. Please try again later.',
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="feedback_container">
      <h1 className="feedback_title">Feedback & Support</h1>
      <div className="feedback_layout">
        {/* Sidebar */}
        <aside className="feedback_sidebar">
          <nav className="feedback_nav">
            <div className="feedback_nav-item active">
              <i className="fas fa-envelope"></i>
              Contact Us
            </div>
            <div className="feedback_nav-item">
              <i className="fas fa-question-circle"></i>
              FAQ
            </div>
            <div className="feedback_nav-item">
              <i className="fas fa-info-circle"></i>
              About
            </div>
            {/* Add more navigation items as needed */}
          </nav>
          {/* Additional Sidebar Content */}
          <div className="feedback_stats">
            <div className="feedback_stats-item">
              <span className="feedback_stats-label">Resolved Tickets</span>
              <span className="feedback_stats-value">150</span>
            </div>
            <div className="feedback_stats-item">
              <span className="feedback_stats-label">Pending Tickets</span>
              <span className="feedback_stats-value">5</span>
            </div>
            {/* Add more stats as needed */}
          </div>
        </aside>

        {/* Main Content */}
        <main className="feedback_content">
          {/* Feedback Form */}
          <form className="feedback_form" onSubmit={handleSubmit}>
            <div className="feedback_form-group">
              <label htmlFor="subject" className="feedback_label">Subject</label>
              <input
                type="text"
                id="subject"
                name="subject"
                className="feedback_input"
                value={formData.subject}
                onChange={handleChange}
                placeholder="Enter the subject"
                required
              />
            </div>

            <div className="feedback_form-group">
              <label htmlFor="category" className="feedback_label">Category</label>
              <select
                id="category"
                name="category"
                className="feedback_input"
                value={formData.category}
                onChange={handleChange}
                required
              >
                <option value="">Select a category</option>
                <option value="Bug Report">Bug Report</option>
                <option value="Feature Request">Feature Request</option>
                <option value="General Inquiry">General Inquiry</option>
                {/* Add more categories as needed */}
              </select>
            </div>

            <div className="feedback_form-group">
              <label htmlFor="message" className="feedback_label">Message</label>
              <textarea
                id="message"
                name="message"
                className="feedback_input"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message"
                rows="6"
                required
              ></textarea>
            </div>

            <div className="feedback_form-group">
              <label htmlFor="attachment" className="feedback_label">Attachment (Optional)</label>
              <input
                type="file"
                id="attachment"
                name="attachment"
                className="feedback_input"
                onChange={handleChange}
                accept=".png, .jpg, .jpeg, .pdf"
              />
            </div>

            <button
              type="submit"
              className="feedback_button"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Submit'}
            </button>
          </form>
        </main>
      </div>
    </div>
  );
};

export default FeedBackSupport;
