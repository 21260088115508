// src/components/Dashboard/Wallet/Wallet.js

import React, { useState, useMemo, useEffect, useContext } from 'react';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
} from 'recharts';
import './Wallet.css';
import { AuthContext } from '../../../contexts/AuthContext';
import useTokenBalances from '../../../hooks/useTokenBalances';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getTokenPrice } from '../../../utils/getTokenPrice';
import DepositDialog from './DepositDialog'; // Import DepositDialog
import TokenImportModal from '../Swap/TokenImportModal'; // Import TokenImportModal
import LoadingSpinner from '../../../components/Loading/Loading'; // Import LoadingSpinner
import {
  symbolToId,
  assetColors,
  periodConfigs,
} from '../../../utils/constants'; // Import constants

// Helper Functions
const hexToRgba = (hex, alpha) => {
  if (typeof hex !== 'string') return `rgba(204, 204, 204, ${alpha})`;
  const sanitizedHex = hex.replace('#', '');
  if (sanitizedHex.length !== 6) return `rgba(204, 204, 204, ${alpha})`;
  const bigint = parseInt(sanitizedHex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const seededRandom = (seed) => {
  return Math.abs(Math.sin(seed) * 10000 % 1);
};

const formatDate = (date, unit) => {
  switch (unit) {
    case 'minute':
    case 'hour':
      return date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });
    case 'day':
    case 'week':
      return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    case 'month':
      return date.toLocaleDateString('en-US', { month: 'short', year: 'numeric' });
    case 'year':
      return date.toLocaleDateString('en-US', { year: 'numeric' });
    default:
      return '';
  }
};

const Wallet = () => {
  // State Management
  const { auth } = useContext(AuthContext);
  const { tokens: fetchedAssets = [], loading: assetsLoading, error: assetsError } = useTokenBalances(auth.user?.address || '');

  // Custom Tokens State
  const [customTokens, setCustomTokens] = useState(() => {
    const saved = localStorage.getItem('customTokens');
    return saved ? JSON.parse(saved) : [];
  });

  // State for Deposit Dialog
  const [transactions, setTransactions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('24H');
  const [coinPrices, setCoinPrices] = useState({});
  const [historicalData, setHistoricalData] = useState({});
  const [depositDialogOpen, setDepositDialogOpen] = useState(false); // State for DepositDialog
  const [selectedDepositAsset, setSelectedDepositAsset] = useState(null); // State for pre-selected asset
  const [loading, setLoading] = useState(true); // Loading state

  // State for Import Token Modal
  const [importModalOpen, setImportModalOpen] = useState(false); // State for ImportTokenModal

  // Calculate total balance
  const totalBalance = useMemo(() => {
    return [...fetchedAssets, ...customTokens].reduce((sum, asset) => {
      const id = symbolToId[asset.symbol];
      const price = coinPrices[id]?.usd || 0;
      return sum + (parseFloat(asset.balance) || 0) * price;
    }, 0);
  }, [fetchedAssets, customTokens, coinPrices]);

  // Prepare assets including custom tokens
  const preparedAssets = useMemo(() => {
    return [...fetchedAssets, ...customTokens].map((asset) => {
      const id = symbolToId[asset.symbol];
      const price = coinPrices[id]?.usd || 0;
      const change = coinPrices[id]?.change_24h || 0;
      const name = coinPrices[id]?.name || asset.name || asset.symbol;
      const image = coinPrices[id]?.image || asset.image || ''; // Use asset.image for custom tokens if available
      const value = (parseFloat(asset.balance) || 0) * price;
      const allocation = totalBalance > 0 ? (value / totalBalance) * 100 : 0;

      return {
        ...asset,
        color: assetColors[asset.symbol] || '#CCCCCC',
        price,
        change,
        name,
        symbol: asset.symbol,
        image,
        value,
        allocation,
      };
    });
    //.filter((asset) => asset.value > 0);
  }, [fetchedAssets, customTokens, coinPrices, totalBalance]);

  // Generate historical data
  const generateHistoricalData = (period, baseValue) => {
    const config = periodConfigs[period];
    if (!config) return [];

    const { interval, unit, dataPoints } = config;
    const baseDate = new Date();
    const data = [];

    const volatility = {
      minute: 0.0005,
      hour: 0.001,
      day: 0.01,
      week: 0.02,
      month: 0.05,
      year: 0.1,
    };

    const trendDirection = seededRandom(baseDate.getTime()) > 0.5 ? 1 : -1;
    const maxChange = volatility[unit] * 10;

    for (let i = dataPoints - 1; i >= 0; i--) {
      const date = new Date(baseDate);

      switch (unit) {
        case 'minute':
          date.setMinutes(date.getMinutes() - i * interval);
          break;
        case 'hour':
          date.setHours(date.getHours() - i * interval);
          break;
        case 'day':
          date.setDate(date.getDate() - i * interval);
          break;
        case 'week':
          date.setDate(date.getDate() - i * interval * 7);
          break;
        case 'month':
          date.setMonth(date.getMonth() - i * interval);
          break;
        case 'year':
          date.setFullYear(date.getFullYear() - i * interval);
          break;
        default:
          break;
      }

      const timeProgress = i / (dataPoints - 1);
      const trendValue = baseValue * (1 - maxChange * trendDirection * timeProgress);
      const randomVariation =
        (seededRandom(i * date.getTime()) - 0.5) * volatility[unit] * baseValue;

      data.push({
        date: formatDate(date, unit),
        value: i === 0 ? baseValue : trendValue + randomVariation,
      });
    }

    return data;
  };

  // Effect for updating historical data
  useEffect(() => {
    if (totalBalance > 0) {
      setHistoricalData((prev) => ({
        ...prev,
        [selectedPeriod]: generateHistoricalData(selectedPeriod, totalBalance),
      }));
    }
  }, [selectedPeriod, totalBalance]);

  // Fetch coin prices
  useEffect(() => {
    const fetchCoinPrices = async () => {
      const allAssets = [...fetchedAssets, ...customTokens];
      if (allAssets.length === 0) return;

      try {
        const pricePromises = allAssets.map((asset) => getTokenPrice(asset.symbol));
        const prices = await Promise.all(pricePromises);

        const fetchedPrices = {};
        allAssets.forEach((asset, index) => {
          const id = symbolToId[asset.symbol];
          if (id && prices[index]) {
            fetchedPrices[id] = {
              usd: prices[index].usd,
              change_24h: prices[index].change_24h,
              name: prices[index].name,
              symbol: prices[index].symbol,
              image: prices[index].image,
            };
          }
        });

        setCoinPrices(fetchedPrices);
      } catch (error) {
        console.error('Error fetching prices:', error);
        toast.error('Failed to fetch cryptocurrency prices');
      }
    };

    fetchCoinPrices();
    const interval = setInterval(fetchCoinPrices, 60000); // Refresh every minute
    return () => clearInterval(interval);
  }, [fetchedAssets, customTokens]);

  // Fetch transactions
  useEffect(() => {
    const fetchTransactions = async () => {
      if (!auth?.user?.address || !auth.chainId) return;

      try {
        const chainId = parseInt(auth.chainId, 16);
        let apiUrl;
        let apiKey;

        switch (chainId) {
          case 1:
            apiUrl = 'https://api.etherscan.io/api';
            apiKey = process.env.REACT_APP_ETHERSCAN_API_KEY;
            break;
          case 42161:
            apiUrl = 'https://api.arbiscan.io/api';
            apiKey = process.env.REACT_APP_ARBISCAN_API_KEY;
            break;
          case 8453:
            apiUrl = 'https://api.basescan.org/api';
            apiKey = process.env.REACT_APP_BASESCAN_API_KEY;
            break;
          default:
            apiUrl = 'https://api.etherscan.io/api';
            apiKey = process.env.REACT_APP_ETHERSCAN_API_KEY;
            break;
        }

        const response = await axios.get(apiUrl, {
          params: {
            module: 'account',
            action: 'txlist',
            address: auth.user.address,
            startblock: 0,
            endblock: 99999999,
            page: 1,
            offset: 100,
            sort: 'desc',
            apikey: apiKey || 'YOUR_API_KEY',
          },
        });

        if (response.data.status === '1' && response.data.result.length > 0) {
          setTransactions(response.data.result.slice(0, 4));
        } else {
          setTransactions([]);
        }
      } catch (error) {
        console.error('Error loading transactions:', error);
        toast.error('Error loading transactions');
        setTransactions([]);
      }
    };

    fetchTransactions();
    const interval = setInterval(fetchTransactions, 30000); // Refresh every 30 seconds
    return () => clearInterval(interval);
  }, [auth?.user?.address, auth.chainId]);

  // Balance Change Calculation
  const balanceChange = useMemo(() => {
    if (totalBalance === 0) return 0;
    return preparedAssets.reduce((sum, asset) => {
      return sum + (asset.change * asset.value) / totalBalance;
    }, 0);
  }, [preparedAssets, totalBalance]);

  // Chart data
  const chartData = useMemo(() => {
    return historicalData[selectedPeriod] || [];
  }, [selectedPeriod, historicalData]);

  // Custom tooltip for chart
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload?.[0]) {
      return (
        <div className="chart-tooltip">
          <div className="tooltip-date">{payload[0].payload.date}</div>
          <div className="tooltip-value">
            <span className="material-icons">attach_money</span>
            {payload[0].value !== undefined && payload[0].value !== null
              ? `$${payload[0].value.toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}`
              : '$0.00'}
          </div>
        </div>
      );
    }
    return null;
  };

  // Loading State Management
  useEffect(() => {
    // Determine if all data is loaded
    const isAssetsLoaded = fetchedAssets.length > 0 || customTokens.length > 0;
    const isPricesLoaded = Object.keys(coinPrices).length > 0;
    const isTransactionsLoaded = transactions.length >= 0; // Transactions can be empty

    if (isAssetsLoaded && isPricesLoaded && isTransactionsLoaded) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [fetchedAssets, customTokens, coinPrices, transactions]);

  // Handle Token Import
  const handleTokenImport = (importedToken) => {
    // Check if token already exists
    const exists = customTokens.some(
      (token) => token.address.toLowerCase() === importedToken.address.toLowerCase()
    );
    if (exists) {
      toast.info('Token is already imported.');
      return;
    }

    const newToken = {
      address: importedToken.address,
      symbol: importedToken.symbol.toUpperCase(),
      name: importedToken.name,
      decimals: parseInt(importedToken.decimals, 10),
      image: importedToken.logo || '', // Use imported logo or empty string
      balance: '0', // Initial balance; will be updated by useTokenBalances
    };

    setCustomTokens((prev) => {
      const updated = [...prev, newToken];
      localStorage.setItem('customTokens', JSON.stringify(updated));
      return updated;
    });

    toast.success(`Token ${newToken.symbol} imported successfully!`);
  };

  // Render loading spinner if data is loading
  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="wallet-container">
      {/* Top Section */}
      <div className="top-section">
        {/* Balance Box */}
        <div className="balance-box">
          <div className="balance-box-content">
            <div className="balance-left">
              <div className="profile-section">
                <img
                  src={auth.profile?.profilePicture || '/default-avatar.png'}
                  alt="Profile"
                  className="profile-avatar"
                  width={64}
                  height={64}
                />
              </div>
              <div className="balance-content">
                <p className="balance-title">MY BALANCE</p>
                <p className="balance-amount">
                  ${totalBalance.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </p>
                <p className="balance-eth">
                  {(
                    totalBalance / (coinPrices['ethereum']?.usd || 3200)
                  ).toFixed(4)}{' '}
                  ETH
                </p>
                <div
                  className={`change-badge ${
                    balanceChange >= 0 ? 'positive' : 'negative'
                  }`}
                >
                  <span className="material-icons">
                    {balanceChange >= 0 ? 'arrow_upward' : 'arrow_downward'}
                  </span>
                  {Math.abs(balanceChange).toFixed(2)}%
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Quick Actions */}
        <div className="quick-actions">
          {[
            { icon: 'send', label: 'Withdraw', color: '#EF4444' },
            { icon: 'arrow_upward', label: 'Deposit', color: '#10B981' },
            { icon: 'swap_horiz', label: 'Swap', color: '#6366F1' },
            { icon: 'shopping_cart', label: 'Market', color: '#3B82F6' },
          ].map(({ icon, label, color }) => (
            <button
              key={label}
              type="button"
              className="action-card-simple"
              onClick={() => {
                if (label === 'Deposit') {
                  setSelectedDepositAsset(null); // No pre-selected asset
                  setDepositDialogOpen(true);
                } else {
                  toast.info(`${label} action clicked.`);
                }
              }}
              aria-label={label}
            >
              <div
                className="action-icon-simple"
                style={{
                  backgroundColor: hexToRgba(color, 0.125),
                  color: color,
                }}
              >
                <span className="material-icons">{icon}</span>
              </div>
              <p className="action-label-simple">{label}</p>
            </button>
          ))}
          {/* Deposit Dialog */}
          <DepositDialog
            open={depositDialogOpen}
            onClose={() => setDepositDialogOpen(false)}
            selectedAsset={selectedDepositAsset}
            assets={preparedAssets} // Pass prepared assets
          />
        </div>

        {/* Chart Section */}
        <div className="chart-section">
          <div className="card-header">
            <h2>Balance History</h2>
            <div className="time-filters">
              {Object.keys(periodConfigs).map((period) => (
                <button
                  key={period}
                  type="button"
                  className={`time-button ${
                    period === selectedPeriod ? 'active' : ''
                  }`}
                  onClick={() => setSelectedPeriod(period)}
                  aria-label={`Select ${period}`}
                >
                  {period}
                </button>
              ))}
            </div>
          </div>
          <div className="chart-container">
            <ResponsiveContainer width="100%" height={300}>
              <AreaChart data={chartData}>
                <defs>
                  <linearGradient id="colorValue" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#1f4ccc" stopOpacity={0.3} />
                    <stop offset="95%" stopColor="#1f4ccc" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="date"
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: '#94A3B8' }}
                  minTickGap={20}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  tick={{ fill: '#94A3B8' }}
                  domain={['dataMin', 'dataMax']}
                  tickFormatter={(value) =>
                    value !== undefined && value !== null
                      ? `$${value.toLocaleString()}`
                      : '$0.00'
                  }
                />
                <Tooltip content={<CustomTooltip />} />
                <Area
                  type="monotone"
                  dataKey="value"
                  stroke="#1f4ccc"
                  fillOpacity={1}
                  fill="url(#colorValue)"
                  strokeWidth={2}
                />
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>

      {/* Middle Section */}
      <div className="middle-section">
        {/* Portfolio Section */}
        <div className="portfolio-section">
          <div className="section-card">
            <div className="card-header">
              <h2>Portfolio</h2>
            </div>
            <div className="portfolio-content">
              <div className="pie-chart">
                {preparedAssets.some((asset) => asset.value > 0) ? (
                  <ResponsiveContainer width="100%" height={350}>
                    <PieChart>
                      <Pie
                        data={preparedAssets.filter((asset) => asset.value > 0)}
                        innerRadius={60}
                        outerRadius={90}
                        paddingAngle={3}
                        dataKey="allocation"
                        labelLine={false}
                        label={({ symbol, allocation, cx, cy, midAngle, outerRadius }) => {
                          const RADIAN = Math.PI / 180;
                          const radius = outerRadius * 1.2;
                          const x = cx + radius * Math.cos(-midAngle * RADIAN);
                          const y = cy + radius * Math.sin(-midAngle * RADIAN);

                          return (
                            <text
                              x={x}
                              y={y}
                              textAnchor={x > cx ? 'start' : 'end'}
                              dominantBaseline="central"
                              fill="#FFFFFF"
                              fontSize="12"
                            >
                              {`${symbol} ${allocation.toFixed(1)}%`}
                            </text>
                          );
                        }}
                      >
                        {preparedAssets
                          .filter((asset) => asset.value > 0)
                          .map((asset) => (
                            <Cell
                              key={`cell-${asset.symbol}`}
                              fill={asset.color}
                            />
                          ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>
                ) : (
                  <div className="no-data-message">No assets to display</div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Activity Section */}
        <div className="activity-section">
          <div className="section-card">
            <div className="card-header">
              <h2>Last Activity</h2>
              {transactions.length > 0 && (
                <button
                  type="button"
                  className="view-all-button"
                  onClick={() => toast.info('View All clicked.')}
                  aria-label="View All Transactions"
                >
                  View All <span className="material-icons">arrow_forward</span>
                </button>
              )}
            </div>
            <div className="transactions-list">
              {transactions.length > 0 ? (
                transactions.map((tx) => {
                  const isReceived =
                    tx.to?.toLowerCase() === auth.user?.address?.toLowerCase();
                  const formattedValue = (parseFloat(tx.value) / 1e18).toFixed(6);
                  const usdValue =
                    (parseFloat(tx.value) / 1e18) *
                    (coinPrices['ethereum']?.usd || 2000);

                  return (
                    <div key={tx.hash} className="transaction-item">
                      <div className="transaction-info">
                        <div
                          className={`transaction-icon ${
                            isReceived ? 'received' : 'sent'
                          }`}
                        >
                          <span className="material-icons">
                            {isReceived ? 'arrow_downward' : 'arrow_upward'}
                          </span>
                        </div>
                        <div>
                          <div className="transaction-type">
                            {isReceived ? 'Received' : 'Sent'}{' '}
                            {tx.contractName || 'ETH'}
                          </div>
                          <div className="transaction-date">
                            <span className="material-icons">access_time</span>{' '}
                            {new Date(tx.timeStamp * 1000).toLocaleString()}
                          </div>
                        </div>
                      </div>
                      <div className="transaction-amount">
                        <div
                          className={`amount ${
                            isReceived ? 'positive' : 'negative'
                          }`}
                        >
                          {isReceived ? '+' : '-'}
                          {formattedValue} ETH
                        </div>
                        <div className="amount-usd">
                          ${usdValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="no-transactions-message">
                  No recent transactions
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Assets Table Section */}
      <div className="assets-section">
        <div className="section-card">
          <div className="card-header">
            <div className="assets-title">
              <h2>Assets</h2>
              <button
                type="button"
                className="import-token-button"
                onClick={() => setImportModalOpen(true)}
                aria-label="Import Token"
              >
                <span className="material-icons">add</span>
              </button>
            </div>
            <button
              type="button"
              className="filter-button"
              onClick={() => toast.info('Filter clicked.')}
              aria-label="Filter Assets"
            >
              All Assets <span className="material-icons">arrow_drop_down</span>
            </button>
          </div>
          <div className="table-responsive">
            <table className="assets-table">
              <thead>
                <tr>
                  <th>Asset</th>
                  <th>Price</th>
                  <th>24h Change</th>
                  <th>Balance</th>
                  <th>Value</th>
                  <th>Allocation</th>
                  <th>Actions</th> {/* Changed from "Trend" to "Actions" */}
                </tr>
              </thead>
              <tbody>
                {preparedAssets.map((asset) => (
                  <tr key={`row-${asset.symbol}-${asset.address || asset.symbol}`}>
                    <td>
                      <div className="asset-info">
                        {asset.image ? (
                          <img
                            src={asset.image}
                            alt={asset.name}
                            className="asset-icon-image"
                            width={24}
                            height={24}
                          />
                        ) : (
                          <div
                            className="asset-icon-placeholder"
                            style={{
                              backgroundColor: hexToRgba(asset.color, 0.125),
                              color: asset.color,
                              width: '24px',
                              height: '24px',
                              borderRadius: '50%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                              fontWeight: 'bold',
                              fontSize: '12px',
                            }}
                          >
                            {asset.symbol?.[0] || '?'}
                          </div>
                        )}
                        <div>
                          <div className="asset-name">{asset.name}</div>
                          <div className="asset-symbol">{asset.symbol}</div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {asset.price !== undefined && asset.price !== null
                        ? `$${asset.price.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : '$0.00'}
                    </td>
                    <td>
                      {asset.change !== undefined && asset.change !== null ? (
                        <div
                          className={`change-badge ${
                            asset.change >= 0 ? 'positive' : 'negative'
                          }`}
                        >
                          <span className="material-icons">
                            {asset.change >= 0 ? 'trending_up' : 'trending_down'}
                          </span>
                          {Math.abs(asset.change).toFixed(2)}%
                        </div>
                      ) : (
                        '0.00%'
                      )}
                    </td>
                    <td>
                      {asset.balance !== undefined && asset.balance !== null ? (
                        <div className="asset-balance">
                          <div>{parseFloat(asset.balance).toFixed(8)}</div>
                          <div className="asset-symbol">{asset.symbol}</div>
                        </div>
                      ) : (
                        '0.00000000'
                      )}
                    </td>
                    <td>
                      {asset.value !== undefined && asset.value !== null
                        ? `$${asset.value.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}`
                        : '$0.00'}
                    </td>
                    <td>
                      {asset.allocation !== undefined && asset.allocation !== null ? (
                        <div className="allocation-bar">
                          <div
                            className="fill"
                            style={{
                              width: `${Math.min(asset.allocation, 100)}%`,
                              backgroundColor: asset.color,
                            }}
                          />
                          <span>{asset.allocation.toFixed(1)}%</span>
                        </div>
                      ) : (
                        '0.0%'
                      )}
                    </td>
                    <td className="actions-cell"> {/* New Actions Cell */}
                      <div className="asset-actions">
                        <button
                          type="button"
                          className="action-icon-button"
                          onClick={() => toast.info(`Withdraw ${asset.symbol} clicked.`)}
                          title="Withdraw"
                          aria-label={`Withdraw ${asset.symbol}`}
                        >
                          <span className="material-icons">send</span>
                        </button>
                        <button
                          type="button"
                          className="action-icon-button"
                          onClick={() => {
                            setSelectedDepositAsset(asset); // Pre-select asset
                            setDepositDialogOpen(true);
                          }}
                          title="Deposit"
                          aria-label={`Deposit ${asset.symbol}`}
                        >
                          <span className="material-icons">arrow_upward</span>
                        </button>
                        <button
                          type="button"
                          className="action-icon-button"
                          onClick={() => toast.info(`Swap ${asset.symbol} clicked.`)}
                          title="Swap"
                          aria-label={`Swap ${asset.symbol}`}
                        >
                          <span className="material-icons">swap_horiz</span>
                        </button>
                        <button
                          type="button"
                          className="action-icon-button"
                          onClick={() => toast.info(`Market ${asset.symbol} clicked.`)}
                          title="Market"
                          aria-label={`Market ${asset.symbol}`}
                        >
                          <span className="material-icons">shopping_cart</span>
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {/* Assets Table Import Token Modal */}
      <TokenImportModal
        open={importModalOpen}
        onClose={() => setImportModalOpen(false)}
        onImport={handleTokenImport}
        provider={auth.wallet?.provider}
      />
    </div>
  );
};

export default Wallet;
