// src/contexts/AuthContext.js

import React, { createContext, useState, useEffect } from 'react';
import { BrowserProvider } from 'ethers';
import { toast } from 'react-toastify';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState({
    isAuthenticated: false,
    user: null,
    wallet: null,
    walletType: null,
    profile: null,
    chainId: null,
  });

  const [temporaryMnemonic, setTemporaryMnemonicState] = useState(
    () => localStorage.getItem('temporaryMnemonic') || null
  );

  useEffect(() => {
    const storedAuth = localStorage.getItem('auth');
    if (storedAuth) {
      const parsedAuth = JSON.parse(storedAuth);
      const currentTime = Date.now();
      const sessionDuration = 2 * 60 * 60 * 1000; // 2 hours in milliseconds
      if (currentTime - parsedAuth.timestamp < sessionDuration) {
        setAuth((prevAuth) => ({
          ...prevAuth,
          isAuthenticated: parsedAuth.isAuthenticated,
          user: parsedAuth.user,
          walletType: parsedAuth.walletType,
          profile: getProfileFromStorage(parsedAuth.user.address),
        }));
      } else {
        localStorage.removeItem('auth');
      }
    }
  }, []);

  useEffect(() => {
    const initializeWallet = async () => {
      if (!auth.isAuthenticated || auth.wallet || !auth.walletType) return;

      if (typeof window.ethereum === 'undefined') {
        toast.error('Please install MetaMask or a compatible wallet.');
        return;
      }

      // Wait briefly to ensure ethereum is fully loaded
      await new Promise((resolve) => setTimeout(resolve, 500));

      if (!window.ethereum || typeof window.ethereum.on !== 'function') {
        toast.error('Ethereum provider not fully supported. Please refresh or reinstall.');
        return;
      }

      try {
        const provider = new BrowserProvider(window.ethereum);
        await provider.send('eth_requestAccounts', []);
        const signer = await provider.getSigner();
        const address = await signer.getAddress();
        const chainId = await provider.send('eth_chainId', []);
        setAuth((prevAuth) => ({
          ...prevAuth,
          wallet: provider,
          user: { address },
          chainId,
        }));

        // Listen for account and network changes
        window.ethereum.on('accountsChanged', handleAccountsChanged);
        window.ethereum.on('chainChanged', handleChainChanged);
      } catch (error) {
        console.error('Error initializing wallet:', error);
        toast.error('Failed to initialize wallet.');
      }
    };

    initializeWallet();

    return () => {
      if (window.ethereum?.removeListener) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, [auth.isAuthenticated, auth.walletType]);

  const handleAccountsChanged = (accounts) => {
    if (accounts.length === 0) {
      logout();
    } else {
      setAuth((prevAuth) => ({
        ...prevAuth,
        user: { address: accounts[0] },
      }));
    }
  };

  const handleChainChanged = (chainId) => {
    if (auth.isAuthenticated && auth.walletType) {
      setAuth((prevAuth) => ({
        ...prevAuth,
        chainId,
        wallet: null,
      }));
    }
  };

  const getProfileFromStorage = (address) => {
    const profiles = JSON.parse(localStorage.getItem('profiles')) || {};
    return profiles[address] || null;
  };

  const saveProfileToStorage = (address, profile) => {
    const profiles = JSON.parse(localStorage.getItem('profiles')) || {};
    profiles[address] = profile;
    localStorage.setItem('profiles', JSON.stringify(profiles));
  };

  const login = (userData) => {
    const newAuthState = {
      isAuthenticated: true,
      user: { address: userData.address },
      wallet: null,
      walletType: userData.walletType,
      profile: getProfileFromStorage(userData.address),
    };
    setAuth(newAuthState);

    const authToStore = {
      isAuthenticated: true,
      user: { address: userData.address },
      walletType: userData.walletType,
      timestamp: Date.now(),
    };
    localStorage.setItem('auth', JSON.stringify(authToStore));
  };

  const logout = () => {
    setAuth({
      isAuthenticated: false,
      user: null,
      wallet: null,
      walletType: null,
      profile: null,
      chainId: null,
    });
    setTemporaryMnemonic(null);
    localStorage.removeItem('temporaryMnemonic');
    localStorage.removeItem('auth');

    if (window.ethereum?.removeListener) {
      window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      window.ethereum.removeListener('chainChanged', handleChainChanged);
    }
  };

  const setTemporaryMnemonic = (mnemonic) => {
    setTemporaryMnemonicState(mnemonic);
    if (mnemonic) {
      localStorage.setItem('temporaryMnemonic', mnemonic);
    } else {
      localStorage.removeItem('temporaryMnemonic');
    }
  };

  const setProfile = (profileData) => {
    if (auth.user && auth.user.address) {
      saveProfileToStorage(auth.user.address, profileData);
      setAuth((prevAuth) => ({
        ...prevAuth,
        profile: profileData,
      }));
    }
  };

  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        logout,
        temporaryMnemonic,
        setTemporaryMnemonic,
        setProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
