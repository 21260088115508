// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Loading Overlay */
.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(17, 17, 17, 0.6); /* Semi-transparent dark background */
    backdrop-filter: blur(8px); /* Blur effect */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Loading/Loading.css"],"names":[],"mappings":"AAAA,oBAAoB;AACpB;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,YAAY;IACZ,aAAa;IACb,iCAAiC,EAAE,qCAAqC;IACxE,0BAA0B,EAAE,gBAAgB;IAC5C,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;EACf","sourcesContent":["/* Loading Overlay */\n.loading-overlay {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    height: 100vh;\n    background: rgba(17, 17, 17, 0.6); /* Semi-transparent dark background */\n    backdrop-filter: blur(8px); /* Blur effect */\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    z-index: 1000;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
