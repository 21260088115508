// src/components/Sidebar/Sidebar.js

import React from 'react';
import { NavLink } from 'react-router-dom';
import './Sidebar.css';
import { ReactComponent as Logo } from './LogoIcon/Logo.svg';

const Sidebar = () => {
  return (
    <nav className="sidebar">
      <div className="sidebar-main-content">
        <div className="sidebar-logo">
          <Logo className="main-logo" />
        </div>

        <ul className="sidebar-nav">
          <li>
            <NavLink
              to="wallet"
              end
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              <img
                src="/assets/media/images/icons/new/wallet-icon.svg"
                alt="Wallet Icon"
                className="sidebar-menu__list-icon"
              />
              <span>My Wallet</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="swap"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              <img
                src="/assets/media/images/icons/new/refresh-icon.svg"
                alt="Swap Icon"
                className="sidebar-menu__list-icon"
              />
              <span>Swap</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="transactions"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              <img
                src="/assets/media/images/icons/new/history-icon.svg"
                alt="Transactions Icon"
                className="sidebar-menu__list-icon"
              />
              <span>History</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="buy-sell"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              <img
                src="/assets/media/images/icons/new/area-chart-icon.svg"
                alt="Buy/Sell Icon"
                className="sidebar-menu__list-icon"
              />
              <span>Market</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="vote"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              <img
                src="/assets/media/images/icons/new/suggestion-box-icon.svg"
                alt="Vote Icon"
                className="sidebar-menu__list-icon"
              />
              <span>Vote</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="trading-bot"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              <img
                src="/assets/media/images/icons/new/microchip-icon.svg"
                alt="Trading Bot Icon"
                className="sidebar-menu__list-icon"
              />
              <span>Trading Bot</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="token"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              <img
                src="/assets/media/images/icons/new/money-icon.svg"
                alt="Token Icon"
                className="sidebar-menu__list-icon"
              />
              <span>Token</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="presale"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              <img
                src="/assets/media/images/icons/new/rocket-launch-line-icon.svg"
                alt="Presale Icon"
                className="sidebar-menu__list-icon"
              />
              <span>Presale</span>
            </NavLink>
          </li>
          <li>
            <NavLink
              to="liquidity"
              className={({ isActive }) => (isActive ? 'active-link' : '')}
            >
              <img
                src="/assets/media/images/icons/new/pie-chart-icon.svg"
                alt="Liquidity Icon"
                className="sidebar-menu__list-icon"
              />
              <span>Liquidity</span>
            </NavLink>
          </li>
        </ul>
      </div>

      <ul className="sidebar-footer">
        <li>
          <NavLink
            to="settings"
            className={({ isActive }) => (isActive ? 'active-link' : '')}
          >
            <img
              src="/assets/media/images/icons/new/setting-line-icon.svg"
              alt="Settings Icon"
              className="sidebar-menu__list-icon"
            />
            <span>Settings</span>
          </NavLink>
        </li>
        <li>
          <NavLink
            to="feedback-support"
            className={({ isActive }) => (isActive ? 'active-link' : '')}
          >
            <img
              src="/assets/media/images/icons/new/how-to-icon.svg"
              alt="Support Icon"
              className="sidebar-menu__list-icon"
            />
            <span>Feedback & Support</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Sidebar;
