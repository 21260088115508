// src/components/Dashboard/Token/steps/Step2TokenType.js
import React, { useContext, useState } from 'react';
import { TokenContext } from '../TokenContext';


const Step2TokenType = ({ nextStep, prevStep }) => {
  const { tokenData, updateTokenData } = useContext(TokenContext);
  const [tokenType, setTokenType] = useState(tokenData.tokenType);
  const [taxRate, setTaxRate] = useState(tokenData.taxRate || '');

  const handleNext = () => {
    if (tokenType === 'Tax' && (!taxRate || taxRate < 0)) {
      alert('Please enter a valid tax rate.');
      return;
    }
    updateTokenData({ tokenType, taxRate });
    nextStep();
  };

  return (
    <div>
      <h2>Select Token Type</h2>
      <div className="token_type-selection">
        <div className="token_type-option">
          <input
            className="token_type-radio"
            type="radio"
            id="baseToken"
            value="Base"
            checked={tokenType === 'Base'}
            onChange={(e) => setTokenType(e.target.value)}
          />
          <label className="token_type-label" htmlFor="baseToken">
            <i className="fas fa-coins token_type-icon"></i>
            <span className="token_type-title">Base Token</span>
            <span className="token_type-description">
              Standard ERC20 token with basic transfer functionality.
              Perfect for simple use cases.
            </span>
          </label>
        </div>

        <div className="token_type-option">
          <input
            className="token_type-radio"
            type="radio"
            id="taxToken"
            value="Tax"
            checked={tokenType === 'Tax'}
            onChange={(e) => setTokenType(e.target.value)}
          />
          <label className="token_type-label" htmlFor="taxToken">
            <i className="fas fa-percentage token_type-icon"></i>
            <span className="token_type-title">Tax Token</span>
            <span className="token_type-description">
              Advanced token with transaction fee mechanism.
              Includes automatic fee distribution.
            </span>
          </label>
        </div>
      </div>

      {tokenType === 'Tax' && (
        <div className="token_form">
          <div className="token_form-group">
            <label className="token_label">Tax Rate (%):</label>
            <input 
              className="token_input"
              type="number" 
              value={taxRate} 
              onChange={(e) => setTaxRate(e.target.value)} 
              placeholder="Enter tax percentage (e.g. 5)"
              required 
              min="0"
              max="100"
            />
          </div>
        </div>
      )}

      <div className="token_buttons">
        <button 
          className="token_button token_button-back" 
          type="button" 
          onClick={prevStep}
        >
          Back
        </button>
        <button 
          className="token_button token_button-next" 
          type="button" 
          onClick={handleNext}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step2TokenType;
