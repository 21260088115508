// src/utils/networkHelpers.js

/**
 * Extracts the necessary fields from the network object for MetaMask's wallet_addEthereumChain method.
 * @param {Object} network - The complete network object.
 * @returns {Object} - A new object containing only the required fields for MetaMask.
 */
export const getMetaMaskChainParams = (network) => ({
    chainId: network.chainId,
    chainName: network.chainName,
    nativeCurrency: network.nativeCurrency,
    rpcUrls: network.rpcUrls,
    blockExplorerUrls: network.blockExplorerUrls,
  });
  