// src/components/Dashboard/DashboardHome/DashboardHome.js
import React from 'react';
import MainContent from '../Wallet/Wallet';

const DashboardHome = () => {
  return (
    <MainContent />
  );
};

export default DashboardHome;
