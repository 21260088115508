import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid
} from 'recharts';
import { TrendingUp, TrendingDown } from 'lucide-react';

const TransactionStats = ({ transactions, user, onBarClick }) => {
  // Process data for the chart
  const chartData = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const monthlyData = new Array(12).fill(0).map((_, index) => ({
      month: new Date(currentYear, index).toLocaleString('en-US', { month: 'short' }).toUpperCase(),
      value: 0,
      monthIndex: index,
    }));

    transactions.forEach(tx => {
      const date = new Date(tx.timeStamp * 1000);
      const monthIndex = date.getMonth();
      monthlyData[monthIndex].value++;
    });

    // Return only last 6 months
    return monthlyData.slice(-6);
  }, [transactions]);

  // Calculate statistics
  const stats = useMemo(() => {
    const totalTransactions = transactions.length;
    const incomingTx = transactions.filter(tx => 
      tx.to?.toLowerCase() === user?.address.toLowerCase()
    ).length;
    const outgoingTx = totalTransactions - incomingTx;

    // Calculate changes from previous period
    const monthlyTotals = chartData.map(data => data.value);
    const currentMonth = monthlyTotals[monthlyTotals.length - 1] || 0;
    const previousMonth = monthlyTotals[monthlyTotals.length - 2] || 0;
    
    const totalChange = previousMonth === 0 
      ? 0 
      : ((currentMonth - previousMonth) / previousMonth) * 100;

    // Calculate incoming and outgoing changes
    const previousMonthTransactions = transactions.filter(tx => {
      const txDate = new Date(tx.timeStamp * 1000);
      const now = new Date();
      const previousMonth = new Date(now.getFullYear(), now.getMonth() - 1);
      return txDate >= previousMonth && txDate < now;
    });

    const previousIncoming = previousMonthTransactions.filter(tx =>
      tx.to?.toLowerCase() === user?.address.toLowerCase()
    ).length;

    const previousOutgoing = previousMonthTransactions.length - previousIncoming;

    const incomingChange = previousIncoming === 0
      ? 0
      : ((incomingTx - previousIncoming) / previousIncoming) * 100;

    const outgoingChange = previousOutgoing === 0
      ? 0
      : ((outgoingTx - previousOutgoing) / previousOutgoing) * 100;
    
    return [
      {
        title: "TOTAL TRANSACTIONS",
        value: totalTransactions,
        change: `${totalChange.toFixed(1)}%`,
        isPositive: totalChange >= 0
      },
      {
        title: "INCOMING",
        value: incomingTx,
        change: `${incomingChange.toFixed(1)}%`,
        isPositive: incomingChange >= 0
      },
      {
        title: "OUTGOING",
        value: outgoingTx,
        change: `${outgoingChange.toFixed(1)}%`,
        isPositive: outgoingChange >= 0
      }
    ];
  }, [transactions, user?.address, chartData]);

  if (!user?.address) {
    return (
      <div className="transaction-stats">
        <p>No user address available.</p>
      </div>
    );
  }

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      return (
        <div style={{
          background: 'rgba(17, 17, 17, 0.9)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          borderRadius: '8px',
          padding: '8px 12px',
          color: '#FFFFFF',
          fontSize: '0.875rem'
        }}>
          <p style={{ margin: 0 }}>{`${payload[0].payload.month}: ${payload[0].value} transactions`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="transaction-stats">
      <div className="transaction-content">
        <div className="stats-container">
          {stats.map((stat, index) => (
            <div key={index} className="stat-card">
              <span className="stat-title">{stat.title}</span>
              <span className="stat-value">{stat.value}</span>
              <span className={`stat-change ${stat.isPositive ? 'positive' : 'negative'}`}>
                {stat.isPositive ? <TrendingUp size={14} /> : <TrendingDown size={14} />}
                {stat.change}
              </span>
            </div>
          ))}
        </div>
        
        <div className="chart-container">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              data={chartData}
              margin={{ top: 10, right: 10, left: 10, bottom: 5 }}
              onClick={(data) => {
                if (data && data.activePayload) {
                  onBarClick?.(
                    data.activePayload[0].payload.month,
                    data.activePayload[0].payload.monthIndex
                  );
                }
              }}
            >
              <CartesianGrid 
                strokeDasharray="3 3" 
                vertical={false} 
                stroke="rgba(255, 255, 255, 0.1)"
              />
              <XAxis
                dataKey="month"
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#94A3B8', fontSize: 12 }}
              />
              <YAxis
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#94A3B8', fontSize: 12 }}
              />
              <Tooltip 
                content={<CustomTooltip />}
                cursor={{ fill: 'rgba(255, 255, 255, 0.05)' }}
              />
              <Bar
                dataKey="value"
                fill="#1e4bca"
                radius={[4, 4, 0, 0]}
                maxBarSize={40}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

TransactionStats.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.shape({
    hash: PropTypes.string.isRequired,
    timeStamp: PropTypes.string.isRequired,
    from: PropTypes.string.isRequired,
    to: PropTypes.string,
    value: PropTypes.string.isRequired,
    isError: PropTypes.string.isRequired,
  })).isRequired,
  user: PropTypes.shape({
    address: PropTypes.string.isRequired,
  }).isRequired,
  onBarClick: PropTypes.func,
};

export default TransactionStats;
