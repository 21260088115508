import React, { useState, useEffect, useMemo, useContext } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  FaClipboard,
  FaExternalLinkAlt,
  FaArrowUp,
  FaArrowDown,
} from 'react-icons/fa';
import { BrowserProvider, JsonRpcProvider, getDefaultProvider } from 'ethers';
import './Transactions.css';
import TransactionStats from './TransactionStats';
import { getTokenPrice } from '../../../utils/getTokenPrice';
import LoadingSpinner from '../../../components/Loading/Loading';

const Transactions = () => {
  const { auth } = useContext(AuthContext);

  const [transactions, setTransactions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [ethPrice, setEthPrice] = useState(0);
  const [ensNames, setEnsNames] = useState({});
  const transactionsPerPage = 10;

  // Filter states
  const [selectedDateRange, setSelectedDateRange] = useState('All');
  const [selectedType, setSelectedType] = useState('All');
  const [filterFrom, setFilterFrom] = useState('');
  const [filterTo, setFilterTo] = useState('');
  const [filterTxHash, setFilterTxHash] = useState('');
  const [sortOrder, setSortOrder] = useState('desc');

  // Copy to clipboard handler
  const handleCopy = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      toast.success('Address copied to clipboard!');
    } catch (err) {
      console.error('Error copying:', err);
      toast.error('Failed to copy address.');
    }
  };

  // Fetch ETH price
  useEffect(() => {
    const fetchEthPrice = async () => {
      try {
        const priceData = await getTokenPrice('ETH');
        setEthPrice(priceData.usd);
      } catch (error) {
        console.error('Error fetching ETH price:', error);
        toast.error('Failed to fetch ETH price.');
      }
    };

    fetchEthPrice();
    const interval = setInterval(fetchEthPrice, 60000);
    return () => clearInterval(interval);
  }, []);

  // Fetch transactions
  useEffect(() => {
    const fetchTransactions = async () => {
      if (!auth?.user?.address || !auth.chainId) {
        setIsLoading(false);
        return;
      }

      try {
        setIsLoading(true);
        const chainId = auth.chainId;
        let apiUrl;
        let apiKey;

        switch (chainId) {
          case 1:
            apiUrl = 'https://api.etherscan.io/api';
            apiKey = process.env.REACT_APP_ETHERSCAN_API_KEY;
            break;
          case 42161:
            apiUrl = 'https://api.arbiscan.io/api';
            apiKey = process.env.REACT_APP_ARBISCAN_API_KEY;
            break;
          case 8453:
            apiUrl = 'https://api.basescan.org/api';
            apiKey = process.env.REACT_APP_BASESCAN_API_KEY;
            break;
          default:
            apiUrl = 'https://api.etherscan.io/api';
            apiKey = process.env.REACT_APP_ETHERSCAN_API_KEY;
            break;
        }

        const response = await axios.get(apiUrl, {
          params: {
            module: 'account',
            action: 'txlist',
            address: auth.user.address,
            startblock: 0,
            endblock: 99999999,
            page: 1,
            offset: 100,
            sort: 'desc',
            apikey: apiKey || 'YOUR_API_KEY',
          },
        });

        if (response.data.status === '1') {
          setTransactions(response.data.result);
        } else {
          console.error('Error fetching transactions:', response.data.message);
          toast.error('Failed to fetch transactions.');
        }
      } catch (error) {
        console.error('Error loading transactions:', error);
        toast.error('Error loading transactions.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchTransactions();
    const interval = setInterval(fetchTransactions, 30000);
    return () => clearInterval(interval);
  }, [auth?.user?.address, auth.chainId]);

  // Resolve ENS names
  useEffect(() => {
    const resolveEnsNames = async () => {
      if (!auth?.user?.address || transactions.length === 0) return;

      const uniqueAddresses = new Set();
      transactions.forEach((tx) => {
        uniqueAddresses.add(tx.from.toLowerCase());
        if (tx.to) uniqueAddresses.add(tx.to.toLowerCase());
      });

      const addressesToResolve = Array.from(uniqueAddresses).filter(
        (address) => !(address in ensNames)
      );

      if (addressesToResolve.length === 0) return;

      try {
        let provider;
        if (auth.wallet?.provider) {
          provider = auth.wallet.provider;
        } else {
          provider = getDefaultProvider();
        }

        const resolvePromises = addressesToResolve.map((address) =>
          provider.lookupAddress(address)
        );
        const results = await Promise.all(resolvePromises);

        const newEnsNames = {};
        addressesToResolve.forEach((address, index) => {
          if (results[index]) {
            newEnsNames[address] = results[index];
          }
        });

        setEnsNames((prev) => ({ ...prev, ...newEnsNames }));
      } catch (error) {
        console.error('Error resolving ENS:', error);
      }
    };

    resolveEnsNames();
  }, [transactions, auth?.wallet?.provider, auth?.user?.address, ensNames]);

  // Filter transactions
  const filteredTransactions = useMemo(() => {
    return transactions
      .filter((tx) => {
        const txDate = new Date(tx.timeStamp * 1000);
        const now = new Date();
        let dateMatch = true;

        if (selectedDateRange === 'Last 7 Days') {
          const sevenDaysAgo = new Date(now - 7 * 24 * 60 * 60 * 1000);
          dateMatch = txDate >= sevenDaysAgo;
        } else if (selectedDateRange === 'Last 30 Days') {
          const thirtyDaysAgo = new Date(now - 30 * 24 * 60 * 60 * 1000);
          dateMatch = txDate >= thirtyDaysAgo;
        } else if (typeof selectedDateRange === 'object') {
          dateMatch =
            txDate >= selectedDateRange.start && txDate <= selectedDateRange.end;
        }

        let typeMatch = true;
        const isIncoming = tx.to?.toLowerCase() === auth.user.address.toLowerCase();
        if (selectedType === 'Sent') {
          typeMatch = !isIncoming;
        } else if (selectedType === 'Received') {
          typeMatch = isIncoming;
        }

        const fromMatch =
          !filterFrom || tx.from.toLowerCase().includes(filterFrom.toLowerCase());
        const toMatch =
          !filterTo || (tx.to && tx.to.toLowerCase().includes(filterTo.toLowerCase()));
        const hashMatch =
          !filterTxHash || tx.hash.toLowerCase().includes(filterTxHash.toLowerCase());

        return dateMatch && typeMatch && fromMatch && toMatch && hashMatch;
      })
      .sort((a, b) => {
        const amountA = parseFloat(a.value) / 1e18;
        const amountB = parseFloat(b.value) / 1e18;
        return sortOrder === 'asc' ? amountA - amountB : amountB - amountA;
      });
  }, [
    transactions,
    selectedDateRange,
    selectedType,
    filterFrom,
    filterTo,
    filterTxHash,
    sortOrder,
    auth?.user?.address,
  ]);

  // Pagination calculations
  const indexOfLastTx = currentPage * transactionsPerPage;
  const indexOfFirstTx = indexOfLastTx - transactionsPerPage;
  const currentTransactions = filteredTransactions.slice(
    indexOfFirstTx,
    indexOfLastTx
  );
  const totalPages = Math.ceil(filteredTransactions.length / transactionsPerPage);

  // Pagination handlers
  const handlePrevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 1));
  const handleNextPage = () => setCurrentPage((prev) => Math.min(prev + 1, totalPages));

  // Get display name for addresses
  const getDisplayName = (address) => {
    const lowerAddress = address.toLowerCase();
    return ensNames[lowerAddress] || `${address.substring(0, 6)}...${address.slice(-4)}`;
  };

  // Handle bar click in chart
  const handleBarClick = (month, monthIndex) => {
    const now = new Date();
    const selectedMonth = new Date(now.getFullYear(), monthIndex, 1);
    const startOfMonth = new Date(
      selectedMonth.getFullYear(),
      selectedMonth.getMonth(),
      1
    );
    const endOfMonth = new Date(
      selectedMonth.getFullYear(),
      selectedMonth.getMonth() + 1,
      0
    );

    setSelectedDateRange({
      start: startOfMonth,
      end: endOfMonth,
    });
    setCurrentPage(1);
  };

  if (auth.loading || isLoading) {
    return (
      <div className="transactions-container">
        <LoadingSpinner />
      </div>
    );
  }

  if (!auth?.user) {
    return (
      <div className="transactions-container">
        <p>Please log in to view transactions.</p>
      </div>
    );
  }

  return (
    <div className="transactions-container">
      {/* Transaction Activity Section */}
      <div className="section-card">
        <div className="card-header">
          <h2>Transaction Activity</h2>
        </div>
        <TransactionStats
          transactions={transactions}
          user={auth.user}
          onBarClick={handleBarClick}
        />
      </div>

      {/* Recent Transactions Section */}
      <div className="section-card">
        <div className="card-header">
          <h2>Recent Transactions</h2>
        </div>

        {/* Filters */}
        <div className="filters-container">
          <div className="filter-group">
            <label htmlFor="date-filter">Date:</label>
            <select
              id="date-filter"
              value={
                selectedDateRange === 'All' ||
                selectedDateRange === 'Last 7 Days' ||
                selectedDateRange === 'Last 30 Days'
                  ? selectedDateRange
                  : 'All'
              }
              onChange={(e) => {
                setSelectedDateRange(e.target.value);
                setCurrentPage(1);
              }}
              className="filter-select"
            >
              <option value="All">All</option>
              <option value="Last 7 Days">Last 7 Days</option>
              <option value="Last 30 Days">Last 30 Days</option>
            </select>
          </div>

          <div className="filter-group">
            <label htmlFor="type-filter">Type:</label>
            <select
              id="type-filter"
              value={selectedType}
              onChange={(e) => {
                setSelectedType(e.target.value);
                setCurrentPage(1);
              }}
              className="filter-select"
            >
              <option value="All">All</option>
              <option value="Sent">Sent</option>
              <option value="Received">Received</option>
            </select>
          </div>

          <div className="filter-group">
            <label htmlFor="from-filter">From:</label>
            <input
              type="text"
              id="from-filter"
              value={filterFrom}
              onChange={(e) => {
                setFilterFrom(e.target.value);
                setCurrentPage(1);
              }}
              placeholder="Search From Address"
              className="filter-input"
            />
          </div>

          <div className="filter-group">
            <label htmlFor="to-filter">To:</label>
            <input
              type="text"
              id="to-filter"
              value={filterTo}
              onChange={(e) => {
                setFilterTo(e.target.value);
                setCurrentPage(1);
              }}
              placeholder="Search To Address"
              className="filter-input"
            />
          </div>

          <div className="filter-group">
            <label htmlFor="txhash-filter">Transaction Hash:</label>
            <input
              type="text"
              id="txhash-filter"
              value={filterTxHash}
              onChange={(e) => {
                setFilterTxHash(e.target.value);
                setCurrentPage(1);
              }}
              placeholder="Search Transaction Hash"
              className="filter-input"
            />
          </div>

          <div className="filter-group">
            <label htmlFor="sort-order">Sort by Amount:</label>
            <select
              id="sort-order"
              value={sortOrder}
              onChange={(e) => {
                setSortOrder(e.target.value);
                setCurrentPage(1);
              }}
              className="filter-select"
            >
              <option value="desc">Highest to Lowest</option>
              <option value="asc">Lowest to Highest</option>
            </select>
          </div>
        </div>

        {/* Transactions Table */}
        {filteredTransactions.length > 0 ? (
          <>
            <div className="table-responsive">
              <table className="transactions-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Type</th>
                    <th>Date</th>
                    <th>Status</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTransactions.map((tx) => {
                    const isIncoming =
                      tx.to?.toLowerCase() === auth.user.address.toLowerCase();
                    const typeIcon = isIncoming ? (
                      <FaArrowDown className="icon incoming" />
                    ) : (
                      <FaArrowUp className="icon outgoing" />
                    );

                    let status = 'Success';
                    let statusClass = 'status-success';

                    if (tx.blockNumber === '0') {
                      status = 'Pending';
                      statusClass = 'status-pending';
                    } else if (tx.isError === '1') {
                      status = 'Failed';
                      statusClass = 'status-failed';
                    }

                    const amountEth = parseFloat(tx.value) / 1e18;
                    const amountUsd = (amountEth * ethPrice).toFixed(2);

                    return (
                      <tr key={tx.hash}>
                        <td>
                          <div className="tx-id-container">
                            <a
                              href={`https://etherscan.io/tx/${tx.hash}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="tx-link"
                            >
                              {tx.hash.substring(0, 6)}...{tx.hash.slice(-4)}
                            </a>
                            <FaExternalLinkAlt className="external-link-icon" />
                          </div>
                        </td>
                        <td className="type-cell">{typeIcon}</td>
                        <td>
                          {new Date(tx.timeStamp * 1000).toLocaleString('en-US')}
                        </td>
                        <td>
                          <span className={statusClass}>{status}</span>
                        </td>
                        <td>
                          <div className="address-container">
                            <span>{getDisplayName(tx.from)}</span>
                            <FaClipboard
                              className="clipboard-icon"
                              onClick={() => handleCopy(tx.from)}
                              title="Copy Address"
                            />
                          </div>
                        </td>
                        <td>
                          {tx.to ? (
                            <div className="address-container">
                              <span>{getDisplayName(tx.to)}</span>
                              <FaClipboard
                                className="clipboard-icon"
                                onClick={() => handleCopy(tx.to)}
                                title="Copy Address"
                              />
                            </div>
                          ) : (
                            'Contract Creation'
                          )}
                        </td>
                        <td>
                          <div className="amount-container">
                            <span className="amount-eth">
                              {amountEth.toFixed(6)} ETH
                            </span>
                            <span className="amount-usd">${amountUsd}</span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <div className="pagination">
              <button
                onClick={handlePrevPage}
                disabled={currentPage === 1}
                className="pagination-button"
              >
                Previous
              </button>
              <span className="pagination-info">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
                className="pagination-button"
              >
                Next
              </button>
            </div>
          </>
        ) : (
          <p>No transactions found.</p>
        )}
      </div>
    </div>
  );
};

export default Transactions;
