// src/components/Dashboard/Topbar/NetworkSelector.js

import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from '@mui/material';
import { FaNetworkWired } from 'react-icons/fa';
import { networks } from '../../../utils/networks';
import { getMetaMaskChainParams } from '../../../utils/networkHelpers';
import { toast } from 'react-toastify';

const NetworkSelector = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentNetwork, setCurrentNetwork] = useState(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    const fetchNetwork = async () => {
      if (window.ethereum) {
        try {
          const chainId = await window.ethereum.request({ method: 'eth_chainId' });
          const network = networks.find(
            (n) => n.chainId.toLowerCase() === chainId.toLowerCase()
          );
          setCurrentNetwork(network || {
            name: 'Unknown Network',
            chainId,
            chainName: 'Unknown Network',
            nativeCurrency: {
              name: '',
              symbol: '',
              decimals: 18,
            },
            rpcUrls: [],
            blockExplorerUrls: [],
            logo: '',
          });
        } catch (error) {
          console.error('Error fetching chain ID:', error);
          toast.error('Failed to load the current network.');
        }
      } else {
        toast.error('Ethereum provider not found. Please install MetaMask.');
      }
    };

    const handleChainChanged = (chainId) => {
      const network = networks.find(
        (n) => n.chainId.toLowerCase() === chainId.toLowerCase()
      );
      setCurrentNetwork(network || {
        name: 'Unknown Network',
        chainId,
        chainName: 'Unknown Network',
        nativeCurrency: {
          name: '',
          symbol: '',
          decimals: 18,
        },
        rpcUrls: [],
        blockExplorerUrls: [],
        logo: '',
      });
      toast.success(`Switched to ${network ? network.name : 'Unknown Network'}`);
    };

    fetchNetwork();

    if (window.ethereum && typeof window.ethereum.on === 'function') {
      window.ethereum.on('chainChanged', handleChainChanged);
    }

    return () => {
      if (window.ethereum?.removeListener) {
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const switchNetwork = async (network) => {
    if (!window.ethereum) {
      toast.error('Ethereum provider is not available.');
      return;
    }

    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: network.chainId }],
      });
      toast.success(`Switched to ${network.chainName}`);
    } catch (switchError) {
      if (switchError.code === 4902 || switchError.code === -32603) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [getMetaMaskChainParams(network)],
          });
          toast.success(`${network.chainName} has been added and switched.`);
        } catch (addError) {
          if (addError.code === 4001) {
            toast.warn('Network addition was rejected.');
          } else {
            toast.error('Failed to add the network.');
          }
        }
      } else if (switchError.code === 4001) {
        toast.warn('Network switch request was rejected.');
      } else {
        toast.error('Failed to switch network.');
      }
    }
  };

  return (
    <>
      <Tooltip title="Select Network">
        <IconButton onClick={handleClick} color="inherit">
          <FaNetworkWired />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {networks.map((network) => (
          <MenuItem
            key={network.chainId}
            onClick={() => {
              switchNetwork(network);
              handleClose();
            }}
          >
            <ListItemIcon>
              {network.logo ? (
                <img
                  src={network.logo}
                  alt={network.symbol}
                  width="24"
                  height="24"
                  style={{ borderRadius: '50%' }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/assets/default-network.png';
                  }}
                />
              ) : (
                <FaNetworkWired />
              )}
            </ListItemIcon>
            <ListItemText primary={network.chainName} />
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default NetworkSelector;
