// src/App.js
import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import './App.css';
import './AppKitConfig';
import SignIn from './components/SignIn';
import Dashboard from './components/Dashboard/Dashboard';
import BackupMnemonic from './components/BackupMnemonic';
import VerifyMnemonic from './components/VerifyMnemonic';
import { ToastContainer } from 'react-toastify';
import useAuth from './hooks/useAuth';
import AOS from 'aos';
import 'aos/dist/aos.css';
import ProfileSetupModal from './components/ProfileSetupModal';

function App() {
  const { auth } = useAuth();
  const [isProfileModalOpen, setProfileModalOpen] = useState(false);

  useEffect(() => {
    AOS.init({
      duration: 1000,
    });
  }, []);

  // Check if the authenticated user has a profile
  useEffect(() => {
    if (auth.isAuthenticated && !auth.profile) {
      setProfileModalOpen(true);
    } else {
      setProfileModalOpen(false);
    }
  }, [auth.isAuthenticated, auth.profile]);

  return (
    <div className={`App ${isProfileModalOpen ? 'blur-background' : ''}`}>
      <ToastContainer position="top-right" autoClose={5000} />
      <Routes>
        <Route
          path="/"
          element={
            !auth.isAuthenticated ? <SignIn /> : <Navigate to="/dashboard" />
          }
        />
        <Route
          path="/dashboard/*"
          element={
            auth.isAuthenticated ? <Dashboard /> : <Navigate to="/" />
          }
        />
        <Route
          path="/backup-mnemonic"
          element={
            !auth.isAuthenticated ? (
              <BackupMnemonic />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route
          path="/verify-mnemonic"
          element={
            !auth.isAuthenticated ? (
              <VerifyMnemonic />
            ) : (
              <Navigate to="/dashboard" />
            )
          }
        />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
      {/* Profile Setup Modal */}
      {auth.isAuthenticated && !auth.profile && (
        <ProfileSetupModal
          open={isProfileModalOpen}
          onClose={() => setProfileModalOpen(false)}
        />
      )}
    </div>
  );
}

export default App;
