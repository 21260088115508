// src/contexts/NotificationsContext.js

import React, { createContext, useState } from 'react';

export const NotificationsContext = createContext();

export const NotificationsProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);

  const addNotification = (notification) => {
    const newNotification = {
      id: Date.now(),
      timestamp: new Date().toISOString(),
      ...notification, // Spread the notification object to include all properties
    };
    setNotifications((prev) => [newNotification, ...prev.slice(0, 4)]); // Keep last 5
    setUnreadCount((prev) => prev + 1);
  };

  const markAllAsRead = () => {
    setUnreadCount(0);
  };

  return (
    <NotificationsContext.Provider
      value={{ notifications, unreadCount, addNotification, markAllAsRead }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};
