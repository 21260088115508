// src/components/BackupMnemonic.js
import React from 'react';
import useAuth from '../hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Mnemonic.css';
import { FaClipboard, FaInfoCircle } from 'react-icons/fa';
import { Tooltip, IconButton } from '@mui/material'; // Import Tooltip and IconButton from Material-UI

const BackupMnemonic = () => {
  const { temporaryMnemonic } = useAuth();
  const navigate = useNavigate();

  if (!temporaryMnemonic) {
    navigate('/');
    return null;
  }

  const mnemonicWords = temporaryMnemonic.split(' ');

  const handleProceed = () => {
    navigate('/verify-mnemonic');
  };

  const handleBack = () => {
    navigate('/sign-in');
  };

  const handleCopy = () => {
    const mnemonicString = mnemonicWords.join(' ');
    navigator.clipboard
      .writeText(mnemonicString)
      .then(() => {
        toast.success('Mnemonic words copied to clipboard!');
      })
      .catch((err) => {
        console.error('Failed to copy mnemonic words:', err);
        toast.error('Failed to copy mnemonic words. Please try manually.');
      });
  };

  return (
    <div className="sign-in sign-in--v2">
      <div className="sign-in-card">
        <div className="backup-mnemonic-content">
          <h2 className="mnemonic-title">Secure Your Mnemonic</h2>
          <p className="mnemonic-instructions">
            Save the following 12 words in a secure location. This phrase is required to restore your wallet if you lose access.
            {/* Tooltip with Information Icon */}
            <Tooltip
              title={
                <React.Fragment>
                  <strong>What is a Mnemonic Phrase?</strong>
                  <br />
                  A mnemonic phrase (also known as a seed phrase) is a series of words generated by your wallet that allows you to access your cryptocurrencies. It is crucial to keep this phrase secure and private.
                  <br />
                  <strong>Best Practices:</strong>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li><strong>Write it down:</strong> Physically write down your mnemonic phrase and store it in a safe place.</li>
                    <li><strong>Do not share:</strong> Never share your mnemonic phrase with anyone or enter it on any websites.</li>
                    <li><strong>Backup:</strong> Keep multiple backups in different secure locations to prevent loss.</li>
                    <li><strong>Do not store digitally:</strong> Avoid saving your mnemonic phrase in digital formats like text files, screenshots, or cloud storage to reduce the risk of hacking.</li>
                  </ul>
                  If someone gains access to your mnemonic phrase, they can control your wallet and all associated funds.
                </React.Fragment>
              }
              placement="right"
              arrow
              interactive
            >
              <IconButton
                aria-label="learn more about mnemonic phrases"
                style={{ marginLeft: '10px', color: '#007bff' }}
              >
                <FaInfoCircle />
              </IconButton>
            </Tooltip>
          </p>

          {/* Copy Button Positioned Prominently */}
          <div className="copy-button-container">
            <button onClick={handleCopy} className="btn btn-outline-secondary copy-button">
              <FaClipboard style={{ marginRight: '5px' }} /> Copy
            </button>
          </div>

          <div className="mnemonic-words spaced-words">
            {mnemonicWords.map((word, index) => (
              <div key={index} className="mnemonic-word">
                <div className="form-control form-control-sm mini-form__input">
                  <span className="word-index">{index + 1}.</span> {word}
                </div>
              </div>
            ))}
          </div>
          <div className="mnemonic-buttons spaced-buttons">
            <button onClick={handleBack} className="btn btn-secondary advance-card__button btn-sm">
              Back
            </button>
            <button onClick={handleProceed} className="btn btn-primary advance-card__button btn-sm btn-pill">
              Proceed
            </button>
          </div>
        </div>
      </div>
      <div className="sign-in-banner spaced-banner">
        <div className="sign-in-banner__content">
          <img
            className="sign-in-banner__illustration"
            src="/assets/media/images/sign-in/backup-illustration.png"
            alt="Backup Illustration"
            data-aos="zoom-in-up"
            data-aos-delay="50"
          />
        </div>
      </div>
      {/* Toast Container for Notifications */}
      <ToastContainer position="top-right" autoClose={3000} hideProgressBar />
    </div>
  );
};

export default BackupMnemonic;
