// src/components/Dashboard/Wallet/TokenImportModal.js

import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Typography,
  Box,
  Alert,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  Divider,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon, Warning as WarningIcon } from '@mui/icons-material';
import { Contract, isAddress } from 'ethers';

// ERC20 ABI pro základní interakce
const ERC20_ABI = [
  'function name() view returns (string)',
  'function symbol() view returns (string)',
  'function decimals() view returns (uint8)',
  'function balanceOf(address) view returns (uint256)',
  // Přidání podpory pro bytes32 varianty
  'function name() view returns (bytes32)',
  'function symbol() view returns (bytes32)',
];

const TokenImportModal = ({ open, onClose, onImport, provider }) => {
  const [address, setAddress] = useState('');
  const [loading, setLoading] = useState(false);
  const [tokenInfo, setTokenInfo] = useState(null);
  const [error, setError] = useState('');
  const [acknowledged, setAcknowledged] = useState(false);
  const [tokenIcon, setTokenIcon] = useState(null);

  const resetState = () => {
    setAddress('');
    setTokenInfo(null);
    setError('');
    setAcknowledged(false);
    setTokenIcon(null);
  };

  // Pomocná funkce pro převod bytes32 na string
  const bytes32ToString = (bytes32) => {
    const hex = bytes32.slice(2);
    let str = '';
    for (let i = 0; i < hex.length; i += 2) {
      const code = parseInt(hex.slice(i, i + 2), 16);
      if (code === 0) break; // Zastavení při prvním null znaku
      str += String.fromCharCode(code);
    }
    return str;
  };

  const getTokenData = async (tokenContract) => {
    let name, symbol, decimals;

    // Získání decimals
    try {
      decimals = await tokenContract.decimals();
    } catch {
      decimals = 18; // Výchozí hodnota
    }

    // Získání name
    try {
      name = await tokenContract.name();
      if (name.startsWith('0x')) {
        name = bytes32ToString(name);
      }
    } catch {
      try {
        const nameData = await provider.call({
          to: address,
          data: '0x06fdde03', // Signatura funkce pro name()
        });
        name = bytes32ToString(nameData);
      } catch {
        name = 'Unknown Token';
      }
    }

    // Získání symbol
    try {
      symbol = await tokenContract.symbol();
      if (symbol.startsWith('0x')) {
        symbol = bytes32ToString(symbol);
      }
    } catch {
      try {
        const symbolData = await provider.call({
          to: address,
          data: '0x95d89b41', // Signatura funkce pro symbol()
        });
        symbol = bytes32ToString(symbolData);
      } catch {
        symbol = 'UNKNOWN';
      }
    }

    return { name, symbol, decimals: decimals.toString() };
  };

  const validateAndGetTokenInfo = async () => {
    if (!address) {
      setError('Please enter a token address');
      return;
    }

    if (!isAddress(address)) {
      setError('Invalid Ethereum address format');
      return;
    }

    setLoading(true);
    setError('');

    try {
      const tokenContract = new Contract(address, ERC20_ABI, provider);

      // Testování funkce balanceOf pro ověření ERC20
      try {
        await tokenContract.balanceOf(address);
      } catch (error) {
        console.error('balanceOf test failed:', error);
        // Pokračování i v případě selhání balanceOf; některé tokeny mohou mít odlišné implementace
      }

      // Získání dat tokenu
      const tokenData = await getTokenData(tokenContract);

      // Získání ikony z CoinGecko
      let iconUrl = null;
      try {
        const response = await fetch(
          `https://api.coingecko.com/api/v3/coins/ethereum/contract/${address}`
        );
        if (response.ok) {
          const data = await response.json();
          iconUrl = data.image?.small || null;
        }
      } catch (e) {
        console.log('Could not fetch token icon');
      }

      setTokenInfo({
        address,
        ...tokenData,
        balance: '0', // Výchozí hodnota; bude aktualizována později
      });
      setTokenIcon(iconUrl);
    } catch (err) {
      console.error('Token import error:', err);
      setError('Could not import token. Please make sure this is a valid ERC20 token.');
      setTokenInfo(null);
    } finally {
      setLoading(false);
    }
  };

  const handleImport = async () => {
    if (!tokenInfo || !acknowledged) return;
    
    try {
      const finalTokenData = {
        ...tokenInfo,
        logo: tokenIcon,
      };

      await onImport(finalTokenData);
      resetState();
      onClose();
    } catch (error) {
      setError('Failed to import token: ' + error.message);
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={() => {
        resetState();
        onClose();
      }} 
      maxWidth="sm" 
      fullWidth
    >
      <DialogTitle>
        Import Token
        <IconButton
          onClick={() => {
            resetState();
            onClose();
          }}
          sx={{ position: 'absolute', right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      
      <DialogContent>
        <TextField
          label="Token Contract Address"
          fullWidth
          value={address}
          onChange={(e) => {
            setAddress(e.target.value.trim());
            setError('');
          }}
          margin="normal"
          error={!!error}
          helperText={error}
          disabled={loading}
          placeholder="0x..."
        />
        
        {!tokenInfo && (
          <Button
            variant="contained"
            onClick={validateAndGetTokenInfo}
            disabled={loading || !address}
            fullWidth
            sx={{ mt: 2 }}
          >
            {loading ? <CircularProgress size={24} /> : 'Import Token'}
          </Button>
        )}

        {tokenInfo && (
          <Box sx={{ mt: 3 }}>
            <Alert severity="warning" icon={<WarningIcon />} sx={{ mb: 2 }}>
              Make sure you trust this token. Anyone can create a token with any name, including fake versions of existing tokens.
            </Alert>

            <Box sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, mb: 2 }}>
              <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                {tokenIcon ? (
                  <img 
                    src={tokenIcon} 
                    alt={tokenInfo.symbol} 
                    style={{ width: 32, height: 32, marginRight: 12, borderRadius: '50%' }}
                  />
                ) : (
                  <Box 
                    sx={{ 
                      width: 32, 
                      height: 32, 
                      bgcolor: 'grey.200', 
                      borderRadius: '50%', 
                      mr: 1.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 'bold'
                    }} 
                  >
                    {tokenInfo.symbol?.[0]}
                  </Box>
                )}
                <Box>
                  <Typography variant="h6">{tokenInfo.name}</Typography>
                  <Typography color="textSecondary">{tokenInfo.symbol}</Typography>
                </Box>
              </Box>

              <Divider sx={{ my: 2 }} />

              <Typography variant="body2" gutterBottom>
                <strong>Address:</strong> {tokenInfo.address}
              </Typography>
              <Typography variant="body2" gutterBottom>
                <strong>Decimals:</strong> {tokenInfo.decimals}
              </Typography>
            </Box>

            <FormControlLabel
              control={
                <Checkbox 
                  checked={acknowledged} 
                  onChange={(e) => setAcknowledged(e.target.checked)}
                  color="primary"
                />
              }
              label="I understand that this token may be unverified and I am importing it at my own risk"
            />
          </Box>
        )}
      </DialogContent>

      <DialogActions sx={{ p: 2 }}>
        <Button 
          onClick={() => {
            resetState();
            onClose();
          }} 
          color="inherit"
        >
          Cancel
        </Button>
        {tokenInfo && (
          <Button
            variant="contained"
            onClick={handleImport}
            disabled={!acknowledged}
            color="primary"
          >
            Import Token
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TokenImportModal;
