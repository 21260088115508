import React, { useState } from 'react';
import { TrendingUp, Plus, Search, ArrowUpRight, ArrowDownRight, Clock } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import './Presale.css';
import presaleData from './presaleData.json'; // Adjust path if necessary

const Presale = () => {
  const [activeTab, setActiveTab] = useState('live');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Status Badge Component
  const StatusBadge = ({ status }) => {
    const statusStyles = {
      live: {
        background: 'rgba(34, 197, 94, 0.1)',
        color: '#22C55E'
      },
      upcoming: {
        background: 'rgba(59, 130, 246, 0.1)',
        color: '#3B82F6'
      },
      cancelled: {
        background: 'rgba(239, 68, 68, 0.1)',
        color: '#EF4444'
      }
    };

    return (
      <div className="status-badge" style={statusStyles[status]}>
        <div className="status-indicator"></div>
        {status.charAt(0).toUpperCase() + status.slice(1)}
      </div>
    );
  };

  // Trending Section
  const TrendingSection = () => (
    <div className="section-card trending-section">
      <div className="trending-header">
        <div className="section-title">
          <TrendingUp size={20} className="icon-gradient" />
          <h2>Trending</h2>
        </div>
      </div>
      <div className="trending-grid">
        {presaleData.trending.map((token) => (
          <div key={token.id} className="trending-item">
            <span className="rank">#{token.id}</span>
            <span className="symbol">{token.symbol}</span>
          </div>
        ))}
      </div>
    </div>
  );

  // Presale Card Component with navigation on click
  const PresaleCard = ({ presale }) => {
    return (
      <div
        className="presale-card"
        onClick={() => navigate(`/dashboard/presale/${presale.id}`)} // Navigate to PresaleDetail
      >
        <div className="card-image-container">
          <img 
            src={presale.image} 
            alt={presale.name} 
            className="presale-image"
          />
          <StatusBadge status={presale.status} />
          {presale.verified && (
            <div className="verified-badge">
              <svg className="check-icon" viewBox="0 0 24 24">
                <path d="M20 6L9 17l-5-5" />
              </svg>
            </div>
          )}
        </div>

        <div className="card-content">
          <div className="token-details">
            <div className="token-header">
              <h3>{presale.name}</h3>
              <p className="token-symbol">{presale.symbol}</p>
            </div>
            <div className="token-price">
              <span className="label">Price</span>
              <span className="value">${presale.price}</span>
            </div>
          </div>

          <div className="progress-container">
            <div className="progress-header">
              <span>Progress</span>
              <span>{((presale.raised / presale.hardCap) * 100).toFixed(1)}%</span>
            </div>
            <div className="progress-bar">
              <div 
                className="progress-fill"
                style={{ width: `${(presale.raised / presale.hardCap) * 100}%` }}
              />
            </div>
            <div className="progress-details">
              <span>{presale.raised} {presale.network}</span>
              <span>{presale.hardCap} {presale.network}</span>
            </div>
          </div>

          <div className="card-footer">
            <div className="info-grid">
              <div className="info-item">
                <span className="label">Min Buy</span>
                <span className="value">{presale.minBuy} {presale.network}</span>
              </div>
              <div className="info-item">
                <span className="label">Max Buy</span>
                <span className="value">{presale.maxBuy} {presale.network}</span>
              </div>
              <div className="info-item">
                <span className="label">Lock</span>
                <span className="value">{presale.liquidityLock}</span>
              </div>
              <div className="info-item">
                <span className="label">24h Change</span>
                <span className={`value ${presale.change24h >= 0 ? 'positive' : 'negative'}`}>
                  {presale.change24h >= 0 ? <ArrowUpRight size={16} /> : <ArrowDownRight size={16} />}
                  {Math.abs(presale.change24h)}%
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="presale-container">
      <div className="presale-content">
        {/* Trending Section */}
        <TrendingSection />

        {/* Header Section */}
        <div className="header-section">
          <div className="page-title">
            <h1>Presale List</h1>
            <p className="subtitle">Discover upcoming and live token presales</p>
          </div>
          <button className="create-button">
            <Plus size={20} />
            Create Presale
          </button>
        </div>

        {/* Filter Section */}
        <div className="filter-section">
          <div className="search-wrapper">
            <Search className="search-icon" size={20} />
            <input
              type="text"
              placeholder="Search presales..."
              className="search-input"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
          
          <div className="tabs-container">
            {['live', 'upcoming', 'cancelled'].map((tab) => (
              <button
                key={tab}
                className={`tab-button ${activeTab === tab ? 'active' : ''}`}
                onClick={() => setActiveTab(tab)}
              >
                {tab === 'live' && <Clock size={16} />}
                {tab.charAt(0).toUpperCase() + tab.slice(1)}
              </button>
            ))}
          </div>
        </div>

        {/* Presale Grid */}
        <div className="presales-grid">
          {presaleData.presales
            .filter(presale => presale.status === activeTab)
            .filter(presale => 
              presale.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
              presale.symbol.toLowerCase().includes(searchTerm.toLowerCase())
            )
            .map(presale => (
              <PresaleCard key={presale.id} presale={presale} />
            ))
          }
        </div>

        {/* Empty State */}
        {presaleData.presales
          .filter(presale => presale.status === activeTab)
          .filter(presale => 
            presale.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            presale.symbol.toLowerCase().includes(searchTerm.toLowerCase())
          ).length === 0 && (
          <div className="empty-state">
            <div className="empty-icon">
              <Search size={48} />
            </div>
            <h3>No presales found</h3>
            <p>Try adjusting your search or filters</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Presale;
