// src/services/ethService.js
import { ethers } from 'ethers';
import tokenABI from '../components/Dashboard/Token/abis/TokenABI.json'; // Ensure TokenABI.json exists in src/abis/
import tokenBytecode from '../components/Dashboard/Token/abis/TokenBytecode.json'; // Ensure TokenBytecode.json exists in src/abis/
import { formatUnits, parseUnits } from 'ethers'; // Corrected import for ethers v6

// Function to calculate ETH fee based on USD amount
export const calculateETHFee = async (usdAmount) => {
  try {
    const response = await fetch('https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd');
    const data = await response.json();
    const ethPrice = data.ethereum.usd;
    const ethFee = usdAmount / ethPrice;
    return parseFloat(ethFee.toFixed(5)); // Returns fee with 5 decimal places
  } catch (error) {
    console.error('Error fetching ETH price:', error);
    return 0.01; // Default fee
  }
};

// Function to deploy ERC20 Token using the authenticated signer
export const deployToken = async (tokenData, ethFee, signer) => {
  try {
    if (!signer) {
      throw new Error('No signer available. Please connect your wallet.');
    }

    // Create a ContractFactory with the signer
    const TokenFactory = new ethers.ContractFactory(tokenABI, tokenBytecode, signer);

    // Deploy the token contract
    const tokenContract = await TokenFactory.deploy(
      tokenData.name,
      tokenData.symbol,
      parseUnits(tokenData.totalSupply.toString(), tokenData.decimals),
      tokenData.ownerAddress
      // Add other constructor parameters if your contract requires
    );

    // Wait for the deployment transaction to be mined
    const receipt = await tokenContract.deployTransaction.wait();

    return {
      contractAddress: tokenContract.address,
      transactionReceipt: receipt,
      tokenData, // Pass token data back if needed
    };
  } catch (error) {
    console.error('Error deploying token:', error);
    throw error;
  }
};
