// src/components/Dashboard/Token/steps/Step4ReviewDeploy.js
import React, { useContext, useState, useEffect } from 'react';
import { TokenContext } from '../TokenContext';
import { calculateETHFee, deployToken } from '../../../../services/ethService';
import { toast } from 'react-toastify';

const Step4ReviewDeploy = ({ prevStep, onDeployed, signer }) => {
  const { tokenData } = useContext(TokenContext);
  const [ethFee, setEthFee] = useState(0);
  const [deploying, setDeploying] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState('');
  const [contractAddress, setContractAddress] = useState('');

  useEffect(() => {
    const fetchFee = async () => {
      const fee = await calculateETHFee(100); // Adjust as per your logic
      setEthFee(fee);
    };
    fetchFee();
  }, []);

  const handleDeploy = async () => {
    if (!signer) {
      setError('No signer available. Please connect your wallet.');
      toast.error('No signer available. Please connect your wallet.');
      return;
    }

    setDeploying(true);
    setError('');
    try {
      const result = await deployToken(tokenData, ethFee, signer);
      setContractAddress(result.contractAddress);
      setSuccess(true);
      onDeployed(result);
      toast.success('Token deployed successfully!');
    } catch (err) {
      console.error('Deployment Error:', err);
      // Extract meaningful error message
      const errorMessage = err.reason || err.message || 'Please try again.';
      setError(`Deployment failed: ${errorMessage}`);
      toast.error(`Deployment failed: ${errorMessage}`);
    } finally {
      setDeploying(false);
    }
  };

  if (success) {
    return (
      <div className="token_deploy-success">
        <i className="fas fa-check-circle token_success-icon"></i>
        <h2 className="token_success-title">Token Deployed Successfully!</h2>
        <p className="token_success-message">
          Your token has been deployed and is now live on the blockchain.
        </p>
        <div className="token_contract-address">
          <strong>Contract Address:</strong>
          <br />
          {contractAddress}
        </div>
        <button 
          className="token_button token_button-next" 
          onClick={() => { window.open(`https://etherscan.io/address/${contractAddress}`, '_blank'); }}
        >
          View on Explorer
        </button>
      </div>
    );
  }

  return (
    <div className="token_review-section">
      <div className="token_review-header">
        <h2 className="token_review-title">Review Your Token</h2>
        <p className="token_review-subtitle">
          Please review all details before deployment
        </p>
      </div>

      <div className="token_review-content">
        <div className="token_review-group">
          <h3 className="token_review-group-title">
            <i className="fas fa-info-circle"></i>
            Basic Information
          </h3>
          <div className="token_review-item">
            <span className="token_review-label">Token Name</span>
            <span className="token_review-value highlight">{tokenData.name}</span>
          </div>
          <div className="token_review-item">
            <span className="token_review-label">Symbol</span>
            <span className="token_review-value highlight">{tokenData.symbol}</span>
          </div>
          <div className="token_review-item">
            <span className="token_review-label">Total Supply</span>
            <span className="token_review-value">{tokenData.totalSupply}</span>
          </div>
        </div>

        <div className="token_review-group">
          <h3 className="token_review-group-title">
            <i className="fas fa-cog"></i>
            Token Configuration
          </h3>
          <div className="token_review-item">
            <span className="token_review-label">Token Type</span>
            <span className="token_review-value">{tokenData.tokenType}</span>
          </div>
          {tokenData.tokenType === 'Tax' && (
            <div className="token_review-item">
              <span className="token_review-label">Tax Rate</span>
              <span className="token_review-value">{tokenData.taxRate}%</span>
            </div>
          )}
          <div className="token_review-item">
            <span className="token_review-label">Decimals</span>
            <span className="token_review-value">{tokenData.decimals}</span>
          </div>
        </div>

        {tokenData.tokenType === 'Tax' && (
          <div className="token_review-group">
            <h3 className="token_review-group-title">
              <i className="fas fa-wallet"></i>
              Fee Configuration
            </h3>
            <div className="token_review-item">
              <span className="token_review-label">Fee Recipient 1</span>
              <span className="token_review-value">{tokenData.feeRecipient1}</span>
            </div>
            <div className="token_review-item">
              <span className="token_review-label">Fee Recipient 2</span>
              <span className="token_review-value">{tokenData.feeRecipient2}</span>
            </div>
          </div>
        )}

        <div className="token_deploy-section">
          <p className="token_deploy-info">
            Deploying this token will require a one-time deployment fee to cover network costs.
          </p>
          <div className="token_deploy-fee">
            Deployment Fee: <span>{ethFee} ETH</span>
          </div>
          
          {error && <div className="token_error" style={{ color: '#FF6B6B', marginBottom: '16px' }}>{error}</div>}

          <div className="token_buttons">
            <button 
              className="token_button token_button-back" 
              type="button" 
              onClick={prevStep}
            >
              Back
            </button>
            <button 
              className="token_deploy-button" 
              type="button" 
              onClick={handleDeploy} 
              disabled={deploying}
            >
              {deploying ? (
                <>
                  <div className="token_deploy-spinner"></div>
                  Deploying...
                </>
              ) : (
                'Deploy Token'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step4ReviewDeploy;
