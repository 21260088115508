// src/components/VerifyMnemonic.js
import React, { useState, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ethers } from 'ethers';
import './Mnemonic.css';
import { FaInfoCircle } from 'react-icons/fa';
import { Tooltip, IconButton } from '@mui/material'; // Import Tooltip and IconButton from Material-UI

const VerifyMnemonic = () => {
  const { temporaryMnemonic, login } = useAuth();
  const navigate = useNavigate();
  const [inputWords, setInputWords] = useState(Array(12).fill(''));
  const [missingIndices, setMissingIndices] = useState([]);
  const [isVerifying, setIsVerifying] = useState(false);

  useEffect(() => {
    if (!temporaryMnemonic) {
      toast.error('Mnemonic phrase is missing. Please create or import a wallet first.');
      navigate('/');
      return;
    }

    // Split the mnemonic phrase into individual words
    const mnemonicWords = temporaryMnemonic.trim().toLowerCase().split(/\s+/);

    // Randomly select 3 indices that will be missing
    const totalWords = mnemonicWords.length;
    const indices = Array.from({ length: totalWords }, (_, i) => i);
    shuffleArray(indices);
    const selectedMissingIndices = indices.slice(0, 3).sort((a, b) => a - b);

    setMissingIndices(selectedMissingIndices);

    // Pre-fill the inputWords array, leaving selected indices empty
    const initialInputWords = mnemonicWords.map((word, index) =>
      selectedMissingIndices.includes(index) ? '' : word
    );
    setInputWords(initialInputWords);
  }, [temporaryMnemonic, navigate]);

  // Fisher-Yates shuffle algorithm
  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
  };

  const handleChange = (index, value) => {
    const newInputWords = [...inputWords];
    newInputWords[index] = value.trim().toLowerCase();
    setInputWords(newInputWords);
  };

  const handleSubmit = async () => {
    // Check if all missing words are filled
    for (let idx of missingIndices) {
      if (!inputWords[idx]) {
        toast.error(`Please fill in the word number ${idx + 1}.`);
        return;
      }
    }

    // Reconstruct the entered mnemonic phrase
    const enteredMnemonic = inputWords.join(' ').trim().toLowerCase();
    const storedMnemonic = temporaryMnemonic.trim().toLowerCase();

    if (enteredMnemonic === storedMnemonic) {
      try {
        setIsVerifying(true);
        if (!ethers.Mnemonic.isValidMnemonic(enteredMnemonic)) {
          throw new Error('The entered mnemonic is invalid.');
        }
        const wallet = ethers.Wallet.fromPhrase(enteredMnemonic);

        // Create a provider
        const provider = new ethers.JsonRpcProvider(`https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`);

        // Connect the wallet to the provider
        const connectedWallet = wallet.connect(provider);

        login({ address: connectedWallet.address, wallet: connectedWallet });
        toast.success('Mnemonic successfully verified. Logged in!');
        navigate('/dashboard');
      } catch (error) {
        console.error('Error creating wallet from mnemonic:', error);
        toast.error(error.message || 'Failed to create wallet from mnemonic.');
      } finally {
        setIsVerifying(false);
      }
    } else {
      toast.error('Mnemonic does not match. Please try again.');
    }
  };

  const handleBack = () => {
    navigate('/backup-mnemonic');
  };

  return (
    <div className="sign-in sign-in--v2">
      <div className="sign-in-card">
        <div className="mnemonic-content">
          <h2 className="mnemonic-title">Verify Your Mnemonic</h2>
          <p className="mnemonic-instructions">
            Please fill in the missing words of your mnemonic to verify your wallet.
            {/* Tooltip with Information Icon */}
            <Tooltip
              title={
                <React.Fragment>
                  <strong>Why Verify Your Mnemonic?</strong>
                  <br />
                  Verifying your mnemonic phrase ensures that you have correctly backed it up. This verification step confirms that the mnemonic you have saved matches the one generated during wallet creation, preventing potential loss of access to your funds.
                  <br />
                  <strong>How to Verify:</strong>
                  <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                    <li>Carefully enter the missing words in the designated fields.</li>
                    <li>Ensure each word is spelled correctly.</li>
                    <li>Do not share your mnemonic phrase with anyone.</li>
                  </ul>
                  If the verification fails, please revisit the backup step to ensure your mnemonic is correctly recorded.
                </React.Fragment>
              }
              placement="right"
              arrow
              interactive
            >
              <IconButton
                aria-label="learn more about verifying mnemonic phrases"
                style={{ marginLeft: '10px', color: '#007bff' }}
              >
                <FaInfoCircle />
              </IconButton>
            </Tooltip>
          </p>
          <div className="mnemonic-words">
            {inputWords.map((word, index) => (
              <div key={index} className="mnemonic-word">
                {missingIndices.includes(index) ? (
                  <input
                    type="text"
                    value={word}
                    onChange={(e) => handleChange(index, e.target.value)}
                    placeholder={`${index + 1}.`}
                    className="form-control form-control-sm mini-form__input"
                    aria-label={`Mnemonic word ${index + 1}`}
                  />
                ) : (
                  <div className="form-control form-control-sm mini-form__input filled-word">
                    <span className="word-index">{index + 1}.</span> {word}
                  </div>
                )}
              </div>
            ))}
          </div>
          <div className="mnemonic-buttons">
            <button onClick={handleBack} className="btn btn-secondary advance-card__button btn-sm">
              Back
            </button>
            <button
              onClick={handleSubmit}
              className="btn btn-primary advance-card__button btn-sm btn-pill"
              disabled={isVerifying}
            >
              {isVerifying ? 'Verifying...' : 'Verify and Log In'}
            </button>
          </div>
        </div>
      </div>
      <div className="sign-in-banner">
        <div className="sign-in-banner__content">
          <img
            className="sign-in-banner__illustration"
            src="/assets/media/images/sign-in/verify-illustration.png"
            alt="Verify Illustration"
            data-aos="zoom-in-up"
            data-aos-delay="50"
          />
        </div>
      </div>
    </div>
  );
};

export default VerifyMnemonic;
