// src/components/SignIn.js
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { injected, walletconnect } from '../connectors';
import { useWeb3React } from '@web3-react/core';
import { ethers, Wallet } from 'ethers'; // Přidán Wallet
import useAuth from '../hooks/useAuth';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import './SignIn.css';
import './Mnemonic.css';
import ImportWallet from './ImportWallet'; // Import nového komponentu

const SignIn = () => {
  const { activate, account, library, active } = useWeb3React();
  const { login, setTemporaryMnemonic } = useAuth();
  const [view, setView] = useState('initial');
  const [topCryptos, setTopCryptos] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (active && account && library) {
      const provider = new ethers.BrowserProvider(window.ethereum);
      const signer = provider.getSigner();
      login({ address: account, wallet: signer, walletType: 'metamask' });
      toast.success('Wallet successfully connected!');
      navigate('/dashboard');
    }
  }, [active, account, library, login, navigate]);

  // Fetch top 13 cryptos from CoinGecko or localStorage
  useEffect(() => {
    const fetchTopCryptos = async () => {
      try {
        const response = await axios.get(
          'https://api.coingecko.com/api/v3/coins/markets',
          {
            params: {
              vs_currency: 'usd',
              order: 'market_cap_desc',
              per_page: 13,
              page: 1,
            },
          }
        );

        const data = response.data;
        setTopCryptos(data);

        // Save data to localStorage
        localStorage.setItem('topCryptos', JSON.stringify(data));
      } catch (error) {
        console.error('Error fetching top cryptos:', error);

        // Load from localStorage if API fails
        const localData = localStorage.getItem('topCryptos');
        if (localData) {
          setTopCryptos(JSON.parse(localData));
        }
      }
    };

    fetchTopCryptos();
  }, []);

  // Handler to connect MetaMask
  const handleConnectMetaMask = async () => {
    if (!window.ethereum) {
      toast.error('MetaMask is not installed. Please install MetaMask and try again.');
      return;
    }

    try {
      await activate(injected, undefined, true);
    } catch (err) {
      console.error('Error connecting MetaMask:', err);
      toast.error('Failed to connect to MetaMask.');
    }
  };

  // Handler to connect WalletConnect
  const handleConnectWalletConnect = async () => {
    try {
      await activate(walletconnect, undefined, true);
    } catch (err) {
      console.error('Error connecting WalletConnect:', err);
      toast.error('Failed to connect to WalletConnect.');
    }
  };

  // Create a new wallet
  const handleCreateWallet = () => {
    try {
      const newWallet = Wallet.createRandom(); // Použití Wallet z ethers
      const mnemonic = newWallet.mnemonic.phrase;
      setTemporaryMnemonic(mnemonic);
      navigate('/backup-mnemonic');
    } catch (error) {
      console.error('Error creating wallet:', error);
      toast.error('Failed to create wallet.');
    }
  };

  const handleBack = () => {
    setView('initial');
  };

  return (
    <div className="sign-in sign-in--v2">
      <div className="sign-in-card">
        <div className="sign-in__heading" style={{ textAlign: 'center' }}>
          <div className="logo-container">
            <img src="/assets/media/images/logo-full.png" alt="Logo" className="logo" data-aos="fade-up" />
          </div>

          <p className="sign-in__title" data-aos="fade-up" data-aos-delay="50">
            Connect your wallet to get started.
          </p>
        </div>

        {view === 'initial' && (
          <>
            <button onClick={handleConnectMetaMask} className="btn btn-google-signin" data-aos="zoom-in-up" data-aos-delay="50">
              <img src="/assets/media/images/icons/MetaMask_Fox.svg" alt="MetaMask Logo" className="logo" />
              <p>Connect MetaMask</p>
            </button>
            <button onClick={handleConnectWalletConnect} className="btn btn-walletconnect" data-aos="zoom-in-up" data-aos-delay="250">
              <img
                src="https://logosarchive.com/wp-content/uploads/2022/02/WalletConnect-icon.svg"
                alt="WalletConnect Logo"
                className="logo"
              />
              <p>WalletConnect</p>
            </button>
            <div className="sign-in__break-or">
              <div className="line"></div>
              <p className="fb-lg">Or</p>
              <div className="line"></div>
            </div>
            <div className="alternative-signin-options" data-aos="fade-up" data-aos-delay="100">
              <button onClick={() => setView('import')} className="btn btn-google-signin" data-aos="zoom-in-up" data-aos-delay="150">
                <p>Import Wallet</p>
              </button>
              <br />
              <button onClick={handleCreateWallet} className="btn btn-google-signin" data-aos="zoom-in-up" data-aos-delay="200">
                <p>Create Wallet</p>
              </button>
            </div>
          </>
        )}

        {view === 'import' && (
          <ImportWallet handleBack={handleBack} />
        )}
      </div>

      <div className="sign-in-banner">
        <div className="sign-in-banner__content">
          {view === 'initial' && (
            <>
              <h2 className="sign-in-banner__title" data-aos="fade-up">
                Welcome to Cross-chain Wallet
              </h2>
              <p className="fb-lg sign-in-banner__subtitle" data-aos="fade-up" data-aos-delay="50">
                Connect seamlessly with Ethereum, TON, and Bitcoin. Trade, bridge, and manage your DeFi in one place with Wpayin.
              </p>
              <div className="top-cryptos" data-aos="zoom-in-up" data-aos-delay="50" style={{ display: 'flex', gap: '10px', justifyContent: 'left' }}>
                {topCryptos.map((crypto) => (
                  <img key={crypto.id} src={crypto.image} alt={crypto.name} title={`${crypto.name} - ${crypto.symbol.toUpperCase()}`} className="crypto-icon" />
                ))}
              </div>
            </>
          )}
          {view === 'import' && (
            <>
              <h4 className="sign-in-banner__title" data-aos="fade-up">
                Import Your Wallet Securely
              </h4>
              <p className="fb-lg sign-in-banner__subtitle" data-aos="fade-up" data-aos-delay="50">
                Ensure you enter your mnemonic or private key correctly to access your wallet.
              </p>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignIn;
