// src/connectors.js
import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';

const INFURA_ID = process.env.REACT_APP_INFURA_ID;

if (!INFURA_ID) {
  console.error('Please set your Infura ID in the .env file.');
}

const RPC_URLS = {
  1: `https://mainnet.infura.io/v3/${INFURA_ID}`,
  3: `https://ropsten.infura.io/v3/${INFURA_ID}`,
  4: `https://rinkeby.infura.io/v3/${INFURA_ID}`,
  5: `https://goerli.infura.io/v3/${INFURA_ID}`,
  42: `https://kovan.infura.io/v3/${INFURA_ID}`,
};

// MetaMask (Injected) connector
export const injected = new InjectedConnector({
  supportedChainIds: [1, 3, 4, 5, 42],
});

// WalletConnect connector
export const walletconnect = new WalletConnectConnector({
  rpc: RPC_URLS,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  pollingInterval: 12000, // Optional: Adjust polling interval as needed
  // Optional: Specify infuraId directly if needed
  infuraId: INFURA_ID,
});
