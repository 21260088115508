// src/components/Dashboard/Token/sections/TokenManagement.js
import React from 'react';
import { toast } from 'react-toastify';

const TokenManagement = ({ tokens, goToCreate }) => {
  return (
    <div className="token_management">
      <div className="token_management-header">
        <h2>Token Management</h2>
        <h3>Select a token</h3>
      </div>
      {tokens.length === 0 ? (
        <div className="token_empty-state">
          <i className="fas fa-box token_empty-icon"></i>
          <p>No Tokens Available</p>
          <p>Create a token to manage it here.</p>
          <button 
            className="token_button token_button-next" 
            onClick={goToCreate} // Use the navigation function
          >
            Create Token
          </button>
        </div>
      ) : (
        <div className="token_list">
          {tokens.map((token, index) => (
            <div key={index} className="token_card">
              <h3>{token.name} ({token.symbol})</h3>
              <p>Total Supply: {token.totalSupply}</p>
              <p>Token Type: {token.tokenType}</p>
              <p>Decimals: {token.decimals}</p>
              {token.tokenType === 'Tax' && (
                <>
                  <p>Tax Rate: {token.taxRate}%</p>
                  <p>Fee Recipient 1: {token.feeRecipient1}</p>
                  <p>Fee Recipient 2: {token.feeRecipient2}</p>
                </>
              )}
              <p>Owner Address: {token.ownerAddress}</p>
              <p>Contract Address: {token.contractAddress}</p>
              <button 
                className="token_button token_button-next" 
                onClick={() => window.open(`https://etherscan.io/address/${token.contractAddress}`, '_blank')}
              >
                View on Explorer
              </button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default TokenManagement;
