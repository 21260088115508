// src/components/Dashboard/Token/steps/Step1BasicInfo.js
import React, { useContext, useState } from 'react';
import { TokenContext } from '../TokenContext';


const Step1BasicInfo = ({ nextStep }) => {
  const { tokenData, updateTokenData } = useContext(TokenContext);
  const [name, setName] = useState(tokenData.name);
  const [symbol, setSymbol] = useState(tokenData.symbol);
  const [totalSupply, setTotalSupply] = useState(tokenData.totalSupply);

  const handleNext = () => {
    if (!name || !symbol || !totalSupply) {
      alert('Please fill in all required fields.');
      return;
    }
    updateTokenData({ name, symbol, totalSupply });
    nextStep();
  };

  return (
    <div>
      <h2>Basic Information</h2>
      <form className="token_form">
        <div className="token_form-group">
          <label className="token_label">Token Name:</label>
          <input 
            className="token_input"
            type="text" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            required 
          />
        </div>
        <div className="token_form-group">
          <label className="token_label">Symbol:</label>
          <input 
            className="token_input"
            type="text" 
            value={symbol} 
            onChange={(e) => setSymbol(e.target.value)} 
            required 
          />
        </div>
        <div className="token_form-group">
          <label className="token_label">Total Supply:</label>
          <input 
            className="token_input"
            type="number" 
            value={totalSupply} 
            onChange={(e) => setTotalSupply(e.target.value)} 
            required 
          />
        </div>
        <div className="token_buttons">
          <button 
            className="token_button token_button-next" 
            type="button" 
            onClick={handleNext}
          >
            Next
          </button>
        </div>
      </form>
    </div>
  );
};

export default Step1BasicInfo;
