// src/utils/getTokenPrice.js

import axios from 'axios';

// Base URL of your backend server
const BACKEND_BASE_URL = 'https://price.walletpayin.com'; // Adjust if different

// Helper function to map symbols to CoinGecko IDs
const getCoinGeckoId = (symbol) => {
  const mapping = {
    ETH: 'ethereum',
    WETH: 'weth',
    USDT: 'tether',
    BTC: 'bitcoin',
    XRP: 'ripple',
    SOL: 'solana',
    ADA: 'cardano',
    // Add more tokens as needed
  };

  return mapping[symbol.toUpperCase()] || symbol.toLowerCase();
};

/**
 * Fetches the USD price, 24-hour change, name, symbol, and image for a given token symbol.
 * 
 * @param {string} tokenSymbol - The symbol of the token (e.g., 'ETH').
 * @returns {Promise<{ usd: number, change_24h: number, name: string, symbol: string, image: string }>} - The token data.
 */
export const getTokenPrice = async (tokenSymbol) => {
  try {
    const id = getCoinGeckoId(tokenSymbol);
    const response = await axios.get(`${BACKEND_BASE_URL}/api/token-prices`);
    const prices = response.data; // Object mapping id to data

    if (prices[id]) {
      return {
        usd: prices[id].usd,
        change_24h: prices[id].change_24h,
        name: prices[id].name,
        symbol: prices[id].symbol,
        image: prices[id].image,
      };
    } else {
      console.warn(`Token ID '${id}' not found in CoinGecko data.`);
      return { usd: 0, change_24h: 0, name: '', symbol: tokenSymbol.toUpperCase(), image: '' };
    }
  } catch (error) {
    console.error(`Error fetching price for ${tokenSymbol}:`, error.message);
    return { usd: 0, change_24h: 0, name: '', symbol: tokenSymbol.toUpperCase(), image: '' };
  }
};

/**
 * Fetches prices for multiple tokens at once.
 * Useful for batch fetching to optimize performance.
 * 
 * @param {string[]} tokenSymbols - Array of token symbols.
 * @returns {Promise<Object>} - An object mapping token symbols to their price data.
 */
export const getMultipleTokenPrices = async (tokenSymbols) => {
  try {
    const response = await axios.get(`${BACKEND_BASE_URL}/api/token-prices`);
    const prices = response.data; // Object mapping id to data

    const result = {};
    tokenSymbols.forEach(symbol => {
      const id = getCoinGeckoId(symbol);
      if (prices[id]) {
        result[symbol] = {
          usd: prices[id].usd,
          change_24h: prices[id].change_24h,
          name: prices[id].name,
          symbol: prices[id].symbol,
          image: prices[id].image,
        };
      } else {
        console.warn(`Token ID '${id}' not found in CoinGecko data.`);
        result[symbol] = { usd: 0, change_24h: 0, name: '', symbol: symbol.toUpperCase(), image: '' };
      }
    });

    return result;
  } catch (error) {
    console.error('Error fetching multiple token prices:', error.message);
    const fallback = {};
    tokenSymbols.forEach(symbol => {
      fallback[symbol] = { usd: 0, change_24h: 0, name: '', symbol: symbol.toUpperCase(), image: '' };
    });
    return fallback;
  }
};
