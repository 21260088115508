import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft, Clock, CheckCircle, Copy, TrendingUp, Facebook, Twitter, Globe, MessageCircle } from 'lucide-react';
import presaleData from './presaleData.json';
import './PresaleDetail.css';

const PresaleDetail = () => {
  const { presaleId } = useParams();
  const navigate = useNavigate();
  const [amount, setAmount] = useState('');
  const [timeLeft, setTimeLeft] = useState({});
  
  const presale = presaleData.presales.find((p) => p.id === presaleId);
  const trendingPosition = presaleData.trending.findIndex((t) => t.id === presaleId) + 1;
  const saleStatus = presale ? presale.status.charAt(0).toUpperCase() + presale.status.slice(1) : '';

  // Memoized function to calculate time left
  const calculateTimeLeft = useCallback(() => {
    if (!presale) return {};
    const difference = +new Date(presale.endTime) - +new Date();
    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  }, [presale]);

  useEffect(() => {
    if (!presale) return;
    setTimeLeft(calculateTimeLeft());
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);
    return () => clearInterval(timer);
  }, [calculateTimeLeft, presale]);

  if (!presale) {
    return <div>Presale not found.</div>;
  }

  const progress = ((presale.raised / presale.hardCap) * 100).toFixed(1);
  
  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    alert("Copied to clipboard!");
  };

  return (
    <div className="presale-detail">
      {/* Header */}
      <div className="detail-header">
        <button className="back-button" onClick={() => navigate(-1)}>
          <ArrowLeft size={20} />
          Back
        </button>
        <div className="token-title">
          <img src={presale.image} alt={presale.name} className="token-image" />
          <div>
            <h1>{presale.name}</h1>
            <div className="token-badges">
              <span className="token-symbol">${presale.symbol}</span>
              <span className={`status-badge ${presale.status}`}>
                {saleStatus}
              </span>
              {presale.verified && (
                <span className="verified-badge">
                  <CheckCircle size={16} /> Verified
                </span>
              )}
              {trendingPosition > 0 && (
                <span className="trending-badge">
                  <TrendingUp size={16} /> Trending #{trendingPosition}
                </span>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Main Content */}
      <div className="detail-content">
        {/* Left Column */}
        <div className="detail-main">
          {/* About Section */}
          <div className="info-section">
            <h2>About {presale.name}</h2>
            <p>{presale.description || "No description available."}</p>
          </div>

          {/* Progress Section */}
          <div className="progress-section">
            <h2>Presale Progress</h2>
            <div className="progress-bar-container">
              <div className="progress-bar" style={{ width: `${progress}%` }}>
                <span className="progress-label">{progress}%</span>
              </div>
            </div>
            <div className="progress-info">
              <span>Raised: {presale.raised} {presale.network}</span>
              <span>Hard Cap: {presale.hardCap} {presale.network}</span>
            </div>
          </div>

          {/* Presale Details */}
          <div className="presale-details">
            <div className="detail-row">
              <span>Price</span>
              <span className="price-value">${presale.price}</span>
            </div>
            <div className="detail-row">
              <span>Soft Cap</span>
              <span>{presale.softCap} {presale.network}</span>
            </div>
            <div className="detail-row">
              <span>Hard Cap</span>
              <span>{presale.hardCap} {presale.network}</span>
            </div>
            <div className="detail-row">
              <span>Minimum Buy</span>
              <span>{presale.minBuy} {presale.network}</span>
            </div>
            <div className="detail-row">
              <span>Maximum Buy</span>
              <span>{presale.maxBuy} {presale.network}</span>
            </div>
            <div className="detail-row">
              <span>Lock Duration</span>
              <span>{presale.liquidityLock}</span>
            </div>
          </div>
          
          {/* Amount Input */}
          <div className="amount-section">
            <label htmlFor="amount">Amount</label>
            <input
              id="amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              placeholder="Enter amount to buy"
              className="amount-input"
            />
          </div>
        </div>

        {/* Right Column */}
        <div className="presale-info-card">
          <div className="card-header">
            <h2>Presale Details</h2>
            <div className="time-remaining">
              <Clock size={16} />
              <span>
                Presale Ends In: {timeLeft.days || 0}d {timeLeft.hours || 0}h {timeLeft.minutes || 0}m {timeLeft.seconds || 0}s
              </span>
            </div>
          </div>

          {/* Wallet & Token Address */}
          <div className="contract-address">
            <div className="address-header">
              Presale Wallet Address
              <button className="copy-button" onClick={() => handleCopy(presale.walletAddress)}>
                <Copy size={16} /> Copy
              </button>
            </div>
            <code>{presale.walletAddress}</code>

            <div className="address-header">
              Token Address
              <button className="copy-button" onClick={() => handleCopy(presale.tokenAddress)}>
                <Copy size={16} /> Copy
              </button>
            </div>
            <code>{presale.tokenAddress}</code>
          </div>

          {/* Social Links */}
          <div className="social-links">
            <h2>Social Media</h2>
            <div className="links-grid">
              <a href={presale.website} target="_blank" rel="noopener noreferrer" className="link-item">
                <Globe /> Website
              </a>
              <a href={presale.twitter} target="_blank" rel="noopener noreferrer" className="link-item">
                <Twitter /> Twitter
              </a>
              <a href={presale.telegram} target="_blank" rel="noopener noreferrer" className="link-item">
                <MessageCircle /> Telegram
              </a>
              <a href={presale.facebook} target="_blank" rel="noopener noreferrer" className="link-item">
                <Facebook /> Facebook
              </a>
            </div>
          </div>

          <button className="contribute-button">
            Connect Wallet to Participate
          </button>
        </div>
      </div>
    </div>
  );
};

export default PresaleDetail;
