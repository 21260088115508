// src/AppKitConfig.js
import { createAppKit } from '@reown/appkit/react';
import { EthersAdapter } from '@reown/appkit-adapter-ethers';
import { mainnet } from '@reown/appkit/networks';

const projectId = 'b274222583949ad56771356135266b61'; // Nahraďte svým skutečným Project ID

const networks = [mainnet];

const metadata = {
  name: 'Wpayin',
  description: 'The new Wpayin cross-chain wallet seamlessly connects multiple blockchain worlds, offering a unified experience across Ethereum, TON, and Bitcoin.',
  url: 'https://app.walletpayin.com', // Ujistěte se, že URL odpovídá vašemu doménovému jménu
  icons: ['https://pbs.twimg.com/profile_images/1848955278634500096/q4NuKCXI_400x400.jpgg'], // URL k vašemu logu
};

createAppKit({
  adapters: [new EthersAdapter()],
  networks,
  metadata,
  projectId,
  features: {
    analytics: true, // Volitelné
  },
});
