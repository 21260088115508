// src/components/Dashboard/Settings/Settings.js

import React, { useContext, useState } from 'react';
import { AuthContext } from '../../../contexts/AuthContext';
import { NotificationsContext } from '../../../contexts/NotificationsContext';
import { Navigate } from 'react-router-dom';
import './Settings.css'; // Ensure this CSS file exists for styling

const Settings = () => {
  const { auth } = useContext(AuthContext);
  const { addNotification } = useContext(NotificationsContext);

  // **Move hooks above any conditional statements**
  const [profileData, setProfileData] = useState({
    username: '',
    email: '',
    // Add other profile fields as needed
  });

  const [isUpdating, setIsUpdating] = useState(false);

  // **Now handle conditional rendering**
  if (!auth.isAuthenticated) {
    return <Navigate to="/" />;
  }

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  // Handle form submission
  const handleUpdate = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!profileData.username || !profileData.email) {
      addNotification({
        type: 'error',
        message: 'Please fill in all required fields.',
      });
      return;
    }

    setIsUpdating(true);

    try {
      // Implement your profile update logic here
      // For example, send data to your backend or an API endpoint

      // Simulating update delay
      await new Promise((resolve) => setTimeout(resolve, 2000));

      addNotification({
        type: 'success',
        message: 'Your profile has been updated successfully!',
      });
    } catch (error) {
      console.error('Profile update error:', error);
      addNotification({
        type: 'error',
        message: 'Failed to update profile. Please try again later.',
      });
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <div className="settings_container">
      <h1 className="settings_title">Account Settings</h1>
      <div className="settings_layout">
        {/* Sidebar */}
        <aside className="settings_sidebar">
          <nav className="settings_nav">
            <div className="settings_nav-item active">
              <i className="fas fa-user-cog"></i>
              Profile
            </div>
            <div className="settings_nav-item">
              <i className="fas fa-lock"></i>
              Security
            </div>
            <div className="settings_nav-item">
              <i className="fas fa-bell"></i>
              Notifications
            </div>
            {/* Add more navigation items as needed */}
          </nav>
          {/* Additional Sidebar Content */}
          <div className="settings_stats">
            <div className="settings_stats-item">
              <span className="settings_stats-label">Active Sessions</span>
              <span className="settings_stats-value">3</span>
            </div>
            <div className="settings_stats-item">
              <span className="settings_stats-label">Connected Wallets</span>
              <span className="settings_stats-value">1</span>
            </div>
            {/* Add more stats as needed */}
          </div>
        </aside>

        {/* Main Content */}
        <main className="settings_content">
          {/* Settings Form */}
          <form className="settings_form" onSubmit={handleUpdate}>
            <div className="settings_form-group">
              <label htmlFor="username" className="settings_label">Username</label>
              <input
                type="text"
                id="username"
                name="username"
                className="settings_input"
                value={profileData.username}
                onChange={handleChange}
                placeholder="Enter your username"
                required
              />
            </div>

            <div className="settings_form-group">
              <label htmlFor="email" className="settings_label">Email</label>
              <input
                type="email"
                id="email"
                name="email"
                className="settings_input"
                value={profileData.email}
                onChange={handleChange}
                placeholder="Enter your email"
                required
              />
            </div>

            {/* Add more form groups as needed */}

            <button
              type="submit"
              className="settings_button"
              disabled={isUpdating}
            >
              {isUpdating ? 'Updating...' : 'Update Profile'}
            </button>
          </form>
        </main>
      </div>
    </div>
  );
};

export default Settings;
