// src/utils/constants.js

// Mapování symbolů na CoinGecko ID pro získání cen a dalších informací
export const symbolToId = {
    ETH: 'ethereum',
    WETH: 'weth',
    USDT: 'tether',
    BTC: 'bitcoin',
    XRP: 'ripple',
    SOL: 'solana',
    ADA: 'cardano',
    BNB: 'binancecoin',
    MATIC: 'matic-network',
    AVAX: 'avalanche-2',
    DOT: 'polkadot',
    DOGE: 'dogecoin',
    SHIB: 'shiba-inu',
    CC: 'cardano-cash',
    WPI: 'wpayin',
    // Přidejte další symboly podle potřeby
  };
  
  // Mapování symbolů na dostupné sítě
  export const coinNetworks = {
    ETH: ['Ethereum', 'Polygon', 'Arbitrum', 'BSC'],
    WETH: ['Ethereum', 'Polygon', 'Arbitrum', 'BSC'],
    USDT: ['Ethereum', 'Tron', 'BSC', 'Polygon', 'Arbitrum'],
    BTC: ['Bitcoin', 'BSC'],
    XRP: ['Ripple', 'BSC'],
    SOL: ['Solana', 'BSC'],
    ADA: ['Cardano', 'BSC'],
    BNB: ['BSC', 'Ethereum'],
    MATIC: ['Polygon', 'Ethereum', 'BSC'],
    AVAX: ['Avalanche', 'BSC', 'Ethereum'],
    DOT: ['Polkadot', 'BSC'],
    DOGE: ['Dogecoin', 'BSC'],
    SHIB: ['Ethereum', 'BSC'],
    CC: ['Ethereum', 'BSC'],
    WPI: ['Ethereum'],
    // Přidejte další symboly a jejich sítě podle potřeby
  };
  
  // Mapování ERC20 adres na sítě
  export const tokenAddressToNetwork = {
    // Ethereum Mainnet
    '0xdac17f958d2ee523a2206206994597c13d831ec7': 'Ethereum', // USDT
    '0xc02aaA39b223FE8D0A0E5C4F27eAD9083C756Cc2': 'Ethereum', // WETH
    '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48': 'Ethereum', // USDC
  
    // Binance Smart Chain
    '0x55d398326f99059ff775485246999027b3197955': 'BSC', // USDT
    '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c': 'BSC', // WBNB
  
    // Polygon
    '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174': 'Polygon', // USDC
  
    // Arbitrum
    '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8': 'Arbitrum', // USDC
  
    // Přidejte další ERC20 adresy a jejich sítě podle potřeby
  };
  
  // Mapování barev aktiv
  export const assetColors = {
    ETH: '#3B82F6',
    BTC: '#F7931A',
    XRP: '#346AA9',
    SOL: '#00FFA3',
    ADA: '#0033AD',
    BNB: '#F3BA2F',
    MATIC: '#8247E5',
    AVAX: '#E84142',
    DOT: '#E6007A',
    DOGE: '#C2A633',
    SHIB: '#C0C0C0',
    CC: '#FF5733',
    // Přidejte další barvy podle potřeby
  };
  
  // Konfigurace časových období pro grafy
  export const periodConfigs = {
    '1H': { interval: 1, unit: 'minute', dataPoints: 60 },
    '24H': { interval: 1, unit: 'hour', dataPoints: 24 },
    '1W': { interval: 1, unit: 'day', dataPoints: 7 },
    '1M': { interval: 1, unit: 'week', dataPoints: 4 },
    '1Y': { interval: 1, unit: 'month', dataPoints: 12 },
    'ALL': { interval: 1, unit: 'year', dataPoints: 10 },
  };
  
  // Minimální částky pro vklady
  export const minimumDepositAmounts = {
    ETH: 0.01,
    WETH: 0.01,
    USDT: 10,
    BTC: 0.001,
    XRP: 10,
    SOL: 0.1,
    ADA: 10,
    BNB: 0.1,
    MATIC: 10,
    AVAX: 1,
    DOT: 1,
    DOGE: 100,
    SHIB: 1000000,
    CC: 5,
    // Přidejte další minimum podle potřeby
  };
  
  // Atributy sítí
  export const networkAttributes = {
    Ethereum: {
      chainId: '1',
      fee: 'High',
      estimatedTime: '5-20 min',
    },
    BSC: {
      chainId: '56',
      fee: 'Low',
      estimatedTime: '1-3 min',
    },
    Polygon: {
      chainId: '137',
      fee: 'Low',
      estimatedTime: '1-3 min',
    },
    Arbitrum: {
      chainId: '42161',
      fee: 'Medium',
      estimatedTime: '2-5 min',
    },
    Tron: {
      chainId: 'trx',
      fee: 'Low',
      estimatedTime: '1-3 min',
    },
    Bitcoin: {
      chainId: 'btc',
      fee: 'Medium',
      estimatedTime: '10-60 min',
    },
    Solana: {
      chainId: 'sol',
      fee: 'Very Low',
      estimatedTime: '1-2 min',
    },
    Ripple: {
      chainId: 'xrp',
      fee: 'Very Low',
      estimatedTime: '1-2 min',
    },
    Cardano: {
      chainId: 'ada',
      fee: 'Low',
      estimatedTime: '2-5 min',
    },
    Avalanche: {
      chainId: 'avax',
      fee: 'Low',
      estimatedTime: '2-5 min',
    },
    Polkadot: {
      chainId: 'dot',
      fee: 'Low',
      estimatedTime: '2-5 min',
    },
    Dogecoin: {
      chainId: 'doge',
      fee: 'Very Low',
      estimatedTime: '1-3 min',
    },
    // Přidejte další sítě podle potřeby
  };
  