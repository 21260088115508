// src/components/Dashboard/Wallet/DepositDialog.js

import React, { useState, useEffect, useContext, useMemo } from 'react';
import {
  Box, // Přidáno Box
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Grid,
  Button,
  CircularProgress,
  TextField,
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { FaTimes, FaCopy, FaInfoCircle, FaExclamationTriangle } from 'react-icons/fa';
import { QRCodeCanvas } from 'qrcode.react';
import { AuthContext } from '../../../contexts/AuthContext';
import { toast } from 'react-toastify';
import './DepositDialog.css';
import {
  symbolToId,
  coinNetworks,
  networkAttributes,
  minimumDepositAmounts,
  tokenAddressToNetwork, // Přidáno pro rozpoznání sítě
} from '../../../utils/constants'; // Import constants

// Helper function to convert hex to RGBA
const hexToRgba = (hex, alpha) => {
  if (typeof hex !== 'string') return `rgba(204, 204, 204, ${alpha})`;
  const sanitizedHex = hex.replace('#', '');
  if (sanitizedHex.length !== 6) return `rgba(204, 204, 204, ${alpha})`;
  const bigint = parseInt(sanitizedHex, 16);
  const r = (bigint >> 16) & 255;
  const g = (bigint >> 8) & 255;
  const b = bigint & 255;
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const DepositDialog = ({ open, onClose, selectedAsset, assets }) => {
  const { auth } = useContext(AuthContext);
  const userAddress = auth.user?.address || '';
  const accountName = auth.profile?.nickname || 'My Wallet';

  const [selectedCoin, setSelectedCoin] = useState('');
  const [selectedChain, setSelectedChain] = useState('');
  const [minDeposit, setMinDeposit] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [depositFee, setDepositFee] = useState('');
  const [processingTime, setProcessingTime] = useState('');

  // Create a list of available coins based on user's assets
  const availableCoins = useMemo(() => {
    return assets.map(asset => ({
      symbol: asset.symbol.toUpperCase(),
      name: asset.name || asset.symbol, // If no name, use symbol
      image: asset.image || '', // Keep empty string if no image
      color: asset.color || '#CCCCCC', // Default color
    }));
  }, [assets]);

  // Pre-set if an asset is selected
  useEffect(() => {
    if (selectedAsset) {
      const assetSymbol = selectedAsset.symbol.toUpperCase();
      setSelectedCoin(assetSymbol);
      const availableNetworks = coinNetworks[assetSymbol];
      if (availableNetworks && availableNetworks.length === 1) {
        setSelectedChain(availableNetworks[0]); // Auto-select if only one network
      } else if (availableNetworks && availableNetworks.length > 1) {
        // Automatically detect network based on ERC20 address
        const tokenAddress = selectedAsset.address.toLowerCase(); // Ensure address is lowercase
        const network = tokenAddressToNetwork[tokenAddress];
        if (network && availableNetworks.includes(network)) {
          setSelectedChain(network);
        } else {
          setSelectedChain(''); // If cannot auto-determine, let user select
        }
      } else {
        setSelectedChain(''); // If no networks available, let user select
      }
      setMinDeposit(minimumDepositAmounts[assetSymbol] || 0);
    } else {
      setSelectedCoin('');
      setSelectedChain('');
      setMinDeposit(0);
      setDepositFee('');
      setProcessingTime('');
    }
  }, [selectedAsset]);

  // Update network information when coin and network are selected
  useEffect(() => {
    if (selectedCoin && selectedChain) {
      setIsLoading(true);
      // Simulate data loading
      setTimeout(() => {
        const networkInfo = networkAttributes[selectedChain];
        if (networkInfo) {
          setDepositFee(networkInfo.fee);
          setProcessingTime(networkInfo.estimatedTime);
        } else {
          setDepositFee('N/A');
          setProcessingTime('N/A');
        }
        setIsLoading(false);
      }, 1000);
    } else {
      setDepositFee('');
      setProcessingTime('');
    }
  }, [selectedCoin, selectedChain]);

  const handleCoinChange = (event) => {
    const coin = event.target.value;
    setSelectedCoin(coin);
    setSelectedChain('');
    setMinDeposit(minimumDepositAmounts[coin] || 0);
  };

  const handleChainChange = (event, newValue) => {
    setSelectedChain(newValue || '');
  };

  const handleCopyAddress = () => {
    if (navigator.clipboard) {
      navigator.clipboard
        .writeText(userAddress)
        .then(() => {
          toast.success('Address copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy address:', err);
          toast.error('Failed to copy address.');
        });
    }
  };

  const generateQRCode = () => {
    if (!selectedCoin || !selectedChain) return '';
    const coin = availableCoins.find((c) => c.symbol === selectedCoin);
    return `${coin?.symbol.toLowerCase()}:${userAddress}?network=${selectedChain.toLowerCase()}`;
  };

  const handleClose = () => {
    setSelectedCoin('');
    setSelectedChain('');
    setMinDeposit(0);
    setDepositFee('');
    setProcessingTime('');
    onClose();
  };

  // Debugging - Log available networks for selectedCoin
  useEffect(() => {
    if (selectedCoin) {
      console.log(`Selected Coin: ${selectedCoin}`);
      console.log(`Available Networks: ${coinNetworks[selectedCoin]}`);
    }
  }, [selectedCoin]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      maxWidth="sm"
      fullWidth
      classes={{
        paper: 'MuiDialog-paper',
      }}
      BackdropProps={{
        className: 'custom-backdrop',
      }}
    >
      <DialogTitle className="dialog-title">
        <Typography variant="h6" component="div">
          Deposit Funds
        </Typography>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className="close-button"
        >
          <FaTimes />
        </IconButton>
      </DialogTitle>

      <DialogContent className="dialog-content">
        <Typography variant="subtitle1" className="nickname-label">
          Account: {accountName}
        </Typography>

        <Grid container spacing={2} style={{ marginTop: '20px' }}>
          {/* Select Coin - Display only the name */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              className="form-control"
              disabled={!!selectedAsset} // Disable if selectedAsset is set
            >
              <InputLabel id="coin-select-label">Select Coin</InputLabel>
              <Select
                labelId="coin-select-label"
                value={selectedCoin}
                label="Select Coin"
                onChange={handleCoinChange}
              >
                {availableCoins.map((coin) => (
                  <MenuItem key={coin.symbol} value={coin.symbol}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {coin.image ? (
                        <img
                          src={coin.image}
                          alt={coin.symbol}
                          className="asset-icon-image"
                          width={24}
                          height={24}
                        />
                      ) : (
                        <div
                          className="asset-icon-placeholder"
                          style={{
                            backgroundColor: hexToRgba(coin.color, 0.125),
                            color: coin.color,
                            width: '24px',
                            height: '24px',
                            borderRadius: '50%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontWeight: 'bold',
                            fontSize: '12px',
                            marginRight: '8px',
                          }}
                        >
                          {coin.symbol?.[0] || '?'}
                        </div>
                      )}
                      <Typography sx={{ ml: 1 }}>{coin.name}</Typography>
                    </Box>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          {/* Select Network - Use Autocomplete for searchable dropdown */}
          <Grid item xs={12} sm={6}>
            <FormControl
              fullWidth
              className="form-control"
              disabled={!selectedCoin}
            >
              <InputLabel id="network-autocomplete-label">Select Network</InputLabel>
              <Autocomplete
                id="network-autocomplete"
                options={Array.isArray(coinNetworks[selectedCoin]) ? coinNetworks[selectedCoin] : []}
                getOptionLabel={(option) => `${option} (${networkAttributes[option]?.fee} fee)`}
                value={selectedChain || null}
                onChange={handleChainChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Network"
                    variant="outlined"
                  />
                )}
                // Allow clearing the selection
                clearOnEscape
                disabled={!selectedCoin}
              />
            </FormControl>
          </Grid>
        </Grid>

        {isLoading ? (
          <div className="loading-container">
            <CircularProgress />
          </div>
        ) : (
          selectedCoin &&
          selectedChain && (
            <div className="deposit-details">
              <Typography
                variant="subtitle1"
                gutterBottom
                style={{ marginTop: '20px' }}
              >
                {availableCoins.find((coin) => coin.symbol === selectedCoin)?.name} Deposit Address
              </Typography>

              <div className="qr-code-container">
                <QRCodeCanvas
                  value={generateQRCode()}
                  size={200}
                  level="H"
                  includeMargin={true}
                  style={{
                    background: 'white',
                    padding: '10px',
                    borderRadius: '8px',
                  }}
                />
              </div>

              <div className="address-display">
                <Typography variant="body1" className="address-text">
                  {userAddress}
                </Typography>
                <IconButton
                  onClick={handleCopyAddress}
                  color="primary"
                  className="copy-button"
                >
                  <FaCopy />
                </IconButton>
              </div>

              <div className="info-section">
                <div className="info-item">
                  <FaInfoCircle className="info-icon" />
                  <Typography variant="body2">
                    Minimum Deposit: {minDeposit} {selectedCoin}
                  </Typography>
                </div>
                <div className="info-item">
                  <FaInfoCircle className="info-icon" />
                  <Typography variant="body2">
                    Network Fee: {depositFee}
                  </Typography>
                </div>
                <div className="info-item">
                  <FaInfoCircle className="info-icon" />
                  <Typography variant="body2">
                    Estimated Processing Time: {processingTime}
                  </Typography>
                </div>
              </div>

              <div className="warning-section">
                <div className="warning-text">
                  <FaExclamationTriangle className="warning-icon" />
                  <Typography variant="body2">
                    Important: Send only {selectedCoin} on {selectedChain} network
                  </Typography>
                </div>
              </div>
            </div>
          )
        )}
      </DialogContent>

      <DialogActions className="dialog-actions">
        <Button
          onClick={handleClose}
          variant="contained"
          color="primary"
          className="btn-pill"
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DepositDialog;
