// src/utils/networks.js

export const networks = [
  {
    name: 'Ethereum Mainnet',
    chainId: '0x1',
    chainName: 'Ethereum Mainnet',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'ETH',
      decimals: 18,
    },
    rpcUrls: [
      `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`,
    ],
    blockExplorerUrls: ['https://etherscan.io'],
    logo: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=024',
  },
  {
    name: 'Arbitrum One',
    chainId: '0xa4b1', // Hexadecimal for 42161
    chainName: 'Arbitrum One',
    nativeCurrency: {
      name: 'Ether',
      symbol: 'AETH',
      decimals: 18,
    },
    rpcUrls: ['https://arb1.arbitrum.io/rpc'],
    blockExplorerUrls: ['https://arbiscan.io'],
    logo: 'https://cryptologos.cc/logos/arbitrum-arb-logo.svg?v=024',
  },
  {
    name: 'Base Mainnet',
    chainId: '0x2105', // Hexadecimal for 8453
    chainName: 'Base Mainnet',
    nativeCurrency: {
      name: 'Base',
      symbol: 'BASE',
      decimals: 18,
    },
    rpcUrls: ['https://mainnet.base.org'],
    blockExplorerUrls: ['https://basescan.org'],
    logo: 'https://cryptologos.cc/logos/base-base-logo.svg?v=024',
  },
  // Add more networks as needed, using public URLs for logos
];

/**
 * Utility function to get a network by its chain ID.
 * @param {string} chainId - The chain ID in hexadecimal format.
 * @returns {object|null} - The network object or null if not found.
 */
export function getNetworkByChainId(chainId) {
  return networks.find(
    (network) => network.chainId.toLowerCase() === chainId.toLowerCase()
  );
}
