// src/Dashboard.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Sidebar from './Sidebar/Sidebar';
import Topbar from './Topbar/Topbar';
import DashboardHome from './DashboardHome/DashboardHome';
import Wallet from './Wallet/Wallet';
import Swap from './Swap/Swap';
import Transactions from './Transactions/Transactions';
import BuySell from './BuySell/BuySell';
import Presale from './Presale/Presale';
import PresaleDetail from './Presale/PresaleDetail';
import Token from './Token/Token';
import Liquidity from './Liquidity/Liquidity'; // Import the Liquidity component
import Settings from './Settings/Settings';
import FeedBackSupport from './FeedBackSupport/FeedBackSupport';
import './Dashboard.css';

const Dashboard = () => {
  return (
    <div className="dashboard-container">
      <Sidebar />
      <div className="dashboard-main">
        <Topbar />
        <div className="dashboard-content">
          <Routes>
            <Route path="/" element={<DashboardHome />} />
            <Route path="wallet" element={<Wallet />} />
            <Route path="swap" element={<Swap />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="buy-sell" element={<BuySell />} />
            <Route path="presale" element={<Presale />} />
            <Route path="presale/:presaleId" element={<PresaleDetail />} />
            <Route path="token/*" element={<Token />} />
            <Route path="liquidity" element={<Liquidity />} /> {/* Add the Liquidity route */}
            <Route path="settings" element={<Settings />} />
            <Route path="feedback-support" element={<FeedBackSupport />} />
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
