// src/components/Dashboard/Swap/SwapSettingsModal.js
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Slider,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Typography,
  TextField,
  Switch,
  Tooltip,
  Divider,
} from '@mui/material';
import { FaInfoCircle, FaTimes, FaChevronDown } from 'react-icons/fa';
import { toast } from 'react-toastify';
import './SwapSettingsModal.css';

const SwapSettingsModal = ({ open, onClose, settings, setSettings }) => {
  // Základní nastavení
  const [slippage, setSlippage] = useState(settings?.slippage || 0.5);
  const [transactionSpeed, setTransactionSpeed] = useState(settings?.transactionSpeed || 'Medium');
  const [customGasPrice, setCustomGasPrice] = useState(settings?.customGasPrice || '');
  const [deadline, setDeadline] = useState(settings?.deadline || 20);
  
  // Advanced settings
  const [isAdvancedOpen, setIsAdvancedOpen] = useState(false);
  const [multihops, setMultihops] = useState(settings?.multihops ?? true);
  const [expertMode, setExpertMode] = useState(settings?.expertMode ?? false);
  const [autoRouter, setAutoRouter] = useState(settings?.autoRouter ?? true);
  const [partialFill, setPartialFill] = useState(settings?.partialFill ?? false);
  const [gasLimit, setGasLimit] = useState(settings?.gasLimit || '');

  useEffect(() => {
    // Základní nastavení
    setSlippage(settings?.slippage || 0.5);
    setTransactionSpeed(settings?.transactionSpeed || 'Medium');
    setCustomGasPrice(settings?.customGasPrice || '');
    setDeadline(settings?.deadline || 20);
    
    // Advanced settings
    setMultihops(settings?.multihops ?? true);
    setExpertMode(settings?.expertMode ?? false);
    setAutoRouter(settings?.autoRouter ?? true);
    setPartialFill(settings?.partialFill ?? false);
    setGasLimit(settings?.gasLimit || '');
  }, [settings]);

  const handleSubmit = () => {
    // Validace
    if (transactionSpeed === 'Custom') {
      if (!customGasPrice || isNaN(customGasPrice) || parseFloat(customGasPrice) <= 0) {
        toast.error('Please enter a valid custom gas price');
        return;
      }
    }

    if (gasLimit && (isNaN(gasLimit) || parseInt(gasLimit) <= 0)) {
      toast.error('Please enter a valid gas limit');
      return;
    }

    if (deadline <= 0 || deadline > 4320) {
      toast.error('Transaction deadline must be between 1 minute and 3 days');
      return;
    }

    const updatedSettings = {
      slippage,
      transactionSpeed,
      deadline,
      multihops,
      expertMode,
      autoRouter,
      partialFill,
      ...(transactionSpeed === 'Custom' && { customGasPrice: parseFloat(customGasPrice) }),
      ...(gasLimit && { gasLimit: parseInt(gasLimit) }),
    };

    setSettings(updatedSettings);
    toast.success('Swap settings updated successfully');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={false}
      BackdropProps={{ className: 'custom-backdrop' }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className="dialog-title">
        Swap Settings
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="close-button"
        >
          <FaTimes />
        </IconButton>
      </DialogTitle>

      <DialogContent className="dialog-content">
        {/* Slippage Tolerance Section */}
        <div className="setting-item">
          <div className="setting-label-container">
            <Typography variant="h6" className="setting-label">
              Slippage Tolerance
              <Tooltip
                title={
                  <React.Fragment>
                    <strong>Slippage Tolerance</strong>
                    <br />
                    Maximum price movement you will accept for this trade
                  </React.Fragment>
                }
                placement="right"
                arrow
                interactive
              >
                <span><FaInfoCircle className="info-icon" /></span>
              </Tooltip>
            </Typography>
          </div>
          
          <Slider
            value={slippage}
            onChange={(e, newValue) => setSlippage(newValue)}
            step={0.1}
            marks
            min={0.1}
            max={5}
            valueLabelDisplay="auto"
            className="slider"
          />
          <Typography variant="body2" className="slider-value">
            Current Slippage: {slippage}%
          </Typography>
        </div>

        <Divider className="settings-divider" />

        {/* Transaction Speed Section */}
        <div className="setting-item">
          <div className="setting-label-container">
            <Typography variant="h6" className="setting-label">
              Transaction Speed
              <Tooltip
                title={
                  <React.Fragment>
                    <strong>Transaction Speed</strong>
                    <br />
                    Higher speeds require higher gas fees
                  </React.Fragment>
                }
                placement="right"
                arrow
                interactive
              >
                <span><FaInfoCircle className="info-icon" /></span>
              </Tooltip>
            </Typography>
          </div>

          <FormControl fullWidth className="transaction-speed-select">
            <Select
              value={transactionSpeed}
              onChange={(e) => setTransactionSpeed(e.target.value)}
              className="select"
            >
              <MenuItem value="Fast">Fast (~30 seconds)</MenuItem>
              <MenuItem value="Medium">Medium (~1 minute)</MenuItem>
              <MenuItem value="Slow">Slow (~3 minutes)</MenuItem>
              <MenuItem value="Custom">Custom Gas Price</MenuItem>
            </Select>
          </FormControl>

          {transactionSpeed === 'Custom' && (
            <TextField
              label="Custom Gas Price (Gwei)"
              value={customGasPrice}
              onChange={(e) => setCustomGasPrice(e.target.value)}
              fullWidth
              variant="outlined"
              type="number"
              className="custom-gas-price-input"
            />
          )}
        </div>

        {/* Transaction Deadline */}
        <div className="setting-item">
          <div className="setting-label-container">
            <Typography variant="h6" className="setting-label">
              Transaction Deadline
              <Tooltip
                title={
                  <React.Fragment>
                    <strong>Transaction Deadline</strong>
                    <br />
                    Transaction will revert if pending for longer than this
                  </React.Fragment>
                }
                placement="right"
                arrow
                interactive
              >
                <span><FaInfoCircle className="info-icon" /></span>
              </Tooltip>
            </Typography>
          </div>
          
          <TextField
            label="Minutes"
            value={deadline}
            onChange={(e) => setDeadline(parseInt(e.target.value) || '')}
            type="number"
            className="deadline-input"
            inputProps={{ min: "1", max: "4320" }}
            fullWidth
          />
        </div>

        {/* Advanced Settings */}
        <div className="setting-item">
          <div 
            className="advanced-settings-header"
            onClick={() => setIsAdvancedOpen(!isAdvancedOpen)}
          >
            <Typography variant="h6" className="advanced-title">
              Advanced Settings
            </Typography>
            <FaChevronDown className={`advanced-icon ${isAdvancedOpen ? 'open' : ''}`} />
          </div>

          <div className={`advanced-settings-content ${isAdvancedOpen ? 'open' : ''}`}>
            <div className="toggle-settings-grid">
              {/* Expert Mode */}
              <div className="toggle-setting">
                <div className="toggle-label">
                  <Typography>
                    Expert Mode
                    <Tooltip
                      title="Enables high slippage trades. Use at your own risk."
                      placement="right"
                      arrow
                    >
                      <span><FaInfoCircle className="info-icon" /></span>
                    </Tooltip>
                  </Typography>
                </div>
                <Switch
                  checked={expertMode}
                  onChange={(e) => setExpertMode(e.target.checked)}
                  className="settings-switch"
                />
              </div>

              {/* Multi-hop Trades */}
              <div className="toggle-setting">
                <div className="toggle-label">
                  <Typography>
                    Multi-hop Trades
                    <Tooltip
                      title="Allow multiple hops to find the best price"
                      placement="right"
                      arrow
                    >
                      <span><FaInfoCircle className="info-icon" /></span>
                    </Tooltip>
                  </Typography>
                </div>
                <Switch
                  checked={multihops}
                  onChange={(e) => setMultihops(e.target.checked)}
                  className="settings-switch"
                />
              </div>

              {/* Auto Router */}
              <div className="toggle-setting">
                <div className="toggle-label">
                  <Typography>
                    Auto Router
                    <Tooltip
                      title="Automatically find the best trading route"
                      placement="right"
                      arrow
                    >
                      <span><FaInfoCircle className="info-icon" /></span>
                    </Tooltip>
                  </Typography>
                </div>
                <Switch
                  checked={autoRouter}
                  onChange={(e) => setAutoRouter(e.target.checked)}
                  className="settings-switch"
                />
              </div>

              {/* Partial Fill */}
              <div className="toggle-setting">
                <div className="toggle-label">
                  <Typography>
                    Allow Partial Fills
                    <Tooltip
                      title="Complete trade even if only partially filled"
                      placement="right"
                      arrow
                    >
                      <span><FaInfoCircle className="info-icon" /></span>
                    </Tooltip>
                  </Typography>
                </div>
                <Switch
                  checked={partialFill}
                  onChange={(e) => setPartialFill(e.target.checked)}
                  className="settings-switch"
                />
              </div>
            </div>

            {/* Custom Gas Limit */}
            <div className="setting-item gas-limit-container">
              <div className="setting-label-container">
                <Typography variant="body1" className="setting-label">
                  Custom Gas Limit
                  <Tooltip
                    title="Set a custom gas limit for your transaction"
                    placement="right"
                    arrow
                  >
                    <span><FaInfoCircle className="info-icon" /></span>
                  </Tooltip>
                </Typography>
              </div>
              <TextField
                label="Gas Limit"
                value={gasLimit}
                onChange={(e) => setGasLimit(e.target.value)}
                type="number"
                className="gas-limit-input"
                placeholder="Auto"
                fullWidth
              />
            </div>
          </div>
        </div>
      </DialogContent>

      <DialogActions className="dialog-actions">
        <div className="actions-container">
          <Tooltip
            title="Save your custom swap settings"
            placement="top"
            arrow
            interactive
          >
            <FaInfoCircle className="info-icon" />
          </Tooltip>
          
          <button
            onClick={handleSubmit}
            className="btn btn-primary advance-card__button btn-sm btn-pill"
          >
            Save and Update
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default SwapSettingsModal;