import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Avatar,
  Grid,
  IconButton,
  Typography,
} from '@mui/material';
import { FaInfoCircle, FaTimes } from 'react-icons/fa';
import { Tooltip } from '@mui/material';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/useAuth';
import './ProfileEditModal.css';

const predefinedAvatars = [
  '/assets/avatars/avatar1.png',
  '/assets/avatars/avatar2.png',
  '/assets/avatars/avatar3.png',
  '/assets/avatars/avatar4.png',
  '/assets/avatars/avatar5.png',
  '/assets/avatars/avatar6.png',
  '/assets/avatars/avatar7.png',
  '/assets/avatars/avatar8.png',
  '/assets/avatars/avatar9.png',
  '/assets/avatars/avatar0.png',
  '/assets/avatars/avatar11.png',
  '/assets/avatars/avatar12.png',
  '/assets/avatars/avatar13.png',
  '/assets/avatars/avatar14.png',
  '/assets/avatars/avatar15.png',
  '/assets/avatars/avatar16.png',
];

const ProfileEditModal = ({ open, onClose }) => {
  const { auth, setProfile } = useAuth();
  const [nickname, setNickname] = useState('');
  const [selectedAvatar, setSelectedAvatar] = useState('');

  useEffect(() => {
    if (auth.profile) {
      setNickname(auth.profile.nickname || '');
      setSelectedAvatar(auth.profile.profilePicture || '/assets/avatars/default.png');
    }
  }, [auth.profile]);

  const handleAvatarSelect = (avatar) => {
    setSelectedAvatar(avatar);
  };

  const handleSubmit = () => {
    if (!nickname.trim()) {
      toast.error('Nickname is required.');
      return;
    }

    const profileData = {
      nickname: nickname.trim(),
      profilePicture: selectedAvatar || '/assets/avatars/default.png',
    };

    setProfile(profileData);
    toast.success('Profile has been successfully updated.');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      disableEscapeKeyDown={false}
      BackdropProps={{ className: 'custom-backdrop' }}
      fullWidth
      maxWidth="sm"
    >
      <DialogTitle className="dialog-title">
        <Typography variant="h6" component="div" style={{ flex: 1, textAlign: 'center' }}>
          Edit Your Profile
        </Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
          className="close-button"
        >
          <FaTimes />
        </IconButton>
      </DialogTitle>

      <DialogContent className="dialog-content">
      <label htmlFor="nickname-input" className="nickname-label">
    Wallet name:
  </label>
  <input
    type="text"
    id="nickname-input"
    value={nickname}
    onChange={(e) => setNickname(e.target.value)}
    placeholder="Enter your wallet name"
    className="form-control form-control-sm mini-form__input"
    aria-label="Nickname"
  />

        <div className="avatar-selection">
          <p>Select a profile picture:</p>
          <Grid container spacing={0} className="avatars-grid">
            {predefinedAvatars.map((avatar, index) => (
              <Grid item xs={3} sm={2} md={1.2} key={index}>
                <Avatar
                  src={avatar}
                  alt={`Avatar ${index + 1}`}
                  className={`avatar ${selectedAvatar === avatar ? 'selected' : ''}`}
                  onClick={() => handleAvatarSelect(avatar)}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </DialogContent>

      <DialogActions className="dialog-actions">
        <div className="actions-container">
          <Tooltip
            title={
              <React.Fragment>
                <strong>Why Do We Need Your Profile Information?</strong>
                <br />
                Your nickname and profile picture help personalize your experience and make interactions within the app more engaging.
              </React.Fragment>
            }
            placement="top"
            arrow
            interactive
          >
            <FaInfoCircle className="info-icon" />
          </Tooltip>
          <button
            onClick={handleSubmit}
            className="btn btn-primary advance-card__button btn-sm btn-pill"
          >
            Save and Update
          </button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default ProfileEditModal;