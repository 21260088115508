import React from 'react';
import { CircularProgress } from '@mui/material';
import './Loading.css';

const Loading = () => {
  return (
    <div className="loading-overlay">
      <CircularProgress size={60} thickness={5} color="primary" />
    </div>
  );
};

export default Loading;
