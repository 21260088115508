// src/components/Dashboard/Topbar/Topbar.js

import React, { useState, useContext, useEffect } from 'react';
import { FaSearch, FaClipboard } from 'react-icons/fa';
import { Avatar, IconButton, Badge } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import useAuth from '../../../hooks/useAuth';
import ProfileEditModal from '../Profile/ProfileEditModal';
import { toast } from 'react-toastify';
import './Topbar.css';
import NetworkSelector from './NetworkSelector';
import { NotificationsContext } from '../../../contexts/NotificationsContext';
import { getNetworkByChainId } from '../../../utils/networks';

const Topbar = () => {
  const { auth, logout } = useAuth();
  const { notifications, unreadCount, markAllAsRead } = useContext(NotificationsContext);
  const [isProfileMenuOpen, setProfileMenuOpen] = useState(false);
  const [isNotificationsOpen, setNotificationsOpen] = useState(false);
  const [isEditProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [currentNetwork, setCurrentNetwork] = useState(null);

  const toggleProfileMenu = () => {
    setProfileMenuOpen(!isProfileMenuOpen);
  };

  const toggleNotifications = () => {
    setNotificationsOpen(!isNotificationsOpen);
    if (!isNotificationsOpen) {
      markAllAsRead();
    }
  };

  const handleLogout = () => {
    logout();
  };

  const handleEditProfileOpen = () => {
    setEditProfileModalOpen(true);
    setProfileMenuOpen(false);
  };

  const handleEditProfileClose = () => {
    setEditProfileModalOpen(false);
  };

  const handleCopyAddress = () => {
    if (auth.user?.address) {
      navigator.clipboard
        .writeText(auth.user.address)
        .then(() => {
          toast.success('Address copied to clipboard!');
        })
        .catch((err) => {
          console.error('Error copying address:', err);
          toast.error('Failed to copy address.');
        });
    }
  };

  useEffect(() => {
    const getCurrentNetwork = async () => {
      if (window.ethereum) {
        try {
          const chainId = await window.ethereum.request({ method: 'eth_chainId' });
          const network = getNetworkByChainId(chainId);
          setCurrentNetwork(network);
        } catch (error) {
          console.error('Error getting chainId:', error);
        }
      }
    };

    getCurrentNetwork();

    if (window.ethereum && typeof window.ethereum.on === 'function') {
      window.ethereum.on('chainChanged', getCurrentNetwork);
    }

    return () => {
      if (window.ethereum?.removeListener) {
        window.ethereum.removeListener('chainChanged', getCurrentNetwork);
      }
    };
  }, []);

  const getExplorerLink = (txHash) => {
    if (currentNetwork && currentNetwork.blockExplorerUrls && currentNetwork.blockExplorerUrls[0]) {
      return `${currentNetwork.blockExplorerUrls[0]}/tx/${txHash}`;
    }
    return '#';
  };

  return (
    <>
      <header className="topbar">
        <div className="topbar-left">
          <div className="navbar-search">
            <FaSearch className="navbar-search__icon" />
            <input
              type="text"
              className="navbar-search__input"
              placeholder="Search"
              aria-label="Search"
            />
          </div>
        </div>
        <div className="topbar-right">
          <div className="navbar-notifications">
            <IconButton color="inherit" onClick={toggleNotifications}>
              <Badge badgeContent={unreadCount} color="error">
                <NotificationsIcon />
              </Badge>
            </IconButton>
            {isNotificationsOpen && (
              <div className="navbar-notifications__dropdown">
                <p className="notifications__title">Notifications</p>
                <div className="notifications__box">
                  {notifications.length > 0 ? (
                    notifications.map((notification) => (
                      <div key={notification.id} className="notifications__item">
                        <p className="notifications__item-message">
                          {notification.message}
                          {notification.txHash && (
                            <>
                              <br />
                              Tx Hash:{' '}
                              {notification.txHash.substring(0, 6)}...
                              {notification.txHash.slice(-4)}
                            </>
                          )}
                        </p>
                        {notification.txHash && currentNetwork && (
                          <a
                            href={getExplorerLink(notification.txHash)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="notifications__item-link"
                          >
                            View on Explorer
                          </a>
                        )}
                        <span className="notifications__item-time">
                          {new Date(notification.timestamp).toLocaleTimeString()}
                        </span>
                      </div>
                    ))
                  ) : (
                    <div className="notifications__item">
                      <p className="notifications__item-message">No new notifications</p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          <NetworkSelector />

          <div className="navbar-profile-menu" onClick={toggleProfileMenu}>
            <Avatar
              src={
                auth.profile?.profilePicture || '/assets/media/images/profile-photo.png'
              }
              alt="Profile Avatar"
              className="navbar-profile-menu__image"
            />
            <img
              src="/assets/media/images/icons/arrow-down.svg"
              alt="Dropdown Arrow"
              className="navbar-profile-menu__arrow"
            />
            {isProfileMenuOpen && (
              <div className="navbar-profile-menu__dropdown">
                <div className="navbar-profile-menu__dropdown-item">
                  <p className="profile-nickname">
                    {auth.profile?.nickname || 'User'}
                  </p>
                </div>
                <div className="navbar-profile-menu__dropdown-item">
                  <p
                    className="clickable-address"
                    onClick={handleCopyAddress}
                    title="Click to copy address"
                    aria-label="Copy address to clipboard"
                    role="button"
                    tabIndex={0}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') handleCopyAddress();
                    }}
                  >
                    {auth.user?.address
                      ? `${auth.user.address.substring(0, 6)}...${auth.user.address.slice(
                          -4
                        )}`
                      : 'No Address'}
                    <FaClipboard className="clipboard-icon" />
                  </p>
                </div>
                <div className="navbar-profile-menu__dropdown-item">
                  <button
                    onClick={handleEditProfileOpen}
                    className="btn btn-secondary advance-card__button btn-sm smaragd"
                  >
                    Edit Profile
                  </button>
                </div>
                <div className="navbar-profile-menu__dropdown-item">
                  <button
                    onClick={handleLogout}
                    className="btn btn-primary advance-card__button btn-sm btn-pill smaragd"
                  >
                    Sign out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </header>
      <ProfileEditModal open={isEditProfileModalOpen} onClose={handleEditProfileClose} />
    </>
  );
};

export default Topbar;
