// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { NotificationsProvider } from './contexts/NotificationsContext'; // Import NotificationsProvider
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { Web3ReactProvider } from '@web3-react/core';
import { BrowserProvider } from 'ethers'; // Import BrowserProvider from ethers v6

function getLibrary(provider) {
  console.log('Initializing library with provider:', provider);
  if (!provider) {
    console.error('Provider is undefined');
    return;
  }
  const library = new BrowserProvider(provider); // Use BrowserProvider for ethers v6
  return library;
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Web3ReactProvider getLibrary={getLibrary}>
      <AuthProvider>
        <NotificationsProvider> {/* Wrap with NotificationsProvider */}
          <Router>
            <App />
          </Router>
        </NotificationsProvider>
      </AuthProvider>
    </Web3ReactProvider>
  </React.StrictMode>
);

reportWebVitals();
