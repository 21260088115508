import React, { useState } from 'react';
import { Wallet, isHexString, JsonRpcProvider } from 'ethers'; // Používáme Wallet z ethers
import { validateMnemonic } from 'bip39'; // Import validateMnemonic z bip39
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Tooltip, IconButton, Box } from '@mui/material';
import { FaInfoCircle } from 'react-icons/fa';
import useAuth from '../hooks/useAuth';
import './Mnemonic.css';

const ImportWallet = ({ handleBack }) => {
  const { login } = useAuth();
  const navigate = useNavigate();

  const [importInput, setImportInput] = useState('');
  const [importType, setImportType] = useState('mnemonic');
  const [inputWords, setInputWords] = useState(Array(12).fill(''));

  // Import wallet using mnemonic or private key
  const handleImportWallet = async () => {
    try {
      let wallet;
      if (importType === 'mnemonic') {
        const enteredMnemonic = normalizeMnemonic(inputWords.join(' '));
        // Validace mnemonic fráze pomocí validateMnemonic
        if (!validateMnemonic(enteredMnemonic)) {
          throw new Error('Invalid mnemonic phrase.');
        }
        // Vytvoření wallet z mnemonic fráze
        wallet = Wallet.fromPhrase(enteredMnemonic);
      } else if (importType === 'privateKey') {
        let formattedPrivateKey = importInput.trim();
        // Automatické přidání '0x' pokud chybí
        if (!formattedPrivateKey.startsWith('0x')) {
          formattedPrivateKey = '0x' + formattedPrivateKey;
        }
        // Validace privátního klíče
        if (!isHexString(formattedPrivateKey) || formattedPrivateKey.length !== 66) {
          throw new Error('Invalid private key.');
        }
        // Vytvoření wallet z privátního klíče
        wallet = new Wallet(formattedPrivateKey);
      } else {
        throw new Error('Invalid import type.');
      }

      const provider = new JsonRpcProvider(
        `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_ID}`
      );
      const connectedWallet = wallet.connect(provider);
      await login({ address: connectedWallet.address, wallet: connectedWallet, walletType: 'imported' });

      toast.success('Wallet successfully imported!');
      setImportInput('');
      setInputWords(Array(12).fill(''));
      navigate('/dashboard');
    } catch (error) {
      console.error('Error importing wallet:', error);
      toast.error(error.message || 'Failed to import wallet.');
    }
  };

  const normalizeMnemonic = (mnemonic) => {
    return mnemonic.trim().toLowerCase().replace(/\s+/g, ' ');
  };

  const handleSelectImportType = (type) => {
    setImportType(type);
    if (type === 'mnemonic') {
      setInputWords(Array(12).fill(''));
      setImportInput('');
    } else {
      setImportInput('');
      setInputWords(Array(12).fill(''));
    }
  };

  const handleMnemonicChange = (index, value) => {
    const words = value.trim().toLowerCase().split(/\s+/);
    const newInputWords = [...inputWords];

    if (words.length > 1) {
      words.forEach((word, idx) => {
        if (index + idx < 12) {
          newInputWords[index + idx] = word;
        }
      });
    } else {
      newInputWords[index] = value.trim().toLowerCase();
    }

    setInputWords(newInputWords);
  };

  return (
    <>
      <div
        className="import-type-selection"
        data-aos="fade-up"
        data-aos-delay="100"
        style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}
      >
        <button
          onClick={() => handleSelectImportType('mnemonic')}
          className={`btn advance-card__button btn-sm ${importType === 'mnemonic' ? 'active' : ''}`}
          style={{ marginRight: '10px' }}
        >
          Mnemonic Phrase
        </button>
        <button
          onClick={() => handleSelectImportType('privateKey')}
          className={`btn advance-card__button btn-sm ${importType === 'privateKey' ? 'active' : ''}`}
          style={{ marginRight: '10px' }}
        >
          Private Key
        </button>
      </div>

      <div
        className="import-info"
        data-aos="fade-up"
        data-aos-delay="150"
        style={{ display: 'flex', alignItems: 'left', marginBottom: '15px' }}
      >
        <p style={{ fontSize: '14px', color: '#555', margin: 0, marginRight: '8px' }}>
          How to connect your wallet using a mnemonic phrase & private key?
        </p>
        <Tooltip
          title={
            <Box sx={{ maxWidth: 300 }}>
              <strong>Importing Your Wallet</strong>
              <br />
              You can import your wallet using either a <strong>Mnemonic Phrase</strong> or a <strong>Private Key</strong>. Here's what you need to know:
              <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                <li>
                  <strong>Mnemonic Phrase:</strong> A sequence of 12 or 24 words generated during wallet creation. It allows you to restore your wallet on any compatible wallet application.
                </li>
                <li>
                  <strong>Private Key:</strong> A unique alphanumeric string that grants full access to your wallet. It's crucial to keep it confidential.
                </li>
              </ul>
              <strong>Best Practices:</strong>
              <ul style={{ marginTop: '10px', paddingLeft: '20px' }}>
                <li>
                  <strong>Keep it Secure:</strong> Never share your mnemonic phrase or private key with anyone.
                </li>
                <li>
                  <strong>Use Trusted Devices:</strong> Import your wallet only on devices you trust.
                </li>
                <li>
                  <strong>Verify Inputs:</strong> Double-check the entered words or private key for accuracy.
                </li>
                <li>
                  <strong>Avoid Public Networks:</strong> Perform wallet imports on secure and private internet connections.
                </li>
              </ul>
              If someone gains access to your mnemonic phrase or private key, they can control your wallet and all associated funds.
            </Box>
          }
          placement="right"
          arrow
          interactive
        >
          <IconButton aria-label="learn more about importing wallets" sx={{ color: '#007bff', padding: '4px' }}>
            <FaInfoCircle />
          </IconButton>
        </Tooltip>
      </div>

      {importType === 'mnemonic' ? (
        <div className="mnemonic-words" data-aos="fade-up" data-aos-delay="150">
          {inputWords.map((word, index) => (
            <div key={index} className="mnemonic-word">
              <input
                type="text"
                value={word}
                onChange={(e) => handleMnemonicChange(index, e.target.value)}
                placeholder={`${index + 1}.`}
                className="form-control form-control-sm mini-form__input"
                aria-label={`Mnemonic word ${index + 1}`}
              />
            </div>
          ))}
        </div>
      ) : (
        <input
          type="text"
          value={importInput}
          onChange={(e) => setImportInput(e.target.value)}
          placeholder="Enter your private key (0x...)"
          className="form-control form-control-sm mini-form__input"
          data-aos="fade-up"
          data-aos-delay="150"
        />
      )}

      <div className="mnemonic-buttons" style={{ marginTop: '20px' }}>
        <button onClick={handleBack} className="btn btn-secondary advance-card__button btn-sm" style={{ marginRight: '10px' }}>
          Back
        </button>
        <button onClick={handleImportWallet} className="btn btn-primary advance-card__button btn-sm btn-pill">
          <p>Sign In</p>
        </button>
      </div>
    </>
  );
};

export default ImportWallet;
