import React, { useState, useEffect } from 'react';
import './BuySell.css';

const BuySell = () => {
  const [orderType, setOrderType] = useState('Market');
  const [marginType, setMarginType] = useState('Cross');
  const [leverage, setLeverage] = useState('20');
  const [selectedTimeframe, setSelectedTimeframe] = useState('5');
  const [orderSize, setOrderSize] = useState('');
  const [btcData, setBtcData] = useState({
    markPrice: 'Loading...',
    indexPrice: 'Loading...',
    dailyChange: 'Loading...',
    dailyHigh: 'Loading...',
    dailyLow: 'Loading...',
    dailyVolume: 'Loading...',
    fundingRate: '+0.0102%' // Static funding rate
  });

  // Fetch BTC/USDT data from CoinGecko
  useEffect(() => {
    const fetchBtcData = async () => {
      try {
        const response = await fetch(
          'https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin'
        );
        const data = await response.json();
        if (data && data.length > 0) {
          const btc = data[0];
          setBtcData({
            markPrice: `$${btc.current_price.toLocaleString()}`,
            indexPrice: `$${btc.current_price.toLocaleString()}`,
            dailyChange: `${btc.price_change_percentage_24h > 0 ? '+' : ''}${btc.price_change_percentage_24h.toFixed(2)}%`,
            dailyHigh: `$${btc.high_24h.toLocaleString()}`,
            dailyLow: `$${btc.low_24h.toLocaleString()}`,
            dailyVolume: `${(btc.total_volume / 1000).toFixed(2)} BTC`,
            fundingRate: '+0.0102%' // Static funding rate
          });
        }
      } catch (error) {
        console.error("Failed to fetch BTC data:", error);
      }
    };

    fetchBtcData();
    const interval = setInterval(fetchBtcData, 60000); // Refresh every 1 minute
    return () => clearInterval(interval);
  }, []);

  // Load and initialize the TradingView widget
  useEffect(() => {
    // Prevent multiple script loads
    if (document.getElementById('tradingview-script')) return;

    const script = document.createElement('script');
    script.id = 'tradingview-script';
    script.src = 'https://s3.tradingview.com/tv.js';
    script.async = true;
    script.onload = () => {
      if (window.TradingView) {
        new window.TradingView.widget({
          autosize: true,
          symbol: "BINANCE:BTCUSDT",
          interval: selectedTimeframe === '1D' ? 'D' : selectedTimeframe + 'm',
          timezone: "exchange",
          theme: "dark",
          style: "1", // Candlestick
          locale: "en",
          toolbar_bg: "#1e2124",
          enable_publishing: false,
          hide_side_toolbar: false,
          allow_symbol_change: true,
          container_id: "tradingview-widget",
          studies: [
            "Volume@tv-basicstudies",
            "RSI@tv-basicstudies"
          ],
          disabled_features: [
            "header_symbol_search"
          ],
          enabled_features: [
            "hide_left_toolbar_by_default"
          ],
          overrides: {
            "paneProperties.background": "#1e2124",
            "paneProperties.vertGridProperties.color": "#232323",
            "paneProperties.horzGridProperties.color": "#232323",
            "scalesProperties.textColor": "#AAA"
          }
        });
      }
    };
    document.head.appendChild(script);
    return () => {
      // Cleanup script on component unmount
      if (script.parentNode) {
        script.parentNode.removeChild(script);
      }
    };
  }, [selectedTimeframe]);

  // Mock Order Book Data
  const mockOrderBook = {
    asks: [
      { price: "66,790.00", size: "0.5", total: "33,395.00" },
      { price: "66,800.00", size: "1.0", total: "66,800.00" },
      { price: "66,810.00", size: "0.3", total: "19,983.00" },
      { price: "66,820.00", size: "0.2", total: "13,364.00" },
      { price: "66,830.00", size: "0.1", total: "6,683.00" },
      { price: "66,840.00", size: "0.4", total: "26,736.00" },
      { price: "66,850.00", size: "0.6", total: "40,110.00" },
      { price: "66,860.00", size: "0.2", total: "13,372.00" }
    ],
    bids: [
      { price: "66,780.00", size: "0.3", total: "20,034.00" },
      { price: "66,770.00", size: "0.7", total: "46,739.00" },
      { price: "66,760.00", size: "0.4", total: "26,704.00" },
      { price: "66,750.00", size: "0.2", total: "13,350.00" },
      { price: "66,740.00", size: "0.5", total: "33,370.00" },
      { price: "66,730.00", size: "0.1", total: "6,673.00" },
      { price: "66,720.00", size: "0.3", total: "20,016.00" },
      { price: "66,710.00", size: "0.2", total: "13,342.00" }
    ]
  };

  // Mock Recent Trades Data
  const mockRecentTrades = [
    { price: "66,785.50", size: "0.2345", time: "12:45:30", type: "sell" },
    { price: "66,790.00", size: "0.1500", time: "12:45:25", type: "buy" },
    { price: "66,780.50", size: "0.5000", time: "12:45:20", type: "sell" },
    { price: "66,775.00", size: "0.3000", time: "12:45:15", type: "buy" },
    { price: "66,785.00", size: "0.4500", time: "12:45:10", type: "sell" }
  ];

  // Handler Functions
  const handleTimeframeChange = (tf) => {
    setSelectedTimeframe(tf);
  };

  const handleOrderTypeChange = (type) => {
    setOrderType(type);
  };

  const handleMarginTypeChange = (type) => {
    setMarginType(type);
  };

  const handleLeverageChange = (e) => {
    setLeverage(e.target.value);
  };

  const handleOrderSizeChange = (e) => {
    setOrderSize(e.target.value);
  };

  const increaseOrderSize = () => {
    setOrderSize((prev) => String(Number(prev || 0) * 2));
  };

  const decreaseOrderSize = () => {
    setOrderSize((prev) => String(Number(prev || 0) / 2));
  };

  const resetOrderSize = () => {
    setOrderSize('0');
  };

  return (
    <div className="trading-platform">
      {/* Header Panel */}
      <div className="trading-header">
        <div className="pair-info">
          <img src="https://cryptologos.cc/logos/bitcoin-btc-logo.png" alt="BTC" className="crypto-icon" />
          <div className="pair-details">
            <span className="pair-name">BTC/USDT</span>
            <span className="pair-type">Perpetual</span>
          </div>
        </div>

        {/* Market Stats */}
        <div className="trading-stats">
          <div className="stat-item">
            <span className="stat-label">Mark Price</span>
            <span className="stat-value">{btcData.markPrice}</span>
          </div>
          <div className="stat-item">
            <span className="stat-label">Index Price</span>
            <span className="stat-value">{btcData.indexPrice}</span>
          </div>
          <div className="stat-item">
            <span className="stat-label">24h Change</span>
            <span className={`stat-value ${btcData.dailyChange.startsWith('+') ? 'positive' : 'negative'}`}>
              {btcData.dailyChange} {btcData.dailyChange.startsWith('+') ? `(+${(btcData.markPrice.replace(/[^0-9.-]+/g,"") * btcData.dailyChange.replace('%','') / 100).toFixed(2)})` : ''}
            </span>
          </div>
          <div className="stat-item">
            <span className="stat-label">24h High</span>
            <span className="stat-value">{btcData.dailyHigh}</span>
          </div>
          <div className="stat-item">
            <span className="stat-label">24h Low</span>
            <span className="stat-value">{btcData.dailyLow}</span>
          </div>
          <div className="stat-item">
            <span className="stat-label">24h Volume</span>
            <span className="stat-value">{btcData.dailyVolume}</span>
          </div>
          <div className="stat-item">
            <span className="stat-label">Funding Rate</span>
            <span className="stat-value">{btcData.fundingRate}</span>
          </div>
        </div>
      </div>

      {/* Main Trading Interface */}
      <div className="trading-content">
        {/* Chart Area */}
        <div className="chart-area">
          <div className="chart-header">
            <div className="chart-controls">
              <div className="timeframe-selector">
                {['1', '5', '15', '30', '60', '240', '1D'].map(tf => (
                  <button
                    key={tf}
                    className={`time-button ${selectedTimeframe === tf ? 'active' : ''}`}
                    onClick={() => handleTimeframeChange(tf)}
                  >
                    {tf.includes('D') ? tf : `${tf}m`}
                  </button>
                ))}
              </div>
              <div className="chart-tools">
                <button className="tool-btn">Indicators</button>
                <button className="tool-btn">Full Screen</button>
                <button className="tool-btn">Settings</button>
              </div>
            </div>
          </div>

          <div id="tradingview-widget" className="tradingview-chart"></div>
        </div>

        {/* Trading Panel */}
        <div className="trading-panel">
          {/* Order Type Selector */}
          <div className="order-type-selector">
            {['Market', 'Limit', 'Stop Market', 'Stop Limit'].map(type => (
              <button
                key={type}
                className={`order-type-btn ${orderType === type ? 'active' : ''}`}
                onClick={() => handleOrderTypeChange(type)}
              >
                {type}
              </button>
            ))}
          </div>

          {/* Leverage Settings */}
          <div className="leverage-settings">
            <div className="leverage-header">
              <div className="margin-mode">
                <button
                  className={`margin-btn ${marginType === 'Cross' ? 'active' : ''}`}
                  onClick={() => handleMarginTypeChange('Cross')}
                >
                  Cross
                </button>
                <button
                  className={`margin-btn ${marginType === 'Isolated' ? 'active' : ''}`}
                  onClick={() => handleMarginTypeChange('Isolated')}
                >
                  Isolated
                </button>
              </div>
              <span className="leverage-display">{leverage}×</span>
            </div>
            <input
              type="range"
              min="1"
              max="100"
              value={leverage}
              onChange={handleLeverageChange}
              className="leverage-slider"
            />
          </div>

          {/* Order Form */}
          <div className="order-form">
            <div className="input-group">
              <label>Size (BTC)</label>
              <div className="input-with-buttons">
                <input
                  type="number"
                  value={orderSize}
                  onChange={handleOrderSizeChange}
                  placeholder="0.000"
                />
                <div className="size-buttons">
                  <button onClick={increaseOrderSize}>2×</button>
                  <button onClick={decreaseOrderSize}>½</button>
                  <button onClick={resetOrderSize}>Reset</button>
                </div>
              </div>
            </div>

            {orderType !== 'Market' && (
              <div className="input-group">
                <label>Price (USDT)</label>
                <input type="number" placeholder="0.00" />
              </div>
            )}

            <div className="order-buttons">
              <button className="buy-btn">Buy</button>
              <button className="sell-btn">Sell</button>
            </div>
          </div>

          {/* Order Book */}
          <div className="orderbook">
            <div className="orderbook-header">
              <span>Price (USDT)</span>
              <span>Size (BTC)</span>
              <span>Total</span>
            </div>

            <div className="orderbook-asks">
              {mockOrderBook.asks.map((ask, i) => (
                <div key={`ask-${i}`} className="orderbook-row ask">
                  <span className="price-red">{ask.price}</span>
                  <span>{ask.size}</span>
                  <span>{ask.total}</span>
                </div>
              ))}
            </div>

            <div className="orderbook-spread">
              <span className="spread-price">66,785.50</span>
              <span className="spread-label">Spread: 0.01% (4.00)</span>
            </div>

            <div className="orderbook-bids">
              {mockOrderBook.bids.map((bid, i) => (
                <div key={`bid-${i}`} className="orderbook-row bid">
                  <span className="price-green">{bid.price}</span>
                  <span>{bid.size}</span>
                  <span>{bid.total}</span>
                </div>
              ))}
            </div>
          </div>

          {/* Recent Trades */}
          <div className="recent-trades">
            <div className="section-header">
              <h3>Recent Trades</h3>
            </div>
            <div className="trades-list">
              {mockRecentTrades.map((trade, i) => (
                <div key={`trade-${i}`} className="trade-row">
                  <span className={trade.type === 'buy' ? 'price-green' : 'price-red'}>{trade.price}</span>
                  <span>{trade.size}</span>
                  <span>{trade.time}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuySell;
