// src/components/Dashboard/Token/Token.js
import React, { useState, useEffect, useContext } from 'react';
import { TokenProvider } from './TokenContext';
import Step1BasicInfo from './steps/Step1BasicInfo';
import Step2TokenType from './steps/Step2TokenType';
import Step3Settings from './steps/Step3Settings';
import Step4ReviewDeploy from './steps/Step4ReviewDeploy';
import TokenList from './sections/TokenList';
import TokenManagement from './sections/TokenManagement';
import './Token.css'; // Ensure Token.css is in the same directory
import useAuth from '../../../hooks/useAuth'; // Adjust the import path based on your project structure
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Token = () => {
  const { auth } = useAuth(); // Access AuthContext
  const navigate = useNavigate(); // Initialize navigate
  const [activeSection, setActiveSection] = useState('create'); // 'create', 'manage', 'list'
  const [step, setStep] = useState(1);
  const [deployedTokens, setDeployedTokens] = useState([]);

  useEffect(() => {
    // Redirect unauthenticated users
    if (!auth.isAuthenticated) {
      toast.error('You must be logged in to access the Token Platform.');
      navigate('/'); // Redirect to SignIn
    }
  }, [auth.isAuthenticated, navigate]);

  useEffect(() => {
    // Load deployed tokens from localStorage or backend
    const loadDeployedTokens = async () => {
      const tokens = localStorage.getItem('deployedTokens');
      if (tokens) {
        setDeployedTokens(JSON.parse(tokens));
      }
    };
    loadDeployedTokens();
  }, []);

  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  const handleTokenDeployed = (result) => {
    const newToken = {
      ...result, // Contains contractAddress and transactionReceipt
      name: result.tokenData.name,
      symbol: result.tokenData.symbol,
      totalSupply: result.tokenData.totalSupply,
      tokenType: result.tokenData.tokenType,
      taxRate: result.tokenData.taxRate,
      decimals: result.tokenData.decimals,
      ownerAddress: result.tokenData.ownerAddress,
      feeRecipient1: result.tokenData.feeRecipient1,
      feeRecipient2: result.tokenData.feeRecipient2,
    };
    const newTokens = [...deployedTokens, newToken];
    setDeployedTokens(newTokens);
    localStorage.setItem('deployedTokens', JSON.stringify(newTokens));
  };

  // **Navigation Function**
  const goToCreate = () => {
    setActiveSection('create');
    setStep(1);
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <Step1BasicInfo nextStep={nextStep} />;
      case 2:
        return <Step2TokenType nextStep={nextStep} prevStep={prevStep} />;
      case 3:
        return <Step3Settings nextStep={nextStep} prevStep={prevStep} />;
      case 4:
        return <Step4ReviewDeploy prevStep={prevStep} onDeployed={handleTokenDeployed} signer={auth.wallet?.getSigner()} />;
      default:
        return <Step1BasicInfo nextStep={nextStep} />;
    }
  };

  const renderContent = () => {
    switch (activeSection) {
      case 'create':
        return (
          <div className="token_create-section">
            <div className="token_steps-indicator">
              <div className={`token_step ${step >= 1 ? 'active' : ''}`}>1. Basic Info</div>
              <div className={`token_step ${step >= 2 ? 'active' : ''}`}>2. Token Type</div>
              <div className={`token_step ${step >= 3 ? 'active' : ''}`}>3. Settings</div>
              <div className={`token_step ${step >= 4 ? 'active' : ''}`}>4. Review & Deploy</div>
            </div>
            <div className="token_step-content">
              {renderStep()}
            </div>
          </div>
        );
      case 'manage':
        return <TokenManagement tokens={deployedTokens} goToCreate={goToCreate} />;
      case 'list':
        return <TokenList tokens={deployedTokens} goToCreate={goToCreate} />;
      default:
        return null;
    }
  };

  return (
    <TokenProvider>
      <div className="token_container">
        <h1>Token Platform</h1>
        <div className="token_layout">
          <div className="token_sidebar">
            <nav className="token_nav">
              <div
                className={`token_nav-item ${activeSection === 'create' ? 'active' : ''}`}
                onClick={() => setActiveSection('create')}
              >
                <i className="fas fa-plus"></i>
                Create New Token
              </div>
              <div
                className={`token_nav-item ${activeSection === 'manage' ? 'active' : ''}`}
                onClick={() => setActiveSection('manage')}
              >
                <i className="fas fa-cog"></i>
                Manage Tokens
              </div>
              <div
                className={`token_nav-item ${activeSection === 'list' ? 'active' : ''}`}
                onClick={() => setActiveSection('list')}
              >
                <i className="fas fa-list"></i>
                My Tokens
              </div>
            </nav>
            
            {/* Token Stats */}
            <div className="token_stats">
              <div className="token_stats-item">
                <span className="token_stats-label">Total Tokens</span>
                <span className="token_stats-value">{deployedTokens.length}</span>
              </div>
              {/* Add more stats items if needed */}
            </div>
          </div>

          <div className="token_main">
            {renderContent()}
          </div>
        </div>
      </div>
    </TokenProvider>
  );
};

export default Token;
