// src/components/Token/TokenContext.js
import React, { createContext, useState } from 'react';

export const TokenContext = createContext();

export const TokenProvider = ({ children }) => {
  const [tokenData, setTokenData] = useState({
    name: '',
    symbol: '',
    totalSupply: '',
    tokenType: 'Base',
    taxRate: '',
    // Add other fields as necessary
  });

  const updateTokenData = (newData) => {
    setTokenData((prev) => ({ ...prev, ...newData }));
  };

  return (
    <TokenContext.Provider value={{ tokenData, updateTokenData }}>
      {children}
    </TokenContext.Provider>
  );
};
